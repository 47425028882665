.c-cookieNotice {

    position: fixed;
    bottom: 20px;
    width: 70%;
    height: 230px;
    background-color: rgba(34, 52, 154, 0.87);
    z-index: 9;
    margin-left: calc(50% - 35%); 
    border-radius: 15px;
    
        @include breakpoint(small only) {
            height: 40%;
            width: 96%;
            margin-left: calc(50% - 48%)
        }
        
    
    
    
    p {
        position: relative;
        width:65%;
        margin: auto;
        margin-top: 30px;
        height: 100px;
        color: white;
        
            
        @include breakpoint(small only) {
            margin-top: 50px;
            width: 90%

    }
        
    }
    
    a {
        color: white;
        text-decoration: underline;
    }
    
    #accept-cookies {
        width: 50px;
        background-color: white;
        border-radius: 3px;
        padding: 10px;
        color: $brand;
        margin-left: calc(50% - 25px);
        cursor: pointer;
        transition: .5s ease-in-out;
        font-weight: 600;

        
        &:hover {
            width: 70px;
            margin-left: calc(50% - 35px);
            transition: .3s ease-in-out;
        }
        
        @include breakpoint(small only) {
            margin-top: 30px;
        }
    }
    

    
}

    .white-fade {
        width: 100vh;
        height: 100vh;
//        background-color: rgba(255, 255, 255, 0.26);
        background-color: pink;
    }





// animated gates

 .section1 {
        width: 100%;
        height: 100vh;
        position: absolute;
        top:0;
        left:0;
        z-index: 2;
     

      
        .nor-fata-1 {
            position: absolute;
            width: 650px; 
            bottom: -60px;
            left: -20px;
            z-index: 5;
            transition: 4s;
        }
        
            .nor-fata-1-animate {
                
                width: 750px;
                left: -350px;
                opacity: 0;

            }
        
        .nor-fata-2{
             position: absolute;
            width: 750px; 
            bottom: -30px;
            right: -100px;
            z-index: 6;
             transition: 4s;
//            background-color: red;
        }
        
            .nor-fata-2-animate {
                width: 850px;
                opacity: 0;
                right: -200px;

            }
        
        .nor-fata-3{
            width: 550px;
            margin-left: calc(50% - 230px);
            bottom: 0;
            position: absolute;
            transition: 5s;
        }
        
            .nor-fata-3-animate {
                width: 650px;
                margin-left: calc(50% - 330px);
                opacity: 0;

            }
        
        
        .gard-stanga {
            position: absolute;
            width: 900px;
            left: -230px;
            bottom: -10px;
            transition: 5s;
            z-index: 3;
        }
        
            .gard-stanga-animate {
               left: -750px; 
            }
        
        .gard-dreapta {
            position: absolute;
            width: 900px;
            right: -250px;
            bottom: -60px;
            z-index: 4;
            transition: 5s;

        }
        
            .gard-dreapta-animate {
                right: -650px
            }
        
        .poarta-stanga {
             width: 330px;
             position: absolute;
             left: 300px;
             bottom: 0px;
             transition: 5s;
             z-index: 2;            
        }
        
            .poarta-stanga-animate { 
                left: -300px;
        }
        
        .poarta-dreapta {
            width: 360px;
            position: absolute;
            right: 240px;
            bottom: -25px;
            transition: 5s;
            display: block;
        }
        
            .poarta-dreapta-animate {
                right: -320px;
        }
        
        @include breakpoint(small only) {
            
            display: none;
            
//            .nor-fata-1 {
//                position: absolute;
//                width: 450px; 
//                bottom: -60px;
//                left: -20px;
//                z-index: 5;
//                transition: 4s;
//        }
//        
//            .nor-fata-1-animate { 
//                width: 450px;
//                left: -350px;
//                opacity: 0;
//            }
//        
//        .nor-fata-2{
//             position: absolute;
//            width: 450px; 
//            bottom: -30px;
//            right: -100px;
//            z-index: 6;
//             transition: 4s;
//                    }
//        
//            .nor-fata-2-animate {
//                width: 450px;
//                opacity: 0;
//                right: -200px;
//            }
//        
//        .nor-fata-3{
//            width: 550px;
//            margin-left: calc(50% - 230px);
//            bottom: 0;
//            position: absolute;
//            transition: 5s;
//        }
//        
//            .nor-fata-3-animate {
//                width: 450px;
//                margin-left: calc(50% - 330px);
//                opacity: 0;
//            }  
//        
//        .gard-stanga {
//            display: none;
//        }
//        
//        .gard-dreapta {
//            display: none;
//        }         
//            
//        .poarta-stanga {
//             width: 280px;
//             position: absolute;
//             left: -70px;
//             bottom: -20px;
//             transition: 5s;
//             z-index: 2;            
//        }
//        
//            .poarta-stanga-animate { 
//                left: -300px;
//        }
//        
//        .poarta-dreapta {
//            width: 285px;
//            position: absolute;
//            right: -70px;
//            bottom: 0px;
//            transition: 5s;
//            display: block;
//        }
//        
//            .poarta-dreapta-animate {
//               right: -300px;
//        }
            
        }

    }
 

// page desktop

 .container-1280 {
     
     
    display: none;

      @include breakpoint(780px up) {
    
    display: block;
    
    position: relative;
    max-width: 1280px;
    height: auto;
    margin: auto;
    z-index: 1;
    
    
    
    
    .pop-up {
        position: fixed;
        width: 590px;
        height: 600px;
        left: calc(50% - 295px);
        top: calc(50% - 250px);
        z-index: 6;
        display: none;

        
                @include breakpoint(small only) {
                    position: fixed;
                    display: block;
                    background-color:#f7f7f7;
                    width: 100%;
                    height: 100%;
                    left:calc(50% - 50%);
                    top:0;
                    z-index: 11;
                    display: none;
            }

 
        .pergament {
            position: fixed;
            width: 590px;
            height: 580px;
            z-index: -1;
            
            @include breakpoint(small only) {
                display: none;
            }
        }
        
        .content-zeus, .content-hera {
            position:relative;
            padding-top: 30px;
        }
        
        .content {
            position: relative;
            margin: 50px auto;
            width: 440px;
            height: 500px;

            
            @include breakpoint(small only) {
                position: absolute;
                width: 90%;
                left:calc(50% - 45%);
                top:0px;
                height: 90%;
                margin-top: 80px;
            }
            
    
            
            .title-info {
                padding-top: 0px;
                font-size: 20px;
            }
            
            .popup-title1 {
                font-size: 16px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
            
            .close-icon {
                position: absolute;
                width: 30px;
                height: 30px;
                top:-10px;
                right: -10px;
                z-index: 4;
                cursor: pointer;
            
            .xbtn {
                position: absolute;
                width: 25px;
                height: 2px;
                margin-left: 2px;
                margin-top: calc(50% - 1px);
                top:0;
                background-color: $zuzu;
                transform: rotate(45deg);
                
                &::before {
                    content: '';
                    width: 25px;
                    height: 2px;
                    background-color: $zuzu;
                    position: absolute;
                    transform: rotate(-90deg);
                }
            }
                
                @include breakpoint(small only) {
                    
                    top: -50px;
                    right: 0px;
                    
                    .xbtn {
                        width: 35px;
                        
                    &::before {
                            width: 35px;
                        }
                    }
                    
                }
        }     
            
            .lauri {
            position: relative;
            width: 150px;
            margin-left: calc(50% - 75px);
            margin-bottom: 15px;
            margin-top: 5px;
        }
            
        
                p span {
                    font-weight: 600;
                }

            
            .play-icon {
                position: relative;
                margin-bottom: 15px;
                margin-top: 15px;
                margin-left: calc(50% - 20px);
                width: 40px;
                cursor: pointer;
            }
            
            
            audio {
                width: 300px;
                margin-left: calc(50% - 150px);
            }
            
            .lauri-info {
                position: relative;
                width: 150px;
                margin-left: calc(50% - 75px);
                margin-bottom: 8px;
                margin-top: 3px;
            }
            
            .info {
                position: relative;
                width: 50%;
                float: left;
                text-align: center;
                
                p {
                    font-weight: 600;
                }
                
                ul {
                    list-style: none;
                    margin-left: 0;
                    
                    li {
                        color: $zuzu;
                        font-family: 'roma-regular';
                        line-height: 19px;
                    }
                }
            }
            
            .valori {}
            
            .grame {}
            
        }
        
            .spoon {
                position:absolute;
                bottom: 0;
                width: 300px;
                left: 300px;
                bottom: -10px;
                z-index: 3;                
            }

    }   
    
 
    .section2 {
        width: 90%;
        margin: auto;
        height: 768px;
        position: relative;
        z-index: 1;
        background: #618cc2;
        
        // Products absolute
        
        
        @include breakpoint(small only) {
            display: none;
        }
        
        .raze-sus {
         position: absolute;
         width: 1000px;
         margin-left: calc(50% - 500px);
         top: 30px;
         }
        
        .raze-jos {
         position: absolute;
         width: 1000px;
         margin-left: calc(50% - 500px);
         bottom: -400px;
         }
        
        
        .nor-top-capsuni {
            position: absolute;
//            background-color: aqua;
            bottom: -40px;
            z-index: 4;
            left: -70px;
        }
        
        .nor-top-1-2 {
            position: absolute;
            right: -60px;
            bottom: -40px;
            z-index: 4;
        }
        
        .nor-top-caise {
            position: absolute;
            bottom: -60px;
            width: 330px;
            margin-left: calc(50% - 165px);
            z-index: 5;
        }
        
        .products {
            position: absolute;
            width: 70%;
            height: 400px;
            bottom: 80px;
            margin-left: calc(50% - 35%);
            z-index: 5;
//            background-color: orangered;
            
            .iaurt-simplu-zeus {
                position: absolute;
                left: calc(50% - 260px);
                top:0;
                transition: .3s ease-in-out;
                cursor: pointer;
                z-index: 5;
                width: 250px;

                &:hover  {
                    top:-10px;
                    transition: .3s ease-in-out;
                }
            }
            
                 .nor-top-1-10 {
                        position: absolute;
                        z-index: 5!important;
                         top: 135px;
                        left: 70px;
                        width: 300px;
                         left: calc(50% - 310px);
                     z-index: 4;
                    }
                     
                .umbra-zuzu {
                    position: absolute;
                    left: calc(50% - 270px);
                    top:150px;
                    width: 240px;
                    transition: .3s ease-in-out;
                    cursor: pointer;
                    z-index: -1;
                    
                    }
            
                    .iaurt-simplu-zeus:hover + .umbra-zuzu {
                width: 200px;
                left: calc(50% - 240px);
            } 
            
                
   
            
            .iaurt-simplu-hera {
                position: absolute;
                right: calc(50% - 260px);
                top:0;
                transition: .3s ease-in-out;
                cursor: pointer;
                width: 250px;

                &:hover {
                    top:-10px;
                    transition: .3s ease-in-out;
                }
            }
            
                .umbra-hera {
                        position: absolute;
                        right: calc(50% - 260px);
                        top:150px;
                        width: 240px;
                        transition: .3s ease-in-out;
                        cursor: pointer;
                    z-index: -1;
                        }
            
                    .iaurt-simplu-hera:hover + .umbra-hera {
                width: 200px;
                right: calc(50% - 250px);
            } 
            
            .nor-top-1-2 {
                position: absolute;
//                background-color: red;
                top:135px;
                right: calc(50% - 310px);
            }
   
            
            .iaurt-capsuna {
                position: absolute;
                left: 0;
                bottom: 30px;
                transition: .3s ease-in-out;
                z-index: 7!important;
                width: 250px;
                
                &:hover {
                    bottom: 40px;
                    transition: .3s ease-in-out;
                }
            }
            
                .umbra-capsuna {
                    position: absolute;
                    left: 0;
                    bottom: 15px;
                    width: 230px;
                    transition: .3s ease-in-out;
                }
            
                    .iaurt-capsuna:hover + .umbra-capsuna {
                width: 180px;
                left: 30px;
            } 
            
            .nor-top-capsuna {
                position: absolute;
                bottom: -35px;
                left: calc(50% - 450px);
                z-index: 8!important;
            }
            
            .iaurt-caise {
                position: absolute;
                left: calc(50% - 115px);
                bottom: 0;
                transition: .3s ease-in-out;
                z-index: 5;
                width: 250px;
                
                &:hover {
                    bottom: 10px;
                    transition: .3s ease-in-out;
                }
            }
            
                .umbra-caise {
                position: absolute;
                left: calc(50% - 90px);
                width: 210px;
                bottom: -10px;
                transition: .3s ease-in-out;
                z-index: -1;
            }
            
            .nor-top-caise {
                position: absolute;
                
            }
            
            
                    .iaurt-caise:hover + .umbra-caise {
                width: 180px;
                left: calc(50% - 85px);
                        z-index: -1;
            }         
            
            .iaurt-cirese {
                position: absolute;
                right: 0;
                bottom: 30px;
                transition: .3s ease-in-out;
                z-index: 6!important;
                width: 250px;
                
                &:hover {
                    bottom: 40px;
                    transition: .3s ease-in-out;
                }
            }
            
                .umbra-cirese {
                position: absolute;
                width: 230px;
                right: 0;
                bottom: 20px;
                transition: .3s ease-in-out;
                z-index: -1;
            }
            
                    .iaurt-cirese:hover + .umbra-cirese {
                width: 180px;
                right: 20px;
            } 
            
            .nor-top-cirese {
                position: absolute;
                bottom: -35px;
                right: calc(50% - 450px);
                z-index: 6!important;
            }

            
        }
        
        .back-alb {
            width: 950px;
            height: 450px;
            position: absolute;
            margin-left: calc(50% - 425px);
            margin-top: calc(50% - 305px);
            z-index: 1;
        }
        
        .col-stanga1 {
            position: absolute;
            width: 80px;
            top:130px;
            left: calc(50% - 200px);
        }
        
        .col-stanga2 {
            position: absolute;
            width: 90px;
            top:150px;
            left: calc(50% - 420px);
        }
        
        .col-dreapta1 {
            position: absolute;
            width: 80px;
            top:130px;
            right: calc(50% - 200px);
        }
        
        .col-dreapta2 {
            width: 90px;
            position: absolute;
            top:150px;
            right: calc(50% - 450px);
        }
        
       //zei
        
        .zeu-zeus {
            position: absolute;
            width: 740px;
            left: -310px;
            top: 140px;
            z-index: 3;
        }
        .zeu-hera {
            position: absolute;
            width: 550px;
            right: -230px;
            top: 140px;
            z-index: 2;
        }
        .zeu-capsuna {
            position: absolute;
            width: 500px;
            left: -210px;
            bottom: 0;
            z-index: 3;
        }
        .zeu-caise {
            position: absolute;
            width: 620px;
            left: calc(50% - 415px);
            bottom: -180px; 
            z-index: 4;
        }
        .zeu-cirese {
            position: absolute;
            width: 430px;
            right: -170px;
            bottom: 0;
            z-index: 2;
        }
        
        //nori
        
        //capsuna
        .nor1-s2 {
            position: absolute;
            width: 900px;
            left: -300px;
            bottom: -200px;
            z-index: 1;
        }
        
        //cirese
        .nor2-s2 {
            position: absolute;
            width: 900px;
            right: -300px;
            bottom: -150px;
            z-index: 1;
        }
        
        // zeus
        .nor3-s2 {
            position: absolute;
            width: 600px;
            left: -150px;
            top: 380px;
//            background-color: red;
            z-index: 1;
        }
        
        .nor-new-left {
            position: absolute;
            width: 600px;
            left: -150px;
            top: 180px;
//          background-color: red;
            z-index: 1;
        }
        
        
        //hera
        .nor4-s2 {
            position: absolute;
            width: 800px;
            top:230px;
            right: -250px;
            z-index: 1;
        }
        
        //caise 
        
        .nor-caise-s2 {
            position: absolute;
            width: 800px;
            margin-left: calc(50% - 400px);
            bottom: -200px;
        }
        
        
    }
    
    .section3 {
        width: 100%;
        height: 768px;
        position: relative;
        background: #618cc2;
        
        //iaurturi
        
        &:hover .zeus-s3 {
            transition: 1s ease-out;
            left: -30px;

        }
        
        &:hover .hera-s3 {
                right: -190px;
                transition: .5s ease-out;
        }
        
        .iaurt-s3-zeus {
            position: absolute;
            bottom: 50px;
            width: 231px;
            left: calc(50% - 280px);
            z-index: 4;  
            transition: .3s ease-in-out;
            cursor: pointer;
            
            &:hover {
                bottom: 60px;
                transition: .3s ease-in-out;
            }
            
                @include breakpoint(small only) {
                    left: calc(50% - 100px); 
                    bottom: 150px;
                    width: 200px;
                }
            
        }
        
        .umbra-zuzu3 {
            position: absolute;
            bottom: 40px;
            width: 231px;
            left: calc(50% - 280px);
            z-index: 3;  
            transition: .3s ease-in-out;
            
                @include breakpoint(small only) {
                    left: calc(50% - 120px); 
                    bottom: 138px;
                }
        }
        
            .iaurt-s3-zeus:hover + .umbra-zuzu3 {
                bottom: 40px;
                left: calc(50% - 250px);
                width: 190px;
                } 
   
        
        .iaurt-s3-hera {
            position: absolute;
            bottom: 50px;
            width: 231px;
            right: calc(50% - 280px);
            transition: .3s ease-in-out;
            cursor: pointer;
            z-index: 4;
            
            &:hover {
                
                bottom: 60px;
                transition: .3s ease-in-out;
            }
            
            
            @include breakpoint(small only) {
                width: 200px;
                left: calc(50% - 100px);
                bottom:-250px;
            }
        }
        
        .nor-grifon-1 {
           position: absolute;
            right: -100px;
            bottom: -50px;
            width: 800px;
            z-index: 2;
        }
        
            .umbra-hera3 {
                position: absolute;
                bottom: 39px;
                width: 225px;
                right: calc(50% - 280px);
                z-index: 3;
                transition: .3s ease-in-out;
                cursor: pointer;
                
                @include breakpoint(small only) {
                    width: 200px;
                    left: calc(50% - 100px);
                    bottom:-260px;
                }
            }

                .iaurt-s3-hera:hover + .umbra-hera3 {
                    bottom: 40px;
                    right: calc(50% - 260px);
                    width: 190px;
                    } 
        
        //zei
        
        .zeus-s3 {
            position: absolute;
            width: 370px;
            bottom: calc(50% - 27%);
            left: 20px;
            z-index: 2;
            transition: 1s ease-out;
            z-index: 3;
            
                @include breakpoint(small only) {
                    
                    width: 800px;
                    max-width: 130%;
                    left: -180px;
                    bottom: calc(50% - 25%);
                }
        }
        
        .nor-uliu-1 {
            position: absolute;
            width: 700px;
            bottom: 110px;
            left: -50px;
            z-index: 2;
        }
        
        
        .hera-s3 {
            position: absolute;
            width: 400px;
            bottom: calc(50% - 45%);
            right: -130px;
            z-index: 2;
            transition: 1s ease-out;
            
                @include breakpoint(small only) {
                    width: 380px;
                    right: -120px;
                    bottom:-250px;
                }

        }
        
        // nor
        
        .nor-s3 {
            position: absolute;
            bottom: -120px;
            width: 850px;
            left: calc(50% - 425px);
            z-index: 2;

            @include breakpoint(small only) {
                display: none;
            }

        }
        
        .nor-s3-1 {
            position: absolute;
            bottom: -120px;
            width: 300px;
            left: calc(50% - 310px);
            bottom: -30px;
            z-index: 4;
        }
        
        .nor-s3-2 {
            position: absolute;
            bottom: -120px;
            width: 300px;
            right: calc(50% - 310px);
            bottom: -30px;
            z-index: 4;
        }
        
        
        .creature {
            position: absolute;
            width: 350px;
            right: -200px;
            bottom: -100px;
            z-index: 2;
            
            @include breakpoint(small only) {
                display: none;
            }
        }
        

    }
    
    .section4 {
        width: 100%;
        height: 768px;
        position: relative;
    background: #618cc2;
        
        &:hover .zeu-caise-s4 {
            right: calc(50% - 770px);
            transition: 1s ease-out;
        }
        
        
         &:hover .zeu-cirese-s4 {
            left: calc(50% - 660px);
            transition: 1s ease-out;
        }
        
        
        &:hover .nor-harp {
            transition: 2s ease-out;
             left: -350px;

        }
        
        .stropi-1 {
            position: absolute;
            width: 320px;
            left:calc(50% - 20px);
            z-index: 3;
        }
        
        
        .cirese-s4 {
            position: absolute;
            width: 231px;
            z-index: 4;
            bottom: 90px;
            left: calc(50% - 340px);
            transition: .3s ease-in-out;
            cursor: pointer;
            z-index: 5!important;
            
            &:hover {
                bottom:100px;
                transition: .3s ease-in-out;
            }
            
            @include breakpoint(small only) {
                width: 200px;
                left: calc(50% - 100px);
                bottom: -250px;

            }
        }
        
            .umbra-cirese {
                position: absolute;
                width: 231px;
                z-index: 3;
                bottom: 80px;
                left: calc(50% - 340px);
                transition: .3s ease-in-out;
                cursor: pointer;
                    
                    @include breakpoint(small only) {
                        width: 200px;
                        left: calc(50% - 100px);
                        bottom: -260px;
                    }
                    
                    
                }
        
                .cirese-s4:hover + .umbra-cirese {
                    bottom: 80px;
                    left: calc(50% - 310px);
                    width: 180px;
                    } 
        
        .caise-s4 {
            position: absolute;
            width: 231px;
            z-index: 4;
            top:calc(50% - 115px);
            right: calc(50% - 340px); 
            transition: .3s ease-in-out;
            cursor: pointer;
            
            &:hover {
                top:calc(50% - 125px);
                transition: .3s ease-in-out;
            }
            
            @include breakpoint(small only) {
                width: 200px;
                left:calc(50% - 100px);
                top:480px;
//                bottom:-400px;
            }
            
        }
        
            .umbra-caise {
                position: absolute;
                width: 231px;
                z-index: 3;
                top:calc(50% - -35px);
                right: calc(50% - 340px); 
                transition: .3s ease-in-out;
                cursor: pointer;
                
                @include breakpoint(small only) {
                    width: 200px;
                    left:calc(50% - 100px);
                    top:calc(50% - -225px);
                }
                
            }
        
                .caise-s4:hover + .umbra-caise {;
                    right: calc(50% - 312px);
                    top:calc(50% - -50px);
                    width: 170px;
                    
                        @include breakpoint(small only) {
                            display: none;
                        }
                    
                    } 
        
        // zei
        
        .zeu-caise-s4 {
            position: absolute;
            width: 730px;
            z-index: 2;
            right: calc(50% - 750px);
            bottom: 100px;
            transition: 1s ease-out;
            
            @include breakpoint(small only) {
                width: 700px;
                right: -130px;
                top: 300px;
            }

        }
        
        .nor-harpa {
            position: absolute;
            width: 700px;
            right: -330px;
            top:60px;
        }
        
        .zeu-caise-s4-1 {
            position: absolute;
            width: 350px;
            z-index: 4;
            right: calc(50% - 450px);
            transition: 1s ease-out;
            bottom: 240px;
        }
        
           
        .zeu-cirese-s4 {
           position: absolute;
            width: 500px; 
            z-index: 2;
            left: calc(50% - 650px);
            bottom: 170px;
            transition: 1s ease-out;
            z-index: 4;
           
            
            @include breakpoint(small only) {
                width: 380px;
                left: -70px;
                bottom:-180px;
            }
        }
        
        .nor-bufnita-1 {
            position: absolute;
            width: 700px;
            left:0;
            top: 100px;
//            background-color: aqua;
            z-index: 2;
        }
        
        // nori
        
        .nor-cirese {
            position: absolute;
            z-index: 2;
            width: 800px;
            bottom: -100px;
            left: calc(50% - 700px);
        }
        
        .nor-cirese-top {
            position: absolute;
            width: 400px;
            bottom: -30px;
            z-index: 4;
            left: calc(50% - 450px);
            z-index: 6!important;

        }
        
        
        .nor-harp {
            position: absolute;
            width: 700px;
            left: -350px;
            bottom:150px;
            transition: 2s ease-out;
            
            @include breakpoint(small only) {
                left: -190px;
                width: 800px;
            }
            
        }

    }
    
    .section5 {
        width: 100%;
        height: 768px;  
        position: relative;
        background: #618cc2;
        
        
        .nor-top-5 {
            position: absolute;
            bottom: 185px;
            z-index: 4;
            right: calc(50% - 400px);
        }
        
        &:hover .zeu-capsuna-s5 {
                transition: 1s ease-out;
                right: calc(50% - 560px);

        }
        
        .stropi-2 {
            position: absolute;
            z-index: 3;
            width: 450px;
            margin-left: calc(50% - 470px);
            bottom: 40px;
        }
        
        .birds {
            position: absolute;
            width: 90%;
            left: calc(50% - 45%);
            top:150px;
            z-index: 3;
        }

        .nor-baza-stanga {
            position: absolute;
            bottom: 0px;
            left: -400px;
            width: 700px;
            z-index: 2;
            overflow: hidden;
            
            @include breakpoint(small only) {
                left: -200px;
            }
        }
        
        .nor-baza-dreapta {
            position: absolute;
            bottom: 0px;
            right: -380px;
            width: 700px;
            z-index: 2;
            overflow: hidden;
//            background-color: red;
            
            @include breakpoint(small only) {
                right: -220px;
            }
        }
        
        
        .nor-stanga-2 {
            position: absolute;
            width: 700px;
            left: -250px;
            bottom: 100px;
            
            @include breakpoint(small only) {
                width: 900px;
                left: -100px;
            }
        }
        
        .nor-dreapta-2 {
            
            position: absolute;
            width: 600px;
            right: -400px;
            bottom: 50px;
            
        }
        
        
        
        
        .capsuna-s5 {
            position: absolute;
            right: calc(50% - 291px);
            width: 231px;
            bottom: 250px;
            z-index: 3;
            transition: .3s ease-in-out;
            cursor: pointer;
            
            &:hover {
                bottom: 260px;
                transition: .3s ease-in-out;
            }
            
            @include breakpoint(small only) {
                width: 200px;
                left:calc(50% - 100px);
                bottom:150px;
            }
        }
        
            .umbra-capsuna {
                position: absolute;
                right: calc(50% - 291px);
                width: 231px;
                bottom: 240px;
                z-index: 3;
                transition: .3s ease-in-out;
                cursor: pointer;
                z-index: 2;
                
                @include breakpoint(small only) {
                     bottom: 142px;
                    right: calc(50% - 120px);


                }
            }
        
            .capsuna-s5:hover + .umbra-capsuna {
                    right: calc(50% - 265px);
                    bottom: 240px;
                    width: 170px;
                    } 
        
        
        
        .zeu-capsuna-s5 {
            position: absolute;
            width: 550px;
            z-index: 2;
            right: calc(50% - 650px);
            bottom: 600px;
            z-index: 4;
            transition: 1s ease-out;
            
            @include breakpoint(small only) {
                right: -150px;
                bottom: 220px;
            }
        }
        
        
    }
    
    
}

}

    #raze-contact {
        position: fixed;
        z-index: 1;
        width: 500px;
        left: calc(50% - 250px);
        top:-100px;
        
            @include breakpoint(small only) {
            left: calc(50% - 200px);
            }    
        
    }
    
    #raze-contact-2 {
        position: absolute;
        z-index: 4;
        width: 500px;
        left: calc(50% - 250px);
        top:-50px;
        height: 300px;
        
        @include breakpoint(small only) {
            left: calc(50% - 200px);
        }
    }

    #munte {
        position: absolute;
        width: 200px;
        z-index: 1;
        bottom: 0;
        width: 1390px;
        
        @include breakpoint(small down) {
            display: none;
        }
    }
   

// page mobile clasele sunt dublate. Mobilul se lucreaza de aici

.index-mobil {
    
    display:none;
    position: relative;
    
    @include breakpoint(780px down) {
        
        display: block;
        overflow: hidden;
         
     
    .pop-up {
                position: fixed;
                display: block;
                background-color:#f7f7f7;
                width: 100%;
                height: 100%;
                left:calc(50% - 50%);
                top:0;
                z-index: 11;
                display: none;
        
            .close-icon {
                position: absolute;
                width: 30px;
                height: 30px;
                top:0px;
                right: 0px;
                z-index: 4;
                cursor: pointer;
            
            .xbtn {
                position: absolute;
                width: 25px;
                height: 2px;
                margin-left: 2px;
                margin-top: calc(50% - 1px);
                top:0;
                background-color: $zuzu;
                transform: rotate(45deg);
                
                &::before {
                    content: '';
                    width: 25px;
                    height: 2px;
                    background-color: $zuzu;
                    position: absolute;
                    transform: rotate(-90deg);
                }
            }
                
                @include breakpoint(small only) {
                    
                    top: -10px;
                    right: 10px;
                    
                    .xbtn {
                        width: 35px;
                        
                    &::before {
                            width: 35px;
                        }
                    }
                    
                }
        }     
        
 
        .pergament {display: none;}
        
        .content-zeus, .content-hera {
            position:relative;
            padding-top: 30px;
        }
        
        .content {

                position: absolute;
                width: 90%;
                left:calc(50% - 45%);
                top:0px;
                height: 90%;
                margin-top: 30px;
//                background-color: aqua;
 

            
            .title-info {
                padding-top: 0px;
                font-size: 20px;
            }
            
            .popup-title1 {
                font-size: 16px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }

      
            .lauri {
            position: relative;
            width: 150px;
            margin-left: calc(50% - 75px);
            margin-bottom: 15px;
            margin-top: 5px;
        }
        
                p span {
                    font-weight: 600;
                }
            
            .play-icon {
                position: relative;
                margin-bottom: 15px;
                margin-top: 15px;
                margin-left: calc(50% - 20px);
                width: 40px;
                cursor: pointer;
            }
                 
            audio {
                width: 300px;
                margin-left: calc(50% - 150px);
            }
            
            .lauri-info {
                position: relative;
                width: 150px;
                margin-left: calc(50% - 75px);
                margin-bottom: 8px;
                margin-top: 3px;
            }
            
            .info {
                position: relative;
                width: 50%;
                float: left;
                text-align: center;
                
                p {
                    font-weight: 600;
                }
                
                ul {
                    list-style: none;
                    margin-left: 0;
                    
                    li {
                        color: $zuzu;
                        font-family: 'roma-regular';
                        line-height: 19px;
                    }
                }
            }
            
            .valori {}
            
            .grame {}
            
        }
        
            .spoon {
                position:absolute;
                bottom: 0;
                width: 300px;
                left: 300px;
                bottom: -10px;
                z-index: 3;                
            }

    }  
        
        
        .section-1 {
            
            position: relative;
//            background-color: red;
            
            @include breakpoint(320px up) {
                height: 100vh;
            }
            
            @include breakpoint(320px down) {
                height: 130vh;
            }
            
            
            .iaurt-produs {
                position: absolute;
                width: 230px;
                bottom:-85px;
                right: calc(50% - 115px);
                z-index: 4;
            }
            
            .umbra {
                position: absolute;
                bottom: -90px;
                width: 230px;
                left: calc(50% - 115px);
                z-index: 3;
            }
            
 
        }
        
        .munte-top {
                position: absolute;
                top:225px;
                
            }
        
        .sectiona {
            height: 150vh;
            width: 100%;
//            outline: 2px solid yellow;
            position: relative;
            
            @include breakpoint(320px up) {
                height: 120vh;
            }
            
            @include breakpoint(320px down) {
                height: 150vh;
            }
    
            
            .iaurt-produs {
                position: absolute;
                width: 230px;
                bottom:0;
                right: calc(50% - 115px);
                z-index: 4;
            }
            
            .umbra {
                position: absolute;
                bottom: -10px;
                width: 230px;
                left: calc(50% - 115px);
                z-index: 3;
            }
        }
        
        .section-2 {
            
            position: relative;
            
            .munte-1 {
                position: absolute;
                z-index: -1;
               
                  @include breakpoint(320px up) {
                bottom:40px;
                }

                @include breakpoint(320px down) {
                    bottom:50px;
                }
                
                 @include breakpoint(320px down) {
                    bottom:50px;
                }
                
                
                @include breakpoint(342px down) {
                    bottom: -90px;
                }
                
                  @include breakpoint(360px up) {
                bottom:0px;
                }
                
                  @include breakpoint(400px down) {
                bottom:-130px;
                }
                
                  @include breakpoint(450px down) {
                bottom:-130px;
                }
                
                
                
                }
            
            .munte-top {
                position: absolute;
                margin: auto;
                top:-450px;
                z-index: 2;
            }
            
        }
        
        
        .section-3 {
            
            position: relative;
            
            .munte-2 {
                position: absolute;
                bottom: 0px;
//                right: -100px;
            }
            
        }
        
        
        .section-4 {
            
            position: relative;
            
            .munte-3 {
                position: absolute;
                bottom: 0px;
//                right: -100px;
            }

            
        }
        
        
         .section-5 {
            
            position: relative;
            
            .munte-4 {
                position: absolute;
                bottom: 0px;
//                right: -100px;
            }
            
        }
        
        .section-6 {
            
            position: relative;
            
            .munte-baza {
                position: absolute;
                bottom: 0px;
//                right: -100px;
            }
            
        }
        
        
        .zeus-mobil {
            position: absolute;
            width: 500px;
            bottom: -40px;
            left: -195px;
            z-index: 3;
            
            @include breakpoint(320px up) {
                width: 400px;
                left: -125px;
            }
            
            @include breakpoint(320px down) {
                width: 500px;
            }
            
        }
        
            .nor-zeus {
                    position: absolute;
                    width: 350px;
                    bottom: -180px;
                    right: -50px;
                    z-index: 2;
                }
        
            .nor-zeus-2 {
                    position: absolute;
                    transform: rotate(190deg);
                    left: -50px;
                    bottom: -120px;
                    width: 200px;
                    z-index: 2;
                }
    
        
        .hera-mobil {
            position: absolute;
            width: 400px;
            top: 180px;
            right: -170px;
            z-index: 2;
        }
        
        .nor-grifon-mobil {
            position: absolute;
            display: block;
            width: 800px;
//            background-color: red;
            top:350px;
            right: -100px;
        }
        
        .apollo-mobil {
            position: absolute;
            width: 390px;
            bottom: 70px;
            right: -50px;
            z-index: 2;
        }
        
        .atena-mobil {
            position: absolute;
            width: 600px;
            bottom: 160px;
            left: -40px;
            z-index: 2;
        }
        
        .dragon {
            position: absolute;
            z-index: 4;
            top: 100px;
            z-index: red;
            width: 320px;
            left: -80px;
        }
        
        .afrodita-mobil {
            position: absolute;
            width: 1000px;
            bottom: 160px;
            left: 0px;
            z-index: 3;
        }

        .nor-hera {
            position: absolute;
            width: 300px;
            bottom: -90px;
            z-index: 2;
        }
        
        .nor-hera-2 {
            position: absolute;
            width: 250px;
            bottom: -100px;
            right: -50px;
            z-index: 2;
            transform: rotate(180deg);
//            background-color: red;
        }
        
        .nor-afrodita {
            position: absolute;
            width: 300px;
            bottom: -90px;
            z-index: 2;
            left: -20px;
        }
        
        .nor-afrodita-2 {
            position: absolute;
            width: 300px;
            bottom: -90px;
            z-index: 2;
            right: 0;
            transform: rotate(180deg);
        }
  
            
            .templu-1 {
                position: absolute;
                z-index: 3;
                bottom: 0px;
                left: -110px;
            }
        
            .templu-2 {
                position: absolute;
                z-index: 3;
                bottom: 0px;
                right: -130px;
//                width: 200px;
            }
        
        .nor-templu-1 {
            position: absolute;
            bottom: -20px;
            left: -110px;
            width: 200px;
//            background-color: red;
            z-index: 4;
        }

        .nor-templu-2 {
            position: absolute;
            bottom: -20px;
            right: -130px;
            width: 250px;
//            background-color: red;
            z-index: 4;
        }
        
//        .nor-cirese {
//            background-color: red;
//        }

    }
    
}


#munte-mobil {
        position: absolute;
        bottom: 0px;
        z-index: 1;
        
            @include breakpoint(780px up) {
                display: none;
            }
        
            @include breakpoint(780px down) {
                display: block;
            }
        
    }
  


#popup-index-mobile-scroll {
    
    height: 100%;
    overflow: scroll;
    
}


