.zuzu-header-container {
    position: fixed;
    width: 100%;
    height: 130px;
    z-index: 5!important;
    top:0;
    left:0;
    
    @include breakpoint(780px down) {
        height: 200px;
        z-index: 2;
    }
    
    .logo-zuzu {
        position: relative;
        float: left;
        width: 120px;
        margin-left: 50px;
        padding-top: 22px;
        transition: .3s;
        z-index: 2;
        
            @include breakpoint(780px down) {
                position: relative;
                width: 100px;
                left:calc(50% - 50px);
                margin: auto;
            }
    }
    
    .logo-divin  {
        position: relative;
        float: left;
        width: 180px;
        margin-left: calc(50% - 240px);
        padding-top: 20px;
        margin-right: 0px;
        transition: .3s;
        z-index: 3;
//        background-color: aquamarine;
        
        @include breakpoint(780px down) {
            
            width: 100%;
            margin: 20px 0;
            z-index: -2;
            
            img {
                width: 150px;
                display: block;
                margin: auto;
                margin-top: -20px;
            }
        }
    } 
    
    nav {
            @include breakpoint(780px down) {
                display: none;
                position: fixed;
                height: 90%;
                width: 100%;
                margin-left: 0;
                top:0px;
                padding-top: 120px;
                margin-top: 0;
                z-index: 8!important;
            }
    
    ul {
        position: relative;
        margin-top: 30px;
        padding-top:0;
        float: right;
        transition: .3s ease-in-out;
        
        
        @include breakpoint(780px down) {
            position: absolute;
            width: 180px;
            margin-left: calc(50% - 90px);
        }
        
        
        
        
        li {
            list-style: none;
            float: left;
            padding: 8px;
            text-transform: uppercase;
            font-family: 'roma-bold';
            
            a {
                color: $white;
//                padding: 7px; 
            }
            
                @include breakpoint(780px down) {
                    float: none;
                    width: 180px;
                    margin: auto;
                    
                    a {
                        color: $zuzu;
                    }
                }
            
            }
        

        &:hover {
            ul {
                display: block;
            }
        }
    }
        
    }
}

.menu-container-fixed {
    position: fixed;
    height: 70px;
    z-index: 2;
    
  
    .logo-zuzu {
        position: relative;
        float: left;
        width: 70px;
        margin-left: 70px;
        padding-top: 10px;
        transition: .3s;
        z-index: 2;
        
            @include breakpoint(780px down) {
//                    float: left;
                    width: 0px;
                    margin-left: 25px;
                padding-left: 20px;
                    z-index: 2;
                    display: block;
            }

    }
    
    .logo-divin  {
        position: relative;
        float: left;
        width: 0px;
        margin-left: calc(50% - 12z0px);
        padding-top: 20px;
        margin-right: 100px;
        transition: .3s;
        z-index: 1;
        
        
        @include breakpoint(780px down) {
            
            width: 100%;
            margin: 20px 0;
            transition: .3s;
            
            
            img {
                width: 0px;
                display: block;
                margin: auto;
                transition: .3s;
            }
        }
        
        
    } 
    
    nav {
        
//        z-index: 3;
    
    ul {
        margin-top: 10px;
        padding-top:0;
        float: right;
        position: relative;
        transition: .3s ease-in-out;
        
              @include breakpoint(780px down) {
                    position: absolute;
                    width: 180px;
                    margin-left: calc(50% - 90px);
                }

        
        li {
            list-style: none;
            float: left;
            padding: 8px;
            text-transform: uppercase;
            font-family: 'roma-bold';
            
            a {
                color: $zuzu;
                padding: 5px;
                background-color: rgba(255, 255, 255, 0.87);
                border-radius: 8px;
                
                @include breakpoint(780px down) {
                    background-color: none;
                }
            }

        }
        
    
    }
    
}
    

    
}



.menu-mobile {
    
    display: none;
    
    @include breakpoint(780px down) {
    
        position: fixed;
        width: 50px;
        height: 50px;
        z-index: 8!important;
        cursor: pointer;
        right: 0;
        top:0;
        display: block;
        
        .hamburger    {
            
            width: 30px;
            height: 3px;
            background-color: $zuzu;
            position: relative;
            margin: auto;
            margin-top: calc(50% - 1px);
            transition: .2s ease-in-out;
            border-radius: 15px;

            
            &::before {
                content: '';
                width: 30px;
                height: 3px;
                background-color: $zuzu;
                position: absolute;
                top:-7px;
                transition: .2s ease-in-out;
                border-radius: 15px;
            }
            
            &::after {
                content: '';
                width: 30px;
                height: 3px;
                background-color: $zuzu;
                position: absolute;
                top:7px;
                transition: .2s ease-in-out;
                border-radius: 15px;
            }
            
        }
    }
    
}
    
    .active {
        
        cursor: pointer;
        
            .hamburger    {
                position: absolute;
                width: 30px;
                height: 2px;
                background-color: rgba(0, 0, 0, 0);
                position: relative;
                margin: auto;
                margin-top: calc(50% - 1px);
                transition: .2s ease-in-out;
                top:0;
            
            &::before {
                transform: rotate(45deg);
                background-color: $zuzu;
                top:0px;
                transition: .2s ease-in-out;

            }
            
            &::after {
                transform: rotate(-45deg);
                background-color: $zuzu;
                top:0;
                transition: .2s ease-in-out;


            }
            
        }
        
    }


// nori meniu

.nori-meniu {
    
    

    z-index: 1;
    
        .nor-meniu-1 {
        position: fixed;
        top:20px;
        right: -485px;
        
        transition: .5s ease-in-out;
    }
    
    
       .nor-meniu-22 {
        position: fixed;
           width: 500px;
        top: 20px;
        left: -460px;
        transition: .5s ease-in-out;
    }
    
        .nor-meniu-3 {
            position: fixed;
//            width: 500px;
            bottom: 50px;
            left: -460px;
            transition: .5s ease-in-out;
            transition: 1s ease-in-out;
        }
    
    
        .nor-meniu-4 {
            position: fixed;
            bottom:50px;
            right: -650px;
            transition: .8s ease-in-out;
        }
    
}


.active-nori  {
    
//    z-index: 6;
    
        .nor-meniu-1 {
        position: fixed;
        top:20px;
        right: -100px;
        transition: .5s ease-in-out;
        z-index: 2;
    }
    
    
       .nor-meniu-22 {
        position: fixed;
        width: 500px;
        bottom: 20px;
        left: -50px;
        transition: .5s ease-in-out;
    }
      
    
           .nor-meniu-3 {
        position: fixed;
        width: 500px;
        bottom: 50px;
        left: -50px;
        transition: 1s ease-in-out;
    }
    
    
        .nor-meniu-4 {
            position: fixed;
            bottom:50px;
            right: -100px;
            transition: .8s ease-in-out;
            z-index: 2;
        }
    
}








