
body {
    background-color: #638dc0;
    overflow-x: hidden;
}


@font-face {
  font-family: 'roma-regular';
  src: url('/assets/fonts/vag/roma-regular.ttf');
}

@font-face {
  font-family: 'roma-bold';
  src: url('/assets/fonts/vag/roma-regular.ttf');
}


.clear {
    clear:both;
}


h1 {
    color: $zuzu;
    font-family: 'roma-bold';
    font-size: 20px;
    text-align: center;
    line-height: 27px;
    text-transform: uppercase;
    
    span {
        font-size: 22px;
        text-transform: uppercase;
    }
}

h2 {
    color:$zuzu;
    font-family: 'roma-bold', sans-serif;
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
}

p {
    color:$zuzu;
      font-family: 'roma-regular';
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom:0;    
}

.btn-zuzu {
    width: 200px;
//    background-color: red;
    height: 30px;
    text-align: center;
    border-radius: 10px;
    padding-top: 4px;
    font-family: 'roma-regular';
    text-transform: uppercase;
    border: 1px solid $brand;
    margin: 10px auto;
    color: $brand;
    cursor: pointer;
    
    a {
        color: #22349a;
    }
}