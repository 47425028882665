.wrapp-contact {
    position: relative;
    height: auto;
    overflow: hidden;
    
    .section1-contact {
        position: relative;
        max-width: 1280px;
        height: 100vh;
        margin: auto;
//        outline: 2px solid yellow;
        
.pergament-box-contact {
    position: absolute;
    width: 500px;
    height: 500px;
    top: 200px;
    left: calc(50% - 250px);
    z-index: 4;
//    background-color: orangered;
    
    @include breakpoint(small only) {
        top:-30px;
    }
    
    .perga-final {
        
        @include breakpoint(small only) {
            display: none;
        }
    }
    
    .form-wrapper-contact {
        position: absolute;
        top:100px;
        width: 380px;
        left:calc(50% - 190px);
        
        @include breakpoint(small only) {
            top:270px;
            width: 60%;
            left: calc(50% - 30%);
        } 
        
        .title-contact {
            text-align: left;
            font-family: 'roma-bold';
        }
        
        .check-text {
            color: $zuzu;
            font-size: 13px;
        }

        [type="checkbox"] {
            margin: 0;
            color: $zuzu;
        }
        
        [type='text'] {
            box-shadow: none;
            border-right: none;
        }
        
       
    
    }
}
        
        .nor-contact-1 {
            position: absolute;
            bottom: 0;
            left: 0;
            
 
        }
        .nor-contact-2 {
            position: absolute;
            bottom: 0;
            right: 0;
        }
        .nor-contact-3 {
            position: absolute;
            left: 0;
            bottom:250px;
            width: 500px;
            
                        
            @include breakpoint(small only) {
                bottom: -40px;
                right: -50px;
            }
            
        }
        .nor-contact-4 {
            position: absolute;
        bottom: 250px;
            right: 0;
            
            @include breakpoint(small only) {
                bottom: 0;
                left: -100px;
            }
            
            
        }
        
        .nor-contact-5 {
            position: absolute;
            bottom: -250px;
            width: 600px;
            left: calc(50% - 300px);
        }
      

    }
    
    
    .section2-contact {
        position: relative;
        max-width: 1280px;
        height: 60vh;
        margin: auto;
//        outline: 2px solid yellow;
        
        @include breakpoint(small down) {
            
            height: 120vh;
            padding-top: 100px;
        }

    }
    
    
    
.box-info {
    width: 400px;
    height: 250px;
//    background-color: aquamarine;
    z-index: 3;
    position: relative;
    margin: auto;
    overflow: scroll;
    
    span{
        color: $zuzu;
        font-weight: 600;
        margin-top: 20px;

    }
    
    p {
        text-align: left;
    }
    
    @include breakpoint(small only) {
        margin-top: 60px;
        width: 80%;
    }
}

    
    
}



.box-info::-webkit-scrollbar {
  width: .2em; 
}
.box-info::-webkit-scrollbar,
.box-info::-webkit-scrollbar-thumb {
  overflow:visible;
  border-radius: 4px;
}
.box-info::-webkit-scrollbar-thumb {
//  background: $zuzu; 
    background-color: rgba(34, 52, 154, 0.6);

}
.cover-bar {
  position: absolute;
  background: red;
  height: 100%;  
  top: 0;
  right: 0;
  width: .4em;
  -webkit-transition: all .5s;
  opacity: 1;

}

.box-info:hover .cover-bar {
//   opacity: 0;
  -webkit-transition: all .5s;
    background-color: red;
    
}

.box-info::-webkit-scrollbar-corner {
//  background: $zuzu; 
    background-color: none;
}



form {
    
    input[type='text'] {
        border-bottom: 2px solid $zuzu;
        background-color: rgba(255, 255, 255, 0);
        border-top:0px;
        border-left: 0px;
        border-radius: 0px;
        box-shadow:inset 0 0px 2px rgba(28, 31, 42, 0.1);
        font-family: 'roma-regular';
        color:$zuzu;
        text-align: center;
        transition: .5s ease-in-out;
    }
    
    
    input[type=text]:focus {
        background-color: rgba(255, 255, 255, 0);
        transition: .5s ease-in-out;
        border: none;
        border-bottom: 2px solid $zuzu;
    }
    
    
    input[type='email'] {
        border-bottom: 2px solid $zuzu;
        background-color: rgba(255, 255, 255, 0);
        border-top:0px;
        border-left: 0px;
        border-right:none;
        border-radius: 0px;
        box-shadow:none;
        font-family: 'roma-regular';
        color:$zuzu;
        text-align: center;
        transition: .5s ease-in-out;
    }
    
    input[type='email']:focus {
        background-color: rgba(255, 255, 255, 0);
        border-top:none;
        border-right:none;
        border-left:none;
        box-shadow: none;
    
    }
    
    
    textarea {
        border-bottom: 2px solid $zuzu;
        border-top:none;
        border-left:none;
        border-right:none;
        box-shadow: none;
        background-color: rgba(255, 255, 255, 0);
    }
    
    textarea:focus {
        background-color: rgba(255, 255, 255, 0);
        box-shadow: none;
        border-top:none;
        border-left:none;
        border-right:none;
        
    }
    
    
    #message {
        background-color: none;
    }
    
    
    
    #submit {
        background-color: $zuzu;
        padding: 8px 25px;
        border-radius: 5px; 
        color:white;
        cursor: pointer;
        width: 150px;
        margin-left: calc(50% - 75px);
        margin-top: 30px;
        

    }
    
    


    ::placeholder {
        color: $zuzu;
        font-family: 'roma-bold';
            text-align: center;
        }

    
}


.back-alb-2 {
    @include breakpoint(small only) {
        display: none;
    }
}


#raze-contact {
    position: fixed;
    width: 600px;
    top:-150px;
    left: calc(50% - 300px);
    z-index: 1;
    
    @include breakpoint(small only) {
    left: calc(50% - 200px);
    }
}

