@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "x-small=0em&small=20em&medium=40em&large=80em&xlarge=105em&xxlarge=123.75em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: Roboto, Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #1c1f2a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 125rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  @media print, screen and (min-width: 20em) {
    .row .row {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  @media print, screen and (min-width: 40em) {
    .row .row {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }
  @media print, screen and (min-width: 80em) {
    .row .row {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }
  @media screen and (min-width: 105em) {
    .row .row {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }
  @media screen and (min-width: 123.75em) {
    .row .row {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }
  .row .row.collapse {
    margin-right: 0;
    margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left; }
  @media print, screen and (min-width: 20em) {
    .column, .columns {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

@media print, screen and (min-width: 20em) {
  .small-1 {
    width: 8.33333%; }
  .small-push-1 {
    position: relative;
    left: 8.33333%; }
  .small-pull-1 {
    position: relative;
    left: -8.33333%; }
  .small-offset-0 {
    margin-left: 0%; }
  .small-2 {
    width: 16.66667%; }
  .small-push-2 {
    position: relative;
    left: 16.66667%; }
  .small-pull-2 {
    position: relative;
    left: -16.66667%; }
  .small-offset-1 {
    margin-left: 8.33333%; }
  .small-3 {
    width: 25%; }
  .small-push-3 {
    position: relative;
    left: 25%; }
  .small-pull-3 {
    position: relative;
    left: -25%; }
  .small-offset-2 {
    margin-left: 16.66667%; }
  .small-4 {
    width: 33.33333%; }
  .small-push-4 {
    position: relative;
    left: 33.33333%; }
  .small-pull-4 {
    position: relative;
    left: -33.33333%; }
  .small-offset-3 {
    margin-left: 25%; }
  .small-5 {
    width: 41.66667%; }
  .small-push-5 {
    position: relative;
    left: 41.66667%; }
  .small-pull-5 {
    position: relative;
    left: -41.66667%; }
  .small-offset-4 {
    margin-left: 33.33333%; }
  .small-6 {
    width: 50%; }
  .small-push-6 {
    position: relative;
    left: 50%; }
  .small-pull-6 {
    position: relative;
    left: -50%; }
  .small-offset-5 {
    margin-left: 41.66667%; }
  .small-7 {
    width: 58.33333%; }
  .small-push-7 {
    position: relative;
    left: 58.33333%; }
  .small-pull-7 {
    position: relative;
    left: -58.33333%; }
  .small-offset-6 {
    margin-left: 50%; }
  .small-8 {
    width: 66.66667%; }
  .small-push-8 {
    position: relative;
    left: 66.66667%; }
  .small-pull-8 {
    position: relative;
    left: -66.66667%; }
  .small-offset-7 {
    margin-left: 58.33333%; }
  .small-9 {
    width: 75%; }
  .small-push-9 {
    position: relative;
    left: 75%; }
  .small-pull-9 {
    position: relative;
    left: -75%; }
  .small-offset-8 {
    margin-left: 66.66667%; }
  .small-10 {
    width: 83.33333%; }
  .small-push-10 {
    position: relative;
    left: 83.33333%; }
  .small-pull-10 {
    position: relative;
    left: -83.33333%; }
  .small-offset-9 {
    margin-left: 75%; }
  .small-11 {
    width: 91.66667%; }
  .small-push-11 {
    position: relative;
    left: 91.66667%; }
  .small-pull-11 {
    position: relative;
    left: -91.66667%; }
  .small-offset-10 {
    margin-left: 83.33333%; }
  .small-12 {
    width: 100%; }
  .small-offset-11 {
    margin-left: 91.66667%; }
  .small-up-1 > .column, .small-up-1 > .columns {
    float: left;
    width: 100%; }
    .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
      float: left; }
  .small-up-2 > .column, .small-up-2 > .columns {
    float: left;
    width: 50%; }
    .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
      float: left; }
  .small-up-3 > .column, .small-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
      float: left; }
  .small-up-4 > .column, .small-up-4 > .columns {
    float: left;
    width: 25%; }
    .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
      float: left; }
  .small-up-5 > .column, .small-up-5 > .columns {
    float: left;
    width: 20%; }
    .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
      float: left; }
  .small-up-6 > .column, .small-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
      float: left; }
  .small-up-7 > .column, .small-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
      float: left; }
  .small-up-8 > .column, .small-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
      float: left; }
  .small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .small-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .small-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .small-centered {
    margin-right: auto;
    margin-left: auto; }
    .small-centered, .small-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .small-uncentered,
  .small-push-0,
  .small-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 80em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 105em) {
  .xlarge-1 {
    width: 8.33333%; }
  .xlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    width: 16.66667%; }
  .xlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-3 {
    width: 25%; }
  .xlarge-push-3 {
    position: relative;
    left: 25%; }
  .xlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-4 {
    width: 33.33333%; }
  .xlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    width: 41.66667%; }
  .xlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-6 {
    width: 50%; }
  .xlarge-push-6 {
    position: relative;
    left: 50%; }
  .xlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-7 {
    width: 58.33333%; }
  .xlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    width: 66.66667%; }
  .xlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-9 {
    width: 75%; }
  .xlarge-push-9 {
    position: relative;
    left: 75%; }
  .xlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-10 {
    width: 83.33333%; }
  .xlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    width: 91.66667%; }
  .xlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-12 {
    width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xlarge-up-1 > .column:nth-of-type(1n), .xlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-1 > .column:nth-of-type(1n+1), .xlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xlarge-up-1 > .column:last-child, .xlarge-up-1 > .columns:last-child {
      float: left; }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xlarge-up-2 > .column:nth-of-type(1n), .xlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-2 > .column:nth-of-type(2n+1), .xlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xlarge-up-2 > .column:last-child, .xlarge-up-2 > .columns:last-child {
      float: left; }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xlarge-up-3 > .column:nth-of-type(1n), .xlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-3 > .column:nth-of-type(3n+1), .xlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xlarge-up-3 > .column:last-child, .xlarge-up-3 > .columns:last-child {
      float: left; }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xlarge-up-4 > .column:nth-of-type(1n), .xlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-4 > .column:nth-of-type(4n+1), .xlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xlarge-up-4 > .column:last-child, .xlarge-up-4 > .columns:last-child {
      float: left; }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xlarge-up-5 > .column:nth-of-type(1n), .xlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-5 > .column:nth-of-type(5n+1), .xlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xlarge-up-5 > .column:last-child, .xlarge-up-5 > .columns:last-child {
      float: left; }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xlarge-up-6 > .column:nth-of-type(1n), .xlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-6 > .column:nth-of-type(6n+1), .xlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xlarge-up-6 > .column:last-child, .xlarge-up-6 > .columns:last-child {
      float: left; }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xlarge-up-7 > .column:nth-of-type(1n), .xlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-7 > .column:nth-of-type(7n+1), .xlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xlarge-up-7 > .column:last-child, .xlarge-up-7 > .columns:last-child {
      float: left; }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xlarge-up-8 > .column:nth-of-type(1n), .xlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-8 > .column:nth-of-type(8n+1), .xlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xlarge-up-8 > .column:last-child, .xlarge-up-8 > .columns:last-child {
      float: left; }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .xlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xlarge-centered, .xlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xlarge-uncentered,
  .xlarge-push-0,
  .xlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-1 {
    width: 8.33333%; }
  .xxlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xxlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    width: 16.66667%; }
  .xxlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xxlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .xxlarge-3 {
    width: 25%; }
  .xxlarge-push-3 {
    position: relative;
    left: 25%; }
  .xxlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .xxlarge-4 {
    width: 33.33333%; }
  .xxlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xxlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .xxlarge-5 {
    width: 41.66667%; }
  .xxlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xxlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .xxlarge-6 {
    width: 50%; }
  .xxlarge-push-6 {
    position: relative;
    left: 50%; }
  .xxlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .xxlarge-7 {
    width: 58.33333%; }
  .xxlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xxlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .xxlarge-8 {
    width: 66.66667%; }
  .xxlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xxlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .xxlarge-9 {
    width: 75%; }
  .xxlarge-push-9 {
    position: relative;
    left: 75%; }
  .xxlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .xxlarge-10 {
    width: 83.33333%; }
  .xxlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xxlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .xxlarge-11 {
    width: 91.66667%; }
  .xxlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xxlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .xxlarge-12 {
    width: 100%; }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xxlarge-up-1 > .column:nth-of-type(1n), .xxlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-1 > .column:nth-of-type(1n+1), .xxlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xxlarge-up-1 > .column:last-child, .xxlarge-up-1 > .columns:last-child {
      float: left; }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xxlarge-up-2 > .column:nth-of-type(1n), .xxlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-2 > .column:nth-of-type(2n+1), .xxlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xxlarge-up-2 > .column:last-child, .xxlarge-up-2 > .columns:last-child {
      float: left; }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xxlarge-up-3 > .column:nth-of-type(1n), .xxlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-3 > .column:nth-of-type(3n+1), .xxlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xxlarge-up-3 > .column:last-child, .xxlarge-up-3 > .columns:last-child {
      float: left; }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xxlarge-up-4 > .column:nth-of-type(1n), .xxlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-4 > .column:nth-of-type(4n+1), .xxlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xxlarge-up-4 > .column:last-child, .xxlarge-up-4 > .columns:last-child {
      float: left; }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xxlarge-up-5 > .column:nth-of-type(1n), .xxlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-5 > .column:nth-of-type(5n+1), .xxlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xxlarge-up-5 > .column:last-child, .xxlarge-up-5 > .columns:last-child {
      float: left; }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xxlarge-up-6 > .column:nth-of-type(1n), .xxlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-6 > .column:nth-of-type(6n+1), .xxlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xxlarge-up-6 > .column:last-child, .xxlarge-up-6 > .columns:last-child {
      float: left; }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xxlarge-up-7 > .column:nth-of-type(1n), .xxlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-7 > .column:nth-of-type(7n+1), .xxlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xxlarge-up-7 > .column:last-child, .xxlarge-up-7 > .columns:last-child {
      float: left; }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xxlarge-up-8 > .column:nth-of-type(1n), .xxlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-8 > .column:nth-of-type(8n+1), .xxlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xxlarge-up-8 > .column:last-child, .xxlarge-up-8 > .columns:last-child {
      float: left; }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xxlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .xxlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xxlarge-centered, .xxlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xxlarge-uncentered,
  .xxlarge-push-0,
  .xxlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 20em) {
  .column-block {
    margin-bottom: 1.25rem; }
    .column-block > :last-child {
      margin-bottom: 0; } }

@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.875rem; }
    .column-block > :last-child {
      margin-bottom: 0; } }

.row {
  max-width: 125rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  @media print, screen and (min-width: 20em) {
    .row .row {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  @media print, screen and (min-width: 40em) {
    .row .row {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }
  @media print, screen and (min-width: 80em) {
    .row .row {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }
  @media screen and (min-width: 105em) {
    .row .row {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }
  @media screen and (min-width: 123.75em) {
    .row .row {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }
  .row .row.collapse {
    margin-right: 0;
    margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  min-width: 0; }
  @media print, screen and (min-width: 20em) {
    .column, .columns {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

@media print, screen and (min-width: 20em) {
  .small-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .small-offset-0 {
    margin-left: 0%; }
  .small-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .small-offset-1 {
    margin-left: 8.33333%; }
  .small-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .small-offset-2 {
    margin-left: 16.66667%; }
  .small-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .small-offset-3 {
    margin-left: 25%; }
  .small-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .small-offset-4 {
    margin-left: 33.33333%; }
  .small-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .small-offset-5 {
    margin-left: 41.66667%; }
  .small-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .small-offset-6 {
    margin-left: 50%; }
  .small-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .small-offset-7 {
    margin-left: 58.33333%; }
  .small-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .small-offset-8 {
    margin-left: 66.66667%; }
  .small-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .small-offset-9 {
    margin-left: 75%; }
  .small-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .small-offset-10 {
    margin-left: 83.33333%; }
  .small-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .small-offset-11 {
    margin-left: 91.66667%; }
  .small-up-1 {
    flex-wrap: wrap; }
    .small-up-1 > .column, .small-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .small-up-2 {
    flex-wrap: wrap; }
    .small-up-2 > .column, .small-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .small-up-3 {
    flex-wrap: wrap; }
    .small-up-3 > .column, .small-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .small-up-4 {
    flex-wrap: wrap; }
    .small-up-4 > .column, .small-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .small-up-5 {
    flex-wrap: wrap; }
    .small-up-5 > .column, .small-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .small-up-6 {
    flex-wrap: wrap; }
    .small-up-6 > .column, .small-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .small-up-7 {
    flex-wrap: wrap; }
    .small-up-7 > .column, .small-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .small-up-8 {
    flex-wrap: wrap; }
    .small-up-8 > .column, .small-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 20em) and (min-width: 20em) {
  .small-expand {
    flex: 1 1 0px; } }

.row.small-unstack > .column, .row.small-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 20em) {
    .row.small-unstack > .column, .row.small-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 20em) {
  .small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

@media print, screen and (min-width: 80em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 80em) and (min-width: 80em) {
  .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 80em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 80em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

@media screen and (min-width: 105em) {
  .xlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-up-1 {
    flex-wrap: wrap; }
    .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xlarge-up-2 {
    flex-wrap: wrap; }
    .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xlarge-up-3 {
    flex-wrap: wrap; }
    .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xlarge-up-4 {
    flex-wrap: wrap; }
    .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xlarge-up-5 {
    flex-wrap: wrap; }
    .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xlarge-up-6 {
    flex-wrap: wrap; }
    .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xlarge-up-7 {
    flex-wrap: wrap; }
    .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xlarge-up-8 {
    flex-wrap: wrap; }
    .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 105em) and (min-width: 105em) {
  .xlarge-expand {
    flex: 1 1 0px; } }

.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 105em) {
    .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
      flex: 1 1 0px; } }

@media screen and (min-width: 105em) {
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .xxlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .xxlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .xxlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .xxlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .xxlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .xxlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .xxlarge-up-1 {
    flex-wrap: wrap; }
    .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xxlarge-up-2 {
    flex-wrap: wrap; }
    .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xxlarge-up-3 {
    flex-wrap: wrap; }
    .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xxlarge-up-4 {
    flex-wrap: wrap; }
    .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xxlarge-up-5 {
    flex-wrap: wrap; }
    .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xxlarge-up-6 {
    flex-wrap: wrap; }
    .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xxlarge-up-7 {
    flex-wrap: wrap; }
    .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xxlarge-up-8 {
    flex-wrap: wrap; }
    .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 123.75em) and (min-width: 123.75em) {
  .xxlarge-expand {
    flex: 1 1 0px; } }

.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 123.75em) {
    .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
      flex: 1 1 0px; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

@media print, screen and (min-width: 20em) {
  .column-block {
    margin-bottom: 1.25rem; }
    .column-block > :last-child {
      margin-bottom: 0; } }

@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.875rem; }
    .column-block > :last-child {
      margin-bottom: 0; } }

.grid-container {
  max-width: 125rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 20em) {
    .grid-container {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 40em) {
    .grid-container {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .grid-container.fluid {
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 20em) {
      .grid-container.fluid {
        padding-right: 0.625rem;
        padding-left: 0.625rem; } }
    @media print, screen and (min-width: 40em) {
      .grid-container.fluid {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

@media print, screen and (min-width: 20em) {
  .grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 80em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

@media screen and (min-width: 105em) {
  .grid-x > .xlarge-shrink, .grid-x > .xlarge-full, .grid-x > .xlarge-1, .grid-x > .xlarge-2, .grid-x > .xlarge-3, .grid-x > .xlarge-4, .grid-x > .xlarge-5, .grid-x > .xlarge-6, .grid-x > .xlarge-7, .grid-x > .xlarge-8, .grid-x > .xlarge-9, .grid-x > .xlarge-10, .grid-x > .xlarge-11, .grid-x > .xlarge-12 {
    flex-basis: auto; } }

@media screen and (min-width: 123.75em) {
  .grid-x > .xxlarge-shrink, .grid-x > .xxlarge-full, .grid-x > .xxlarge-1, .grid-x > .xxlarge-2, .grid-x > .xxlarge-3, .grid-x > .xxlarge-4, .grid-x > .xxlarge-5, .grid-x > .xxlarge-6, .grid-x > .xxlarge-7, .grid-x > .xxlarge-8, .grid-x > .xxlarge-9, .grid-x > .xxlarge-10, .grid-x > .xxlarge-11, .grid-x > .xxlarge-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 20em) {
  .grid-x > .small-1 {
    width: 8.33333%; }
  .grid-x > .small-2 {
    width: 16.66667%; }
  .grid-x > .small-3 {
    width: 25%; }
  .grid-x > .small-4 {
    width: 33.33333%; }
  .grid-x > .small-5 {
    width: 41.66667%; }
  .grid-x > .small-6 {
    width: 50%; }
  .grid-x > .small-7 {
    width: 58.33333%; }
  .grid-x > .small-8 {
    width: 66.66667%; }
  .grid-x > .small-9 {
    width: 75%; }
  .grid-x > .small-10 {
    width: 83.33333%; }
  .grid-x > .small-11 {
    width: 91.66667%; }
  .grid-x > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 80em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

@media screen and (min-width: 105em) {
  .grid-x > .xlarge-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .xlarge-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .xlarge-1 {
    width: 8.33333%; }
  .grid-x > .xlarge-2 {
    width: 16.66667%; }
  .grid-x > .xlarge-3 {
    width: 25%; }
  .grid-x > .xlarge-4 {
    width: 33.33333%; }
  .grid-x > .xlarge-5 {
    width: 41.66667%; }
  .grid-x > .xlarge-6 {
    width: 50%; }
  .grid-x > .xlarge-7 {
    width: 58.33333%; }
  .grid-x > .xlarge-8 {
    width: 66.66667%; }
  .grid-x > .xlarge-9 {
    width: 75%; }
  .grid-x > .xlarge-10 {
    width: 83.33333%; }
  .grid-x > .xlarge-11 {
    width: 91.66667%; }
  .grid-x > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 123.75em) {
  .grid-x > .xxlarge-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .xxlarge-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .xxlarge-1 {
    width: 8.33333%; }
  .grid-x > .xxlarge-2 {
    width: 16.66667%; }
  .grid-x > .xxlarge-3 {
    width: 25%; }
  .grid-x > .xxlarge-4 {
    width: 33.33333%; }
  .grid-x > .xxlarge-5 {
    width: 41.66667%; }
  .grid-x > .xxlarge-6 {
    width: 50%; }
  .grid-x > .xxlarge-7 {
    width: 58.33333%; }
  .grid-x > .xxlarge-8 {
    width: 66.66667%; }
  .grid-x > .xxlarge-9 {
    width: 75%; }
  .grid-x > .xxlarge-10 {
    width: 83.33333%; }
  .grid-x > .xxlarge-11 {
    width: 91.66667%; }
  .grid-x > .xxlarge-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

@media print, screen and (min-width: 20em) {
  .grid-margin-x {
    margin-left: -0.625rem;
    margin-right: -0.625rem; } }

@media print, screen and (min-width: 40em) {
  .grid-margin-x {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; } }

.grid-margin-x > .cell {
  width: calc(100% - ); }

@media print, screen and (min-width: 20em) {
  .grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem; } }

@media print, screen and (min-width: 40em) {
  .grid-margin-x > .cell {
    width: calc(100% - 1.875rem);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem; } }

@media print, screen and (min-width: 20em) {
  .grid-margin-x > .small-auto {
    width: auto; }
  .grid-margin-x > .small-shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.25rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.25rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.25rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.25rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.25rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.25rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.25rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.25rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.25rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.25rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-x > .small-auto {
    width: auto; }
  .grid-margin-x > .small-shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.875rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.875rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.875rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.875rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.875rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.875rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.875rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.875rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.875rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.875rem); }
  .grid-margin-x > .medium-auto {
    width: auto; }
  .grid-margin-x > .medium-shrink {
    width: auto; }
  .grid-margin-x > .medium-1 {
    width: calc(8.33333% - 1.875rem); }
  .grid-margin-x > .medium-2 {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x > .medium-3 {
    width: calc(25% - 1.875rem); }
  .grid-margin-x > .medium-4 {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x > .medium-5 {
    width: calc(41.66667% - 1.875rem); }
  .grid-margin-x > .medium-6 {
    width: calc(50% - 1.875rem); }
  .grid-margin-x > .medium-7 {
    width: calc(58.33333% - 1.875rem); }
  .grid-margin-x > .medium-8 {
    width: calc(66.66667% - 1.875rem); }
  .grid-margin-x > .medium-9 {
    width: calc(75% - 1.875rem); }
  .grid-margin-x > .medium-10 {
    width: calc(83.33333% - 1.875rem); }
  .grid-margin-x > .medium-11 {
    width: calc(91.66667% - 1.875rem); }
  .grid-margin-x > .medium-12 {
    width: calc(100% - 1.875rem); } }

@media print, screen and (min-width: 80em) {
  .grid-margin-x > .large-auto {
    width: auto; }
  .grid-margin-x > .large-shrink {
    width: auto; }
  .grid-margin-x > .large-1 {
    width: calc(8.33333% - 1.875rem); }
  .grid-margin-x > .large-2 {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x > .large-3 {
    width: calc(25% - 1.875rem); }
  .grid-margin-x > .large-4 {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x > .large-5 {
    width: calc(41.66667% - 1.875rem); }
  .grid-margin-x > .large-6 {
    width: calc(50% - 1.875rem); }
  .grid-margin-x > .large-7 {
    width: calc(58.33333% - 1.875rem); }
  .grid-margin-x > .large-8 {
    width: calc(66.66667% - 1.875rem); }
  .grid-margin-x > .large-9 {
    width: calc(75% - 1.875rem); }
  .grid-margin-x > .large-10 {
    width: calc(83.33333% - 1.875rem); }
  .grid-margin-x > .large-11 {
    width: calc(91.66667% - 1.875rem); }
  .grid-margin-x > .large-12 {
    width: calc(100% - 1.875rem); } }

@media screen and (min-width: 105em) {
  .grid-margin-x > .xlarge-auto {
    width: auto; }
  .grid-margin-x > .xlarge-shrink {
    width: auto; }
  .grid-margin-x > .xlarge-1 {
    width: calc(8.33333% - 1.875rem); }
  .grid-margin-x > .xlarge-2 {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x > .xlarge-3 {
    width: calc(25% - 1.875rem); }
  .grid-margin-x > .xlarge-4 {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x > .xlarge-5 {
    width: calc(41.66667% - 1.875rem); }
  .grid-margin-x > .xlarge-6 {
    width: calc(50% - 1.875rem); }
  .grid-margin-x > .xlarge-7 {
    width: calc(58.33333% - 1.875rem); }
  .grid-margin-x > .xlarge-8 {
    width: calc(66.66667% - 1.875rem); }
  .grid-margin-x > .xlarge-9 {
    width: calc(75% - 1.875rem); }
  .grid-margin-x > .xlarge-10 {
    width: calc(83.33333% - 1.875rem); }
  .grid-margin-x > .xlarge-11 {
    width: calc(91.66667% - 1.875rem); }
  .grid-margin-x > .xlarge-12 {
    width: calc(100% - 1.875rem); } }

@media screen and (min-width: 123.75em) {
  .grid-margin-x > .xxlarge-auto {
    width: auto; }
  .grid-margin-x > .xxlarge-shrink {
    width: auto; }
  .grid-margin-x > .xxlarge-1 {
    width: calc(8.33333% - 1.875rem); }
  .grid-margin-x > .xxlarge-2 {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x > .xxlarge-3 {
    width: calc(25% - 1.875rem); }
  .grid-margin-x > .xxlarge-4 {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x > .xxlarge-5 {
    width: calc(41.66667% - 1.875rem); }
  .grid-margin-x > .xxlarge-6 {
    width: calc(50% - 1.875rem); }
  .grid-margin-x > .xxlarge-7 {
    width: calc(58.33333% - 1.875rem); }
  .grid-margin-x > .xxlarge-8 {
    width: calc(66.66667% - 1.875rem); }
  .grid-margin-x > .xxlarge-9 {
    width: calc(75% - 1.875rem); }
  .grid-margin-x > .xxlarge-10 {
    width: calc(83.33333% - 1.875rem); }
  .grid-margin-x > .xxlarge-11 {
    width: calc(91.66667% - 1.875rem); }
  .grid-margin-x > .xxlarge-12 {
    width: calc(100% - 1.875rem); } }

@media print, screen and (min-width: 20em) {
  .grid-padding-x .grid-padding-x {
    margin-right: -0.625rem;
    margin-left: -0.625rem; } }

@media print, screen and (min-width: 40em) {
  .grid-padding-x .grid-padding-x {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem; } }

@media print, screen and (min-width: 20em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-right: -0.625rem;
    margin-left: -0.625rem; } }

@media print, screen and (min-width: 40em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem; } }

@media print, screen and (min-width: 20em) {
  .grid-padding-x > .cell {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media print, screen and (min-width: 40em) {
  .grid-padding-x > .cell {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

@media print, screen and (min-width: 20em) {
  .small-up-1 > .cell {
    width: 100%; }
  .small-up-2 > .cell {
    width: 50%; }
  .small-up-3 > .cell {
    width: 33.33333%; }
  .small-up-4 > .cell {
    width: 25%; }
  .small-up-5 > .cell {
    width: 20%; }
  .small-up-6 > .cell {
    width: 16.66667%; }
  .small-up-7 > .cell {
    width: 14.28571%; }
  .small-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 80em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

@media screen and (min-width: 105em) {
  .xlarge-up-1 > .cell {
    width: 100%; }
  .xlarge-up-2 > .cell {
    width: 50%; }
  .xlarge-up-3 > .cell {
    width: 33.33333%; }
  .xlarge-up-4 > .cell {
    width: 25%; }
  .xlarge-up-5 > .cell {
    width: 20%; }
  .xlarge-up-6 > .cell {
    width: 16.66667%; }
  .xlarge-up-7 > .cell {
    width: 14.28571%; }
  .xlarge-up-8 > .cell {
    width: 12.5%; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-up-1 > .cell {
    width: 100%; }
  .xxlarge-up-2 > .cell {
    width: 50%; }
  .xxlarge-up-3 > .cell {
    width: 33.33333%; }
  .xxlarge-up-4 > .cell {
    width: 25%; }
  .xxlarge-up-5 > .cell {
    width: 20%; }
  .xxlarge-up-6 > .cell {
    width: 16.66667%; }
  .xxlarge-up-7 > .cell {
    width: 14.28571%; }
  .xxlarge-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 20em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.25rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media print, screen and (min-width: 80em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media screen and (min-width: 105em) {
  .grid-margin-x.xlarge-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.xlarge-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.xlarge-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.xlarge-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.xlarge-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.xlarge-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.xlarge-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.xlarge-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media screen and (min-width: 123.75em) {
  .grid-margin-x.xxlarge-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.xxlarge-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.xxlarge-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.xxlarge-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.xxlarge-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.xxlarge-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.xxlarge-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.xxlarge-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media print, screen and (min-width: 20em) {
  .small-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .small-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 20em) {
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .small-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .small-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .small-margin-collapse > .medium-3 {
    width: 25%; }
  .small-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .small-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .small-margin-collapse > .medium-6 {
    width: 50%; }
  .small-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .small-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .small-margin-collapse > .medium-9 {
    width: 75%; }
  .small-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .small-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .small-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 80em) {
  .small-margin-collapse > .large-1 {
    width: 8.33333%; }
  .small-margin-collapse > .large-2 {
    width: 16.66667%; }
  .small-margin-collapse > .large-3 {
    width: 25%; }
  .small-margin-collapse > .large-4 {
    width: 33.33333%; }
  .small-margin-collapse > .large-5 {
    width: 41.66667%; }
  .small-margin-collapse > .large-6 {
    width: 50%; }
  .small-margin-collapse > .large-7 {
    width: 58.33333%; }
  .small-margin-collapse > .large-8 {
    width: 66.66667%; }
  .small-margin-collapse > .large-9 {
    width: 75%; }
  .small-margin-collapse > .large-10 {
    width: 83.33333%; }
  .small-margin-collapse > .large-11 {
    width: 91.66667%; }
  .small-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 105em) {
  .small-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .small-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .small-margin-collapse > .xlarge-3 {
    width: 25%; }
  .small-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .small-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .small-margin-collapse > .xlarge-6 {
    width: 50%; }
  .small-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .small-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .small-margin-collapse > .xlarge-9 {
    width: 75%; }
  .small-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .small-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .small-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 123.75em) {
  .small-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .small-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .small-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .small-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .small-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .small-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .small-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .small-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .small-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .small-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .small-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .small-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media print, screen and (min-width: 20em) {
  .small-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .small-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 80em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 105em) {
  .medium-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .xlarge-3 {
    width: 25%; }
  .medium-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .xlarge-6 {
    width: 50%; }
  .medium-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .xlarge-9 {
    width: 75%; }
  .medium-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 123.75em) {
  .medium-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .medium-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .medium-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .medium-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 80em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 80em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 80em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 80em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 105em) {
  .large-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .large-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .large-margin-collapse > .xlarge-3 {
    width: 25%; }
  .large-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .large-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .large-margin-collapse > .xlarge-6 {
    width: 50%; }
  .large-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .large-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .large-margin-collapse > .xlarge-9 {
    width: 75%; }
  .large-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .large-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .large-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 123.75em) {
  .large-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .large-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .large-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .large-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .large-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .large-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .large-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .large-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .large-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .large-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .large-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .large-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media print, screen and (min-width: 80em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 105em) {
  .xlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media screen and (min-width: 105em) {
  .xlarge-margin-collapse > .small-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .small-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .small-3 {
    width: 25%; }
  .xlarge-margin-collapse > .small-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .small-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .small-6 {
    width: 50%; }
  .xlarge-margin-collapse > .small-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .small-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .small-9 {
    width: 75%; }
  .xlarge-margin-collapse > .small-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .small-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .small-12 {
    width: 100%; } }

@media screen and (min-width: 105em) {
  .xlarge-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .medium-3 {
    width: 25%; }
  .xlarge-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .medium-6 {
    width: 50%; }
  .xlarge-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .medium-9 {
    width: 75%; }
  .xlarge-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .medium-12 {
    width: 100%; } }

@media screen and (min-width: 105em) {
  .xlarge-margin-collapse > .large-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .large-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .large-3 {
    width: 25%; }
  .xlarge-margin-collapse > .large-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .large-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .large-6 {
    width: 50%; }
  .xlarge-margin-collapse > .large-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .large-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .large-9 {
    width: 75%; }
  .xlarge-margin-collapse > .large-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .large-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 105em) {
  .xlarge-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .xlarge-3 {
    width: 25%; }
  .xlarge-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .xlarge-6 {
    width: 50%; }
  .xlarge-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .xlarge-9 {
    width: 75%; }
  .xlarge-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 123.75em) {
  .xlarge-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .xlarge-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .xlarge-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .xlarge-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media screen and (min-width: 105em) {
  .xlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-margin-collapse > .small-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .small-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .small-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .small-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .small-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .small-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .small-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .small-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .small-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .small-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .small-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .small-12 {
    width: 100%; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .medium-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .medium-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .medium-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .medium-12 {
    width: 100%; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-margin-collapse > .large-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .large-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .large-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .large-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .large-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .large-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .large-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .large-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .large-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .large-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .large-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .xlarge-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .xlarge-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .xlarge-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 20em) {
  .small-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .small-offset-0 {
    margin-left: calc(0% + 0.625rem); }
  .small-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .small-offset-1 {
    margin-left: calc(8.33333% + 0.625rem); }
  .small-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .small-offset-2 {
    margin-left: calc(16.66667% + 0.625rem); }
  .small-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .small-offset-3 {
    margin-left: calc(25% + 0.625rem); }
  .small-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .small-offset-4 {
    margin-left: calc(33.33333% + 0.625rem); }
  .small-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .small-offset-5 {
    margin-left: calc(41.66667% + 0.625rem); }
  .small-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .small-offset-6 {
    margin-left: calc(50% + 0.625rem); }
  .small-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .small-offset-7 {
    margin-left: calc(58.33333% + 0.625rem); }
  .small-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .small-offset-8 {
    margin-left: calc(66.66667% + 0.625rem); }
  .small-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .small-offset-9 {
    margin-left: calc(75% + 0.625rem); }
  .small-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .small-offset-10 {
    margin-left: calc(83.33333% + 0.625rem); }
  .small-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .small-offset-11 {
    margin-left: calc(91.66667% + 0.625rem); } }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media print, screen and (min-width: 80em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media screen and (min-width: 105em) {
  .xlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xlarge-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xlarge-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xlarge-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .xlarge-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xlarge-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xlarge-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .xlarge-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xlarge-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xlarge-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .xlarge-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xlarge-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xlarge-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media screen and (min-width: 123.75em) {
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xxlarge-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xxlarge-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xxlarge-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .xxlarge-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xxlarge-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xxlarge-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .xxlarge-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xxlarge-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xxlarge-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .xxlarge-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xxlarge-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xxlarge-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  @media print, screen and (min-width: 20em) {
    .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 80em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  @media screen and (min-width: 105em) {
    .grid-y > .xlarge-shrink, .grid-y > .xlarge-full, .grid-y > .xlarge-1, .grid-y > .xlarge-2, .grid-y > .xlarge-3, .grid-y > .xlarge-4, .grid-y > .xlarge-5, .grid-y > .xlarge-6, .grid-y > .xlarge-7, .grid-y > .xlarge-8, .grid-y > .xlarge-9, .grid-y > .xlarge-10, .grid-y > .xlarge-11, .grid-y > .xlarge-12 {
      flex-basis: auto; } }
  @media screen and (min-width: 123.75em) {
    .grid-y > .xxlarge-shrink, .grid-y > .xxlarge-full, .grid-y > .xxlarge-1, .grid-y > .xxlarge-2, .grid-y > .xxlarge-3, .grid-y > .xxlarge-4, .grid-y > .xxlarge-5, .grid-y > .xxlarge-6, .grid-y > .xxlarge-7, .grid-y > .xxlarge-8, .grid-y > .xxlarge-9, .grid-y > .xxlarge-10, .grid-y > .xxlarge-11, .grid-y > .xxlarge-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 20em) {
    .grid-y > .small-1 {
      height: 8.33333%; }
    .grid-y > .small-2 {
      height: 16.66667%; }
    .grid-y > .small-3 {
      height: 25%; }
    .grid-y > .small-4 {
      height: 33.33333%; }
    .grid-y > .small-5 {
      height: 41.66667%; }
    .grid-y > .small-6 {
      height: 50%; }
    .grid-y > .small-7 {
      height: 58.33333%; }
    .grid-y > .small-8 {
      height: 66.66667%; }
    .grid-y > .small-9 {
      height: 75%; }
    .grid-y > .small-10 {
      height: 83.33333%; }
    .grid-y > .small-11 {
      height: 91.66667%; }
    .grid-y > .small-12 {
      height: 100%; } }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 80em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }
  @media screen and (min-width: 105em) {
    .grid-y > .xlarge-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .xlarge-shrink {
      height: auto; }
    .grid-y > .xlarge-1 {
      height: 8.33333%; }
    .grid-y > .xlarge-2 {
      height: 16.66667%; }
    .grid-y > .xlarge-3 {
      height: 25%; }
    .grid-y > .xlarge-4 {
      height: 33.33333%; }
    .grid-y > .xlarge-5 {
      height: 41.66667%; }
    .grid-y > .xlarge-6 {
      height: 50%; }
    .grid-y > .xlarge-7 {
      height: 58.33333%; }
    .grid-y > .xlarge-8 {
      height: 66.66667%; }
    .grid-y > .xlarge-9 {
      height: 75%; }
    .grid-y > .xlarge-10 {
      height: 83.33333%; }
    .grid-y > .xlarge-11 {
      height: 91.66667%; }
    .grid-y > .xlarge-12 {
      height: 100%; } }
  @media screen and (min-width: 123.75em) {
    .grid-y > .xxlarge-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .xxlarge-shrink {
      height: auto; }
    .grid-y > .xxlarge-1 {
      height: 8.33333%; }
    .grid-y > .xxlarge-2 {
      height: 16.66667%; }
    .grid-y > .xxlarge-3 {
      height: 25%; }
    .grid-y > .xxlarge-4 {
      height: 33.33333%; }
    .grid-y > .xxlarge-5 {
      height: 41.66667%; }
    .grid-y > .xxlarge-6 {
      height: 50%; }
    .grid-y > .xxlarge-7 {
      height: 58.33333%; }
    .grid-y > .xxlarge-8 {
      height: 66.66667%; }
    .grid-y > .xxlarge-9 {
      height: 75%; }
    .grid-y > .xxlarge-10 {
      height: 83.33333%; }
    .grid-y > .xxlarge-11 {
      height: 91.66667%; }
    .grid-y > .xxlarge-12 {
      height: 100%; } }

@media print, screen and (min-width: 20em) {
  .grid-padding-y .grid-padding-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem; } }

@media print, screen and (min-width: 40em) {
  .grid-padding-y .grid-padding-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem; } }

@media print, screen and (min-width: 20em) {
  .grid-padding-y > .cell {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; } }

@media print, screen and (min-width: 40em) {
  .grid-padding-y > .cell {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem; } }

@media print, screen and (min-width: 20em) {
  .grid-margin-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem; } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem; } }

.grid-margin-y > .cell {
  height: calc(100% - ); }

@media print, screen and (min-width: 20em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem; } }

@media print, screen and (min-width: 20em) {
  .grid-margin-y > .small-auto {
    height: auto; }
  .grid-margin-y > .small-shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .small-auto {
    height: auto; }
  .grid-margin-y > .small-shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.875rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.875rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.875rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.875rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.875rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.875rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.875rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.875rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem); }
  .grid-margin-y > .medium-auto {
    height: auto; }
  .grid-margin-y > .medium-shrink {
    height: auto; }
  .grid-margin-y > .medium-1 {
    height: calc(8.33333% - 1.875rem); }
  .grid-margin-y > .medium-2 {
    height: calc(16.66667% - 1.875rem); }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem); }
  .grid-margin-y > .medium-4 {
    height: calc(33.33333% - 1.875rem); }
  .grid-margin-y > .medium-5 {
    height: calc(41.66667% - 1.875rem); }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem); }
  .grid-margin-y > .medium-7 {
    height: calc(58.33333% - 1.875rem); }
  .grid-margin-y > .medium-8 {
    height: calc(66.66667% - 1.875rem); }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem); }
  .grid-margin-y > .medium-10 {
    height: calc(83.33333% - 1.875rem); }
  .grid-margin-y > .medium-11 {
    height: calc(91.66667% - 1.875rem); }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem); } }

@media print, screen and (min-width: 80em) {
  .grid-margin-y > .large-auto {
    height: auto; }
  .grid-margin-y > .large-shrink {
    height: auto; }
  .grid-margin-y > .large-1 {
    height: calc(8.33333% - 1.875rem); }
  .grid-margin-y > .large-2 {
    height: calc(16.66667% - 1.875rem); }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem); }
  .grid-margin-y > .large-4 {
    height: calc(33.33333% - 1.875rem); }
  .grid-margin-y > .large-5 {
    height: calc(41.66667% - 1.875rem); }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem); }
  .grid-margin-y > .large-7 {
    height: calc(58.33333% - 1.875rem); }
  .grid-margin-y > .large-8 {
    height: calc(66.66667% - 1.875rem); }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem); }
  .grid-margin-y > .large-10 {
    height: calc(83.33333% - 1.875rem); }
  .grid-margin-y > .large-11 {
    height: calc(91.66667% - 1.875rem); }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem); } }

@media screen and (min-width: 105em) {
  .grid-margin-y > .xlarge-auto {
    height: auto; }
  .grid-margin-y > .xlarge-shrink {
    height: auto; }
  .grid-margin-y > .xlarge-1 {
    height: calc(8.33333% - 1.875rem); }
  .grid-margin-y > .xlarge-2 {
    height: calc(16.66667% - 1.875rem); }
  .grid-margin-y > .xlarge-3 {
    height: calc(25% - 1.875rem); }
  .grid-margin-y > .xlarge-4 {
    height: calc(33.33333% - 1.875rem); }
  .grid-margin-y > .xlarge-5 {
    height: calc(41.66667% - 1.875rem); }
  .grid-margin-y > .xlarge-6 {
    height: calc(50% - 1.875rem); }
  .grid-margin-y > .xlarge-7 {
    height: calc(58.33333% - 1.875rem); }
  .grid-margin-y > .xlarge-8 {
    height: calc(66.66667% - 1.875rem); }
  .grid-margin-y > .xlarge-9 {
    height: calc(75% - 1.875rem); }
  .grid-margin-y > .xlarge-10 {
    height: calc(83.33333% - 1.875rem); }
  .grid-margin-y > .xlarge-11 {
    height: calc(91.66667% - 1.875rem); }
  .grid-margin-y > .xlarge-12 {
    height: calc(100% - 1.875rem); } }

@media screen and (min-width: 123.75em) {
  .grid-margin-y > .xxlarge-auto {
    height: auto; }
  .grid-margin-y > .xxlarge-shrink {
    height: auto; }
  .grid-margin-y > .xxlarge-1 {
    height: calc(8.33333% - 1.875rem); }
  .grid-margin-y > .xxlarge-2 {
    height: calc(16.66667% - 1.875rem); }
  .grid-margin-y > .xxlarge-3 {
    height: calc(25% - 1.875rem); }
  .grid-margin-y > .xxlarge-4 {
    height: calc(33.33333% - 1.875rem); }
  .grid-margin-y > .xxlarge-5 {
    height: calc(41.66667% - 1.875rem); }
  .grid-margin-y > .xxlarge-6 {
    height: calc(50% - 1.875rem); }
  .grid-margin-y > .xxlarge-7 {
    height: calc(58.33333% - 1.875rem); }
  .grid-margin-y > .xxlarge-8 {
    height: calc(66.66667% - 1.875rem); }
  .grid-margin-y > .xxlarge-9 {
    height: calc(75% - 1.875rem); }
  .grid-margin-y > .xxlarge-10 {
    height: calc(83.33333% - 1.875rem); }
  .grid-margin-y > .xxlarge-11 {
    height: calc(91.66667% - 1.875rem); }
  .grid-margin-y > .xxlarge-12 {
    height: calc(100% - 1.875rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 20em) {
  .small-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .small-grid-frame {
    width: 100%; }
  .small-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .small-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .small-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .small-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 80em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media screen and (min-width: 105em) {
  .xlarge-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .xlarge-grid-frame {
    width: 100%; }
  .xlarge-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .xlarge-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .xlarge-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .xlarge-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .xxlarge-grid-frame {
    width: 100%; }
  .xxlarge-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .xxlarge-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .xxlarge-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .xxlarge-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 20em) {
  .grid-y.small-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 80em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media screen and (min-width: 105em) {
  .grid-y.xlarge-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media screen and (min-width: 123.75em) {
  .grid-y.xxlarge-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 20em) {
  .cell .grid-y.small-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 80em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

@media screen and (min-width: 105em) {
  .cell .grid-y.xlarge-grid-frame {
    height: 100%; } }

@media screen and (min-width: 123.75em) {
  .cell .grid-y.xxlarge-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 20em) {
  .grid-margin-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem; } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem; } }

.grid-margin-y > .cell {
  height: calc(100% - ); }

@media print, screen and (min-width: 20em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem; } }

@media print, screen and (min-width: 20em) {
  .grid-margin-y > .small-auto {
    height: auto; }
  .grid-margin-y > .small-shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .small-auto {
    height: auto; }
  .grid-margin-y > .small-shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.875rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.875rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.875rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.875rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.875rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.875rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.875rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.875rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem); }
  .grid-margin-y > .medium-auto {
    height: auto; }
  .grid-margin-y > .medium-shrink {
    height: auto; }
  .grid-margin-y > .medium-1 {
    height: calc(8.33333% - 1.875rem); }
  .grid-margin-y > .medium-2 {
    height: calc(16.66667% - 1.875rem); }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem); }
  .grid-margin-y > .medium-4 {
    height: calc(33.33333% - 1.875rem); }
  .grid-margin-y > .medium-5 {
    height: calc(41.66667% - 1.875rem); }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem); }
  .grid-margin-y > .medium-7 {
    height: calc(58.33333% - 1.875rem); }
  .grid-margin-y > .medium-8 {
    height: calc(66.66667% - 1.875rem); }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem); }
  .grid-margin-y > .medium-10 {
    height: calc(83.33333% - 1.875rem); }
  .grid-margin-y > .medium-11 {
    height: calc(91.66667% - 1.875rem); }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem); } }

@media print, screen and (min-width: 80em) {
  .grid-margin-y > .large-auto {
    height: auto; }
  .grid-margin-y > .large-shrink {
    height: auto; }
  .grid-margin-y > .large-1 {
    height: calc(8.33333% - 1.875rem); }
  .grid-margin-y > .large-2 {
    height: calc(16.66667% - 1.875rem); }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem); }
  .grid-margin-y > .large-4 {
    height: calc(33.33333% - 1.875rem); }
  .grid-margin-y > .large-5 {
    height: calc(41.66667% - 1.875rem); }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem); }
  .grid-margin-y > .large-7 {
    height: calc(58.33333% - 1.875rem); }
  .grid-margin-y > .large-8 {
    height: calc(66.66667% - 1.875rem); }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem); }
  .grid-margin-y > .large-10 {
    height: calc(83.33333% - 1.875rem); }
  .grid-margin-y > .large-11 {
    height: calc(91.66667% - 1.875rem); }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem); } }

@media screen and (min-width: 105em) {
  .grid-margin-y > .xlarge-auto {
    height: auto; }
  .grid-margin-y > .xlarge-shrink {
    height: auto; }
  .grid-margin-y > .xlarge-1 {
    height: calc(8.33333% - 1.875rem); }
  .grid-margin-y > .xlarge-2 {
    height: calc(16.66667% - 1.875rem); }
  .grid-margin-y > .xlarge-3 {
    height: calc(25% - 1.875rem); }
  .grid-margin-y > .xlarge-4 {
    height: calc(33.33333% - 1.875rem); }
  .grid-margin-y > .xlarge-5 {
    height: calc(41.66667% - 1.875rem); }
  .grid-margin-y > .xlarge-6 {
    height: calc(50% - 1.875rem); }
  .grid-margin-y > .xlarge-7 {
    height: calc(58.33333% - 1.875rem); }
  .grid-margin-y > .xlarge-8 {
    height: calc(66.66667% - 1.875rem); }
  .grid-margin-y > .xlarge-9 {
    height: calc(75% - 1.875rem); }
  .grid-margin-y > .xlarge-10 {
    height: calc(83.33333% - 1.875rem); }
  .grid-margin-y > .xlarge-11 {
    height: calc(91.66667% - 1.875rem); }
  .grid-margin-y > .xlarge-12 {
    height: calc(100% - 1.875rem); } }

@media screen and (min-width: 123.75em) {
  .grid-margin-y > .xxlarge-auto {
    height: auto; }
  .grid-margin-y > .xxlarge-shrink {
    height: auto; }
  .grid-margin-y > .xxlarge-1 {
    height: calc(8.33333% - 1.875rem); }
  .grid-margin-y > .xxlarge-2 {
    height: calc(16.66667% - 1.875rem); }
  .grid-margin-y > .xxlarge-3 {
    height: calc(25% - 1.875rem); }
  .grid-margin-y > .xxlarge-4 {
    height: calc(33.33333% - 1.875rem); }
  .grid-margin-y > .xxlarge-5 {
    height: calc(41.66667% - 1.875rem); }
  .grid-margin-y > .xxlarge-6 {
    height: calc(50% - 1.875rem); }
  .grid-margin-y > .xxlarge-7 {
    height: calc(58.33333% - 1.875rem); }
  .grid-margin-y > .xxlarge-8 {
    height: calc(66.66667% - 1.875rem); }
  .grid-margin-y > .xxlarge-9 {
    height: calc(75% - 1.875rem); }
  .grid-margin-y > .xxlarge-10 {
    height: calc(83.33333% - 1.875rem); }
  .grid-margin-y > .xxlarge-11 {
    height: calc(91.66667% - 1.875rem); }
  .grid-margin-y > .xxlarge-12 {
    height: calc(100% - 1.875rem); } }

@media print, screen and (min-width: 20em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.25rem); } }

@media print, screen and (min-width: 40em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 80em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem); } }

@media screen and (min-width: 105em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem); } }

@media screen and (min-width: 123.75em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 20em) {
  .grid-margin-y.small-grid-frame {
    height: calc(100vh + 1.25rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 80em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem); } }

@media screen and (min-width: 105em) {
  .grid-margin-y.xlarge-grid-frame {
    height: calc(100vh + 1.875rem); } }

@media screen and (min-width: 123.75em) {
  .grid-margin-y.xxlarge-grid-frame {
    height: calc(100vh + 1.875rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: Roboto, Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

@media print, screen and (min-width: 20em) {
  h1, .h1 {
    font-size: 1.5rem; }
  h2, .h2 {
    font-size: 1.25rem; }
  h3, .h3 {
    font-size: 1.1875rem; }
  h4, .h4 {
    font-size: 1.125rem; }
  h5, .h5 {
    font-size: 1.0625rem; }
  h6, .h6 {
    font-size: 1rem; } }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #22349a;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1d2d84; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 125rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #1c1f2a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #1c1f2a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #1c1f2a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

@media print, screen and (min-width: 20em) {
  .small-text-left {
    text-align: left; }
  .small-text-right {
    text-align: right; }
  .small-text-center {
    text-align: center; }
  .small-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 80em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 105em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #22349a;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #1d2c83;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #22349a;
    color: #fefefe; }
    .button.primary:hover, .button.primary:focus {
      background-color: #1b2a7b;
      color: #fefefe; }
  .button.secondary {
    background-color: #0065e2;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #0051b5;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #1c1f2a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #1c1f2a; }
  .button.warning {
    background-color: #ffae00;
    color: #1c1f2a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #1c1f2a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #22349a;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #22349a;
        color: #fefefe; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #0065e2;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #1c1f2a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #1c1f2a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.hollow {
    border: 1px solid #22349a;
    color: #22349a; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #111a4d;
      color: #111a4d; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #22349a;
        color: #22349a; }
    .button.hollow.primary {
      border: 1px solid #22349a;
      color: #22349a; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #111a4d;
        color: #111a4d; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #22349a;
          color: #22349a; }
    .button.hollow.secondary {
      border: 1px solid #0065e2;
      color: #0065e2; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #003371;
        color: #003371; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #0065e2;
          color: #0065e2; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #22349a;
    color: #22349a; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #111a4d;
      color: #111a4d; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #22349a;
        color: #22349a; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #22349a;
      color: #22349a; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #111a4d;
        color: #111a4d; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #22349a;
          color: #22349a; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #0065e2;
      color: #0065e2; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #003371;
        color: #003371; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #0065e2;
          color: #0065e2; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #22349a; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #22349a; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #0065e2; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(28, 31, 42, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #1c1f2a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #1c1f2a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #1c1f2a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #1c1f2a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #1c1f2a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

input[type="range"] {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 0.45rem;
  margin-bottom: 0.45rem;
  appearance: none;
  border: 0;
  line-height: 1;
  cursor: pointer; }
  input[type="range"]:focus {
    outline: 0; }
  input[type="range"][disabled] {
    opacity: 0.25; }
  input[type="range"]::-webkit-slider-runnable-track {
    height: 0.5rem;
    background: #e6e6e6; }
  input[type="range"]::-webkit-slider-handle {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    -webkit-appearance: none;
    background: #22349a; }
  input[type="range"]::-moz-range-track {
    height: 0.5rem;
    -moz-appearance: none;
    background: #e6e6e6; }
  input[type="range"]::-moz-range-thumb {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    -moz-appearance: none;
    background: #22349a; }
  input[type="range"]::-ms-track {
    height: 0.5rem;
    border: 0;
    border-top: 0.45rem solid #fefefe;
    border-bottom: 0.45rem solid #fefefe;
    background: #e6e6e6;
    overflow: visible;
    color: transparent; }
  input[type="range"]::-ms-thumb {
    width: 1.4rem;
    height: 1.4rem;
    border: 0;
    background: #22349a; }
  input[type="range"]::-ms-fill-lower {
    background: #cacaca; }
  input[type="range"]::-ms-fill-upper {
    background: #e6e6e6; }
  output {
    vertical-align: middle;
    margin-left: 0.5em;
    line-height: 1.4rem; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #22349a; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #1c1f2a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #22349a transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #22349a transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #22349a;
  color: #fefefe; }
  .badge.primary {
    background: #22349a;
    color: #fefefe; }
  .badge.secondary {
    background: #0065e2;
    color: #fefefe; }
  .badge.success {
    background: #3adb76;
    color: #1c1f2a; }
  .badge.warning {
    background: #ffae00;
    color: #1c1f2a; }
  .badge.alert {
    background: #cc4b37;
    color: #fefefe; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #1c1f2a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #22349a; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #22349a;
    color: #fefefe; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #1b2a7b;
      color: #fefefe; }
  .button-group.secondary .button {
    background-color: #0065e2;
    color: #fefefe; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #0051b5;
      color: #fefefe; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #1c1f2a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #1c1f2a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #1c1f2a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #1c1f2a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #fefefe; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 80em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(28, 31, 42, 0.25);
  border-radius: 0;
  background-color: white;
  color: #1c1f2a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7dcf6;
    color: #1c1f2a; }
  .callout.secondary {
    background-color: #d4e7ff;
    color: #1c1f2a; }
  .callout.success {
    background-color: #e1faea;
    color: #1c1f2a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #1c1f2a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #1c1f2a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #1c1f2a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #1c1f2a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 20em) {
    .menu.small-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.small-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.small-expanded li {
      flex: 1 1 0px; }
    .menu.small-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 80em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  @media screen and (min-width: 105em) {
    .menu.xlarge-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.xlarge-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.xlarge-expanded li {
      flex: 1 1 0px; }
    .menu.xlarge-simple li {
      flex: 1 1 0px; } }
  @media screen and (min-width: 123.75em) {
    .menu.xxlarge-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.xxlarge-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.xxlarge-expanded li {
      flex: 1 1 0px; }
    .menu.xxlarge-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #22349a;
    color: #fefefe; }
  .menu .active > a {
    background: #22349a;
    color: #fefefe; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #1c1f2a;
    box-shadow: 0 7px 0 #1c1f2a, 0 14px 0 #1c1f2a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #22349a; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #22349a; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #22349a transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #22349a transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #22349a transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #22349a; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #22349a transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #22349a; }

@media print, screen and (min-width: 20em) {
  .dropdown.menu.small-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.small-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.small-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.small-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #22349a transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.small-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.small-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.small-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.small-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.small-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #22349a transparent transparent; }
  .dropdown.menu.small-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #22349a; } }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #22349a transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #22349a transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #22349a; } }

@media print, screen and (min-width: 80em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #22349a transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #22349a transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #22349a; } }

@media screen and (min-width: 105em) {
  .dropdown.menu.xlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #22349a transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.xlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xlarge-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #22349a transparent transparent; }
  .dropdown.menu.xlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #22349a; } }

@media screen and (min-width: 123.75em) {
  .dropdown.menu.xxlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xxlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #22349a transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.xxlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xxlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #22349a transparent transparent; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #22349a; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #22349a transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #22349a; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #22349a;
  color: #fefefe; }
  .label.primary {
    background: #22349a;
    color: #fefefe; }
  .label.secondary {
    background: #0065e2;
    color: #fefefe; }
  .label.success {
    background: #3adb76;
    color: #1c1f2a; }
  .label.warning {
    background: #ffae00;
    color: #1c1f2a; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 19.9375em) {
    .media-object.stack-for-x-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 19.9375em) {
    .media-object.stack-for-x-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-x-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(28, 31, 42, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(28, 31, 42, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto; }
  @media print, screen and (min-width: 20em) {
    .position-left {
      width: 250px;
      transform: translateX(-250px); } }
  @media print, screen and (min-width: 20em) {
    .off-canvas-content .off-canvas.position-left {
      transform: translateX(-250px); } }
  .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
    transform: translate(0, 0); }
  @media print, screen and (min-width: 20em) {
    .off-canvas-content.is-open-left.has-transition-push {
      transform: translateX(250px); } }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(28, 31, 42, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto; }
  @media print, screen and (min-width: 20em) {
    .position-right {
      width: 250px;
      transform: translateX(250px); } }
  @media print, screen and (min-width: 20em) {
    .off-canvas-content .off-canvas.position-right {
      transform: translateX(250px); } }
  .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
    transform: translate(0, 0); }
  @media print, screen and (min-width: 20em) {
    .off-canvas-content.is-open-right.has-transition-push {
      transform: translateX(-250px); } }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(28, 31, 42, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto; }
  @media print, screen and (min-width: 20em) {
    .position-top {
      height: 250px;
      transform: translateY(-250px); } }
  @media print, screen and (min-width: 20em) {
    .off-canvas-content .off-canvas.position-top {
      transform: translateY(-250px); } }
  .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
    transform: translate(0, 0); }
  @media print, screen and (min-width: 20em) {
    .off-canvas-content.is-open-top.has-transition-push {
      transform: translateY(250px); } }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(28, 31, 42, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto; }
  @media print, screen and (min-width: 20em) {
    .position-bottom {
      height: 250px;
      transform: translateY(250px); } }
  @media print, screen and (min-width: 20em) {
    .off-canvas-content .off-canvas.position-bottom {
      transform: translateY(250px); } }
  .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
    transform: translate(0, 0); }
  @media print, screen and (min-width: 20em) {
    .off-canvas-content.is-open-bottom.has-transition-push {
      transform: translateY(-250px); } }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(28, 31, 42, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 20em) {
  .position-left.reveal-for-small {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-small .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-small {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-small ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-small {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-small .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-small {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-small ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-small {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-small .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-small {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-small ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-small {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-small .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-small {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-small ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 80em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media screen and (min-width: 105em) {
  .position-left.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-xlarge ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-xlarge ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-xlarge ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-xlarge ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media screen and (min-width: 123.75em) {
  .position-left.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-xxlarge ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-xxlarge ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-xxlarge ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-xxlarge ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 20em) {
  .off-canvas.in-canvas-for-small {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-small.position-left, .off-canvas.in-canvas-for-small.position-right, .off-canvas.in-canvas-for-small.position-top, .off-canvas.in-canvas-for-small.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-small .close-button {
      display: none; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 80em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

@media screen and (min-width: 105em) {
  .off-canvas.in-canvas-for-xlarge {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-xlarge.position-left, .off-canvas.in-canvas-for-xlarge.position-right, .off-canvas.in-canvas-for-xlarge.position-top, .off-canvas.in-canvas-for-xlarge.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-xlarge .close-button {
      display: none; } }

@media screen and (min-width: 123.75em) {
  .off-canvas.in-canvas-for-xxlarge {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-xxlarge.position-left, .off-canvas.in-canvas-for-xxlarge.position-right, .off-canvas.in-canvas-for-xxlarge.position-top, .off-canvas.in-canvas-for-xxlarge.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-xxlarge .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(28, 31, 42, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(28, 31, 42, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #1c1f2a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #22349a;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #1c1f2a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #22349a; }
  .progress.secondary .progress-meter {
    background-color: #0065e2; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #22349a; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #22349a;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #1d2c83; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(28, 31, 42, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 125rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 125rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 125rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 125rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 19.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #22349a; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  thead {
    background: #f8f8f8;
    color: #1c1f2a; }
  tfoot {
    background: #f1f1f1;
    color: #1c1f2a; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 79.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #22349a; }
  .tabs.primary > li > a {
    color: #fefefe; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #2538a7; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #22349a; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #1d2d84; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #22349a; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #1c1f2a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(28, 31, 42, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(34, 52, 154, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #1c1f2a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #1c1f2a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #1c1f2a;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1c1f2a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1c1f2a;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1c1f2a transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 39.9375em) {
    .top-bar.stacked-for-small {
      flex-wrap: wrap; }
      .top-bar.stacked-for-small .top-bar-left,
      .top-bar.stacked-for-small .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 79.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 104.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 123.6875em) {
    .top-bar.stacked-for-xlarge {
      flex-wrap: wrap; }
      .top-bar.stacked-for-xlarge .top-bar-left,
      .top-bar.stacked-for-xlarge .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  .top-bar.stacked-for-xxlarge {
    flex-wrap: wrap; }
    .top-bar.stacked-for-xxlarge .top-bar-left,
    .top-bar.stacked-for-xxlarge .top-bar-right {
      flex: 0 0 100%;
      max-width: 100%; }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after {
  clear: both; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

@media print, screen and (min-width: 20em) {
  .small-order-1 {
    order: 1; }
  .small-order-2 {
    order: 2; }
  .small-order-3 {
    order: 3; }
  .small-order-4 {
    order: 4; }
  .small-order-5 {
    order: 5; }
  .small-order-6 {
    order: 6; } }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 80em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

@media screen and (min-width: 105em) {
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-order-1 {
    order: 1; }
  .xxlarge-order-2 {
    order: 2; }
  .xxlarge-order-3 {
    order: 3; }
  .xxlarge-order-4 {
    order: 4; }
  .xxlarge-order-5 {
    order: 5; }
  .xxlarge-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 20em) {
  .small-flex-container {
    display: flex; }
  .small-flex-child-auto {
    flex: 1 1 auto; }
  .small-flex-child-grow {
    flex: 1 0 auto; }
  .small-flex-child-shrink {
    flex: 0 1 auto; }
  .small-flex-dir-row {
    flex-direction: row; }
  .small-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .small-flex-dir-column {
    flex-direction: column; }
  .small-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 80em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 105em) {
  .xlarge-flex-container {
    display: flex; }
  .xlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xlarge-flex-dir-row {
    flex-direction: row; }
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xlarge-flex-dir-column {
    flex-direction: column; }
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 123.75em) {
  .xxlarge-flex-container {
    display: flex; }
  .xxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xxlarge-flex-dir-row {
    flex-direction: row; }
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xxlarge-flex-dir-column {
    flex-direction: column; }
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.text-hide {
  font: 0/0 a !important;
  color: transparent !important;
  text-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important; }

.text-truncate {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-wrap {
  word-wrap: break-word !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-overline {
  text-decoration: overline !important; }

.text-underline {
  text-decoration: underline !important; }

.text-line-through {
  text-decoration: line-through !important; }

.font-wide {
  letter-spacing: 0.25rem; }

.font-normal {
  font-weight: normal; }

.font-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic !important; }

ul.list-disc {
  list-style-type: disc !important; }

ul.list-circle {
  list-style-type: circle !important; }

ul.list-square {
  list-style-type: square !important; }

ol.list-decimal {
  list-style-type: decimal !important; }

ol.list-lower-alpha {
  list-style-type: lower-alpha !important; }

ol.list-lower-latin {
  list-style-type: lower-latin !important; }

ol.list-lower-roman {
  list-style-type: lower-roman !important; }

ol.list-upper-alpha {
  list-style-type: upper-alpha !important; }

ol.list-upper-latin {
  list-style-type: upper-latin !important; }

ol.list-upper-roman {
  list-style-type: upper-roman !important; }

.rounded {
  border-radius: 5000px !important; }

.radius {
  border-radius: 0.1875rem; }

.bordered {
  border: 0.0625rem solid #cacaca; }

.shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

.arrow-down {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1c1f2a transparent transparent; }

.arrow-up {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #1c1f2a; }

.arrow-right {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1c1f2a; }

.arrow-left {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1c1f2a transparent transparent; }

.separator-center {
  text-align: center !important; }
  .separator-center::before, .separator-center::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-center::after {
    clear: both; }
  .separator-center::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #22349a;
    margin: 1rem auto 0; }

.separator-left {
  text-align: left !important; }
  .separator-left::before, .separator-left::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-left::after {
    clear: both; }
  .separator-left::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #22349a;
    margin: 1rem auto 0;
    margin-left: 0 !important; }

.separator-right {
  text-align: right !important; }
  .separator-right::before, .separator-right::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-right::after {
    clear: both; }
  .separator-right::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #22349a;
    margin: 1rem auto 0;
    margin-right: 0 !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.display-inline {
  display: inline !important; }

.display-inline-block {
  display: inline-block !important; }

.display-block {
  display: block !important; }

.display-table {
  display: table !important; }

.display-table-cell {
  display: table-cell !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-fixed-top {
  position: fixed !important;
  top: 0rem !important;
  right: 0rem !important;
  left: 0rem !important;
  z-index: 975; }

.position-fixed-bottom {
  position: fixed !important;
  right: 0rem !important;
  bottom: 0rem !important;
  left: 0rem !important;
  z-index: 975; }

.border-box {
  box-sizing: border-box !important; }

.border-none {
  border: none !important; }

.width-25 {
  width: 25% !important; }

.width-50 {
  width: 50% !important; }

.width-75 {
  width: 75% !important; }

.width-100 {
  width: 100% !important; }

.height-25 {
  height: 25% !important; }

.height-50 {
  height: 50% !important; }

.height-75 {
  height: 75% !important; }

.height-100 {
  height: 100% !important; }

.max-width-100 {
  max-width: 100% !important; }

.max-height-100 {
  max-height: 100% !important; }

.margin-0 {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important; }

.padding-0 {
  padding-top: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important; }

.margin-top-0 {
  margin-top: 0rem !important; }

.padding-top-0 {
  padding-top: 0rem !important; }

.margin-right-0 {
  margin-right: 0rem !important; }

.padding-right-0 {
  padding-right: 0rem !important; }

.margin-bottom-0 {
  margin-bottom: 0rem !important; }

.padding-bottom-0 {
  padding-bottom: 0rem !important; }

.margin-left-0 {
  margin-left: 0rem !important; }

.padding-left-0 {
  padding-left: 0rem !important; }

.margin-horizontal-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important; }

.padding-horizontal-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important; }

.margin-vertical-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.padding-vertical-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important; }

.margin-1 {
  margin-top: 1rem !important;
  margin-right: 1rem !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important; }

.padding-1 {
  padding-top: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1rem !important; }

.margin-top-1 {
  margin-top: 1rem !important; }

.padding-top-1 {
  padding-top: 1rem !important; }

.margin-right-1 {
  margin-right: 1rem !important; }

.padding-right-1 {
  padding-right: 1rem !important; }

.margin-bottom-1 {
  margin-bottom: 1rem !important; }

.padding-bottom-1 {
  padding-bottom: 1rem !important; }

.margin-left-1 {
  margin-left: 1rem !important; }

.padding-left-1 {
  padding-left: 1rem !important; }

.margin-horizontal-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.padding-horizontal-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.margin-vertical-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.padding-vertical-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.margin-2 {
  margin-top: 2rem !important;
  margin-right: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 2rem !important; }

.padding-2 {
  padding-top: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
  padding-left: 2rem !important; }

.margin-top-2 {
  margin-top: 2rem !important; }

.padding-top-2 {
  padding-top: 2rem !important; }

.margin-right-2 {
  margin-right: 2rem !important; }

.padding-right-2 {
  padding-right: 2rem !important; }

.margin-bottom-2 {
  margin-bottom: 2rem !important; }

.padding-bottom-2 {
  padding-bottom: 2rem !important; }

.margin-left-2 {
  margin-left: 2rem !important; }

.padding-left-2 {
  padding-left: 2rem !important; }

.margin-horizontal-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.padding-horizontal-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.margin-vertical-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.padding-vertical-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.margin-3 {
  margin-top: 3rem !important;
  margin-right: 3rem !important;
  margin-bottom: 3rem !important;
  margin-left: 3rem !important; }

.padding-3 {
  padding-top: 3rem !important;
  padding-right: 3rem !important;
  padding-bottom: 3rem !important;
  padding-left: 3rem !important; }

.margin-top-3 {
  margin-top: 3rem !important; }

.padding-top-3 {
  padding-top: 3rem !important; }

.margin-right-3 {
  margin-right: 3rem !important; }

.padding-right-3 {
  padding-right: 3rem !important; }

.margin-bottom-3 {
  margin-bottom: 3rem !important; }

.padding-bottom-3 {
  padding-bottom: 3rem !important; }

.margin-left-3 {
  margin-left: 3rem !important; }

.padding-left-3 {
  padding-left: 3rem !important; }

.margin-horizontal-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.padding-horizontal-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.margin-vertical-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.padding-vertical-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

body {
  background-color: #638dc0;
  overflow-x: hidden; }

@font-face {
  font-family: 'roma-regular';
  src: url("/assets/fonts/vag/roma-regular.ttf"); }

@font-face {
  font-family: 'roma-bold';
  src: url("/assets/fonts/vag/roma-regular.ttf"); }

.clear {
  clear: both; }

h1 {
  color: #22349a;
  font-family: 'roma-bold';
  font-size: 20px;
  text-align: center;
  line-height: 27px;
  text-transform: uppercase; }
  h1 span {
    font-size: 22px;
    text-transform: uppercase; }

h2 {
  color: #22349a;
  font-family: 'roma-bold', sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 15px; }

p {
  color: #22349a;
  font-family: 'roma-regular';
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 0; }

.btn-zuzu {
  width: 200px;
  height: 30px;
  text-align: center;
  border-radius: 10px;
  padding-top: 4px;
  font-family: 'roma-regular';
  text-transform: uppercase;
  border: 1px solid #22349a;
  margin: 10px auto;
  color: #22349a;
  cursor: pointer; }
  .btn-zuzu a {
    color: #22349a; }

.c-cookieNotice {
  position: fixed;
  bottom: 20px;
  width: 70%;
  height: 230px;
  background-color: rgba(34, 52, 154, 0.87);
  z-index: 9;
  margin-left: calc(50% - 35%);
  border-radius: 15px; }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    .c-cookieNotice {
      height: 40%;
      width: 96%;
      margin-left: calc(50% - 48%); } }
  .c-cookieNotice p {
    position: relative;
    width: 65%;
    margin: auto;
    margin-top: 30px;
    height: 100px;
    color: white; }
    @media screen and (min-width: 20em) and (max-width: 39.9375em) {
      .c-cookieNotice p {
        margin-top: 50px;
        width: 90%; } }
  .c-cookieNotice a {
    color: white;
    text-decoration: underline; }
  .c-cookieNotice #accept-cookies {
    width: 50px;
    background-color: white;
    border-radius: 3px;
    padding: 10px;
    color: #22349a;
    margin-left: calc(50% - 25px);
    cursor: pointer;
    transition: .5s ease-in-out;
    font-weight: 600; }
    .c-cookieNotice #accept-cookies:hover {
      width: 70px;
      margin-left: calc(50% - 35px);
      transition: .3s ease-in-out; }
    @media screen and (min-width: 20em) and (max-width: 39.9375em) {
      .c-cookieNotice #accept-cookies {
        margin-top: 30px; } }

.white-fade {
  width: 100vh;
  height: 100vh;
  background-color: pink; }

.section1 {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; }
  .section1 .nor-fata-1 {
    position: absolute;
    width: 650px;
    bottom: -60px;
    left: -20px;
    z-index: 5;
    transition: 4s; }
  .section1 .nor-fata-1-animate {
    width: 750px;
    left: -350px;
    opacity: 0; }
  .section1 .nor-fata-2 {
    position: absolute;
    width: 750px;
    bottom: -30px;
    right: -100px;
    z-index: 6;
    transition: 4s; }
  .section1 .nor-fata-2-animate {
    width: 850px;
    opacity: 0;
    right: -200px; }
  .section1 .nor-fata-3 {
    width: 550px;
    margin-left: calc(50% - 230px);
    bottom: 0;
    position: absolute;
    transition: 5s; }
  .section1 .nor-fata-3-animate {
    width: 650px;
    margin-left: calc(50% - 330px);
    opacity: 0; }
  .section1 .gard-stanga {
    position: absolute;
    width: 900px;
    left: -230px;
    bottom: -10px;
    transition: 5s;
    z-index: 3; }
  .section1 .gard-stanga-animate {
    left: -750px; }
  .section1 .gard-dreapta {
    position: absolute;
    width: 900px;
    right: -250px;
    bottom: -60px;
    z-index: 4;
    transition: 5s; }
  .section1 .gard-dreapta-animate {
    right: -650px; }
  .section1 .poarta-stanga {
    width: 330px;
    position: absolute;
    left: 300px;
    bottom: 0px;
    transition: 5s;
    z-index: 2; }
  .section1 .poarta-stanga-animate {
    left: -300px; }
  .section1 .poarta-dreapta {
    width: 360px;
    position: absolute;
    right: 240px;
    bottom: -25px;
    transition: 5s;
    display: block; }
  .section1 .poarta-dreapta-animate {
    right: -320px; }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    .section1 {
      display: none; } }

.container-1280 {
  display: none; }
  @media screen and (min-width: 48.75em) {
    .container-1280 {
      display: block;
      position: relative;
      max-width: 1280px;
      height: auto;
      margin: auto;
      z-index: 1; }
      .container-1280 .pop-up {
        position: fixed;
        width: 590px;
        height: 600px;
        left: calc(50% - 295px);
        top: calc(50% - 250px);
        z-index: 6;
        display: none; } }
    @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
      .container-1280 .pop-up {
        position: fixed;
        display: block;
        background-color: #f7f7f7;
        width: 100%;
        height: 100%;
        left: calc(50% - 50%);
        top: 0;
        z-index: 11;
        display: none; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .pop-up .pergament {
          position: fixed;
          width: 590px;
          height: 580px;
          z-index: -1; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .pop-up .pergament {
          display: none; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .pop-up .content-zeus, .container-1280 .pop-up .content-hera {
          position: relative;
          padding-top: 30px; }
        .container-1280 .pop-up .content {
          position: relative;
          margin: 50px auto;
          width: 440px;
          height: 500px; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .pop-up .content {
          position: absolute;
          width: 90%;
          left: calc(50% - 45%);
          top: 0px;
          height: 90%;
          margin-top: 80px; } }
  @media screen and (min-width: 48.75em) {
          .container-1280 .pop-up .content .title-info {
            padding-top: 0px;
            font-size: 20px; }
          .container-1280 .pop-up .content .popup-title1 {
            font-size: 16px;
            letter-spacing: 2px;
            text-transform: uppercase; }
          .container-1280 .pop-up .content .close-icon {
            position: absolute;
            width: 30px;
            height: 30px;
            top: -10px;
            right: -10px;
            z-index: 4;
            cursor: pointer; }
            .container-1280 .pop-up .content .close-icon .xbtn {
              position: absolute;
              width: 25px;
              height: 2px;
              margin-left: 2px;
              margin-top: calc(50% - 1px);
              top: 0;
              background-color: #22349a;
              transform: rotate(45deg); }
              .container-1280 .pop-up .content .close-icon .xbtn::before {
                content: '';
                width: 25px;
                height: 2px;
                background-color: #22349a;
                position: absolute;
                transform: rotate(-90deg); } }
        @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
          .container-1280 .pop-up .content .close-icon {
            top: -50px;
            right: 0px; }
            .container-1280 .pop-up .content .close-icon .xbtn {
              width: 35px; }
              .container-1280 .pop-up .content .close-icon .xbtn::before {
                width: 35px; } }
  @media screen and (min-width: 48.75em) {
          .container-1280 .pop-up .content .lauri {
            position: relative;
            width: 150px;
            margin-left: calc(50% - 75px);
            margin-bottom: 15px;
            margin-top: 5px; }
          .container-1280 .pop-up .content p span {
            font-weight: 600; }
          .container-1280 .pop-up .content .play-icon {
            position: relative;
            margin-bottom: 15px;
            margin-top: 15px;
            margin-left: calc(50% - 20px);
            width: 40px;
            cursor: pointer; }
          .container-1280 .pop-up .content audio {
            width: 300px;
            margin-left: calc(50% - 150px); }
          .container-1280 .pop-up .content .lauri-info {
            position: relative;
            width: 150px;
            margin-left: calc(50% - 75px);
            margin-bottom: 8px;
            margin-top: 3px; }
          .container-1280 .pop-up .content .info {
            position: relative;
            width: 50%;
            float: left;
            text-align: center; }
            .container-1280 .pop-up .content .info p {
              font-weight: 600; }
            .container-1280 .pop-up .content .info ul {
              list-style: none;
              margin-left: 0; }
              .container-1280 .pop-up .content .info ul li {
                color: #22349a;
                font-family: 'roma-regular';
                line-height: 19px; }
        .container-1280 .pop-up .spoon {
          position: absolute;
          bottom: 0;
          width: 300px;
          left: 300px;
          bottom: -10px;
          z-index: 3; }
      .container-1280 .section2 {
        width: 90%;
        margin: auto;
        height: 768px;
        position: relative;
        z-index: 1;
        background: #618cc2; } }
    @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
      .container-1280 .section2 {
        display: none; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section2 .raze-sus {
          position: absolute;
          width: 1000px;
          margin-left: calc(50% - 500px);
          top: 30px; }
        .container-1280 .section2 .raze-jos {
          position: absolute;
          width: 1000px;
          margin-left: calc(50% - 500px);
          bottom: -400px; }
        .container-1280 .section2 .nor-top-capsuni {
          position: absolute;
          bottom: -40px;
          z-index: 4;
          left: -70px; }
        .container-1280 .section2 .nor-top-1-2 {
          position: absolute;
          right: -60px;
          bottom: -40px;
          z-index: 4; }
        .container-1280 .section2 .nor-top-caise {
          position: absolute;
          bottom: -60px;
          width: 330px;
          margin-left: calc(50% - 165px);
          z-index: 5; }
        .container-1280 .section2 .products {
          position: absolute;
          width: 70%;
          height: 400px;
          bottom: 80px;
          margin-left: calc(50% - 35%);
          z-index: 5; }
          .container-1280 .section2 .products .iaurt-simplu-zeus {
            position: absolute;
            left: calc(50% - 260px);
            top: 0;
            transition: .3s ease-in-out;
            cursor: pointer;
            z-index: 5;
            width: 250px; }
            .container-1280 .section2 .products .iaurt-simplu-zeus:hover {
              top: -10px;
              transition: .3s ease-in-out; }
          .container-1280 .section2 .products .nor-top-1-10 {
            position: absolute;
            z-index: 5 !important;
            top: 135px;
            left: 70px;
            width: 300px;
            left: calc(50% - 310px);
            z-index: 4; }
          .container-1280 .section2 .products .umbra-zuzu {
            position: absolute;
            left: calc(50% - 270px);
            top: 150px;
            width: 240px;
            transition: .3s ease-in-out;
            cursor: pointer;
            z-index: -1; }
          .container-1280 .section2 .products .iaurt-simplu-zeus:hover + .umbra-zuzu {
            width: 200px;
            left: calc(50% - 240px); }
          .container-1280 .section2 .products .iaurt-simplu-hera {
            position: absolute;
            right: calc(50% - 260px);
            top: 0;
            transition: .3s ease-in-out;
            cursor: pointer;
            width: 250px; }
            .container-1280 .section2 .products .iaurt-simplu-hera:hover {
              top: -10px;
              transition: .3s ease-in-out; }
          .container-1280 .section2 .products .umbra-hera {
            position: absolute;
            right: calc(50% - 260px);
            top: 150px;
            width: 240px;
            transition: .3s ease-in-out;
            cursor: pointer;
            z-index: -1; }
          .container-1280 .section2 .products .iaurt-simplu-hera:hover + .umbra-hera {
            width: 200px;
            right: calc(50% - 250px); }
          .container-1280 .section2 .products .nor-top-1-2 {
            position: absolute;
            top: 135px;
            right: calc(50% - 310px); }
          .container-1280 .section2 .products .iaurt-capsuna {
            position: absolute;
            left: 0;
            bottom: 30px;
            transition: .3s ease-in-out;
            z-index: 7 !important;
            width: 250px; }
            .container-1280 .section2 .products .iaurt-capsuna:hover {
              bottom: 40px;
              transition: .3s ease-in-out; }
          .container-1280 .section2 .products .umbra-capsuna {
            position: absolute;
            left: 0;
            bottom: 15px;
            width: 230px;
            transition: .3s ease-in-out; }
          .container-1280 .section2 .products .iaurt-capsuna:hover + .umbra-capsuna {
            width: 180px;
            left: 30px; }
          .container-1280 .section2 .products .nor-top-capsuna {
            position: absolute;
            bottom: -35px;
            left: calc(50% - 450px);
            z-index: 8 !important; }
          .container-1280 .section2 .products .iaurt-caise {
            position: absolute;
            left: calc(50% - 115px);
            bottom: 0;
            transition: .3s ease-in-out;
            z-index: 5;
            width: 250px; }
            .container-1280 .section2 .products .iaurt-caise:hover {
              bottom: 10px;
              transition: .3s ease-in-out; }
          .container-1280 .section2 .products .umbra-caise {
            position: absolute;
            left: calc(50% - 90px);
            width: 210px;
            bottom: -10px;
            transition: .3s ease-in-out;
            z-index: -1; }
          .container-1280 .section2 .products .nor-top-caise {
            position: absolute; }
          .container-1280 .section2 .products .iaurt-caise:hover + .umbra-caise {
            width: 180px;
            left: calc(50% - 85px);
            z-index: -1; }
          .container-1280 .section2 .products .iaurt-cirese {
            position: absolute;
            right: 0;
            bottom: 30px;
            transition: .3s ease-in-out;
            z-index: 6 !important;
            width: 250px; }
            .container-1280 .section2 .products .iaurt-cirese:hover {
              bottom: 40px;
              transition: .3s ease-in-out; }
          .container-1280 .section2 .products .umbra-cirese {
            position: absolute;
            width: 230px;
            right: 0;
            bottom: 20px;
            transition: .3s ease-in-out;
            z-index: -1; }
          .container-1280 .section2 .products .iaurt-cirese:hover + .umbra-cirese {
            width: 180px;
            right: 20px; }
          .container-1280 .section2 .products .nor-top-cirese {
            position: absolute;
            bottom: -35px;
            right: calc(50% - 450px);
            z-index: 6 !important; }
        .container-1280 .section2 .back-alb {
          width: 950px;
          height: 450px;
          position: absolute;
          margin-left: calc(50% - 425px);
          margin-top: calc(50% - 305px);
          z-index: 1; }
        .container-1280 .section2 .col-stanga1 {
          position: absolute;
          width: 80px;
          top: 130px;
          left: calc(50% - 200px); }
        .container-1280 .section2 .col-stanga2 {
          position: absolute;
          width: 90px;
          top: 150px;
          left: calc(50% - 420px); }
        .container-1280 .section2 .col-dreapta1 {
          position: absolute;
          width: 80px;
          top: 130px;
          right: calc(50% - 200px); }
        .container-1280 .section2 .col-dreapta2 {
          width: 90px;
          position: absolute;
          top: 150px;
          right: calc(50% - 450px); }
        .container-1280 .section2 .zeu-zeus {
          position: absolute;
          width: 740px;
          left: -310px;
          top: 140px;
          z-index: 3; }
        .container-1280 .section2 .zeu-hera {
          position: absolute;
          width: 550px;
          right: -230px;
          top: 140px;
          z-index: 2; }
        .container-1280 .section2 .zeu-capsuna {
          position: absolute;
          width: 500px;
          left: -210px;
          bottom: 0;
          z-index: 3; }
        .container-1280 .section2 .zeu-caise {
          position: absolute;
          width: 620px;
          left: calc(50% - 415px);
          bottom: -180px;
          z-index: 4; }
        .container-1280 .section2 .zeu-cirese {
          position: absolute;
          width: 430px;
          right: -170px;
          bottom: 0;
          z-index: 2; }
        .container-1280 .section2 .nor1-s2 {
          position: absolute;
          width: 900px;
          left: -300px;
          bottom: -200px;
          z-index: 1; }
        .container-1280 .section2 .nor2-s2 {
          position: absolute;
          width: 900px;
          right: -300px;
          bottom: -150px;
          z-index: 1; }
        .container-1280 .section2 .nor3-s2 {
          position: absolute;
          width: 600px;
          left: -150px;
          top: 380px;
          z-index: 1; }
        .container-1280 .section2 .nor-new-left {
          position: absolute;
          width: 600px;
          left: -150px;
          top: 180px;
          z-index: 1; }
        .container-1280 .section2 .nor4-s2 {
          position: absolute;
          width: 800px;
          top: 230px;
          right: -250px;
          z-index: 1; }
        .container-1280 .section2 .nor-caise-s2 {
          position: absolute;
          width: 800px;
          margin-left: calc(50% - 400px);
          bottom: -200px; }
      .container-1280 .section3 {
        width: 100%;
        height: 768px;
        position: relative;
        background: #618cc2; }
        .container-1280 .section3:hover .zeus-s3 {
          transition: 1s ease-out;
          left: -30px; }
        .container-1280 .section3:hover .hera-s3 {
          right: -190px;
          transition: .5s ease-out; }
        .container-1280 .section3 .iaurt-s3-zeus {
          position: absolute;
          bottom: 50px;
          width: 231px;
          left: calc(50% - 280px);
          z-index: 4;
          transition: .3s ease-in-out;
          cursor: pointer; }
          .container-1280 .section3 .iaurt-s3-zeus:hover {
            bottom: 60px;
            transition: .3s ease-in-out; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section3 .iaurt-s3-zeus {
          left: calc(50% - 100px);
          bottom: 150px;
          width: 200px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section3 .umbra-zuzu3 {
          position: absolute;
          bottom: 40px;
          width: 231px;
          left: calc(50% - 280px);
          z-index: 3;
          transition: .3s ease-in-out; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section3 .umbra-zuzu3 {
          left: calc(50% - 120px);
          bottom: 138px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section3 .iaurt-s3-zeus:hover + .umbra-zuzu3 {
          bottom: 40px;
          left: calc(50% - 250px);
          width: 190px; }
        .container-1280 .section3 .iaurt-s3-hera {
          position: absolute;
          bottom: 50px;
          width: 231px;
          right: calc(50% - 280px);
          transition: .3s ease-in-out;
          cursor: pointer;
          z-index: 4; }
          .container-1280 .section3 .iaurt-s3-hera:hover {
            bottom: 60px;
            transition: .3s ease-in-out; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section3 .iaurt-s3-hera {
          width: 200px;
          left: calc(50% - 100px);
          bottom: -250px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section3 .nor-grifon-1 {
          position: absolute;
          right: -100px;
          bottom: -50px;
          width: 800px;
          z-index: 2; }
        .container-1280 .section3 .umbra-hera3 {
          position: absolute;
          bottom: 39px;
          width: 225px;
          right: calc(50% - 280px);
          z-index: 3;
          transition: .3s ease-in-out;
          cursor: pointer; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section3 .umbra-hera3 {
          width: 200px;
          left: calc(50% - 100px);
          bottom: -260px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section3 .iaurt-s3-hera:hover + .umbra-hera3 {
          bottom: 40px;
          right: calc(50% - 260px);
          width: 190px; }
        .container-1280 .section3 .zeus-s3 {
          position: absolute;
          width: 370px;
          bottom: calc(50% - 27%);
          left: 20px;
          z-index: 2;
          transition: 1s ease-out;
          z-index: 3; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section3 .zeus-s3 {
          width: 800px;
          max-width: 130%;
          left: -180px;
          bottom: calc(50% - 25%); } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section3 .nor-uliu-1 {
          position: absolute;
          width: 700px;
          bottom: 110px;
          left: -50px;
          z-index: 2; }
        .container-1280 .section3 .hera-s3 {
          position: absolute;
          width: 400px;
          bottom: calc(50% - 45%);
          right: -130px;
          z-index: 2;
          transition: 1s ease-out; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section3 .hera-s3 {
          width: 380px;
          right: -120px;
          bottom: -250px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section3 .nor-s3 {
          position: absolute;
          bottom: -120px;
          width: 850px;
          left: calc(50% - 425px);
          z-index: 2; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section3 .nor-s3 {
          display: none; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section3 .nor-s3-1 {
          position: absolute;
          bottom: -120px;
          width: 300px;
          left: calc(50% - 310px);
          bottom: -30px;
          z-index: 4; }
        .container-1280 .section3 .nor-s3-2 {
          position: absolute;
          bottom: -120px;
          width: 300px;
          right: calc(50% - 310px);
          bottom: -30px;
          z-index: 4; }
        .container-1280 .section3 .creature {
          position: absolute;
          width: 350px;
          right: -200px;
          bottom: -100px;
          z-index: 2; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section3 .creature {
          display: none; } }
  @media screen and (min-width: 48.75em) {
      .container-1280 .section4 {
        width: 100%;
        height: 768px;
        position: relative;
        background: #618cc2; }
        .container-1280 .section4:hover .zeu-caise-s4 {
          right: calc(50% - 770px);
          transition: 1s ease-out; }
        .container-1280 .section4:hover .zeu-cirese-s4 {
          left: calc(50% - 660px);
          transition: 1s ease-out; }
        .container-1280 .section4:hover .nor-harp {
          transition: 2s ease-out;
          left: -350px; }
        .container-1280 .section4 .stropi-1 {
          position: absolute;
          width: 320px;
          left: calc(50% - 20px);
          z-index: 3; }
        .container-1280 .section4 .cirese-s4 {
          position: absolute;
          width: 231px;
          z-index: 4;
          bottom: 90px;
          left: calc(50% - 340px);
          transition: .3s ease-in-out;
          cursor: pointer;
          z-index: 5 !important; }
          .container-1280 .section4 .cirese-s4:hover {
            bottom: 100px;
            transition: .3s ease-in-out; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section4 .cirese-s4 {
          width: 200px;
          left: calc(50% - 100px);
          bottom: -250px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section4 .umbra-cirese {
          position: absolute;
          width: 231px;
          z-index: 3;
          bottom: 80px;
          left: calc(50% - 340px);
          transition: .3s ease-in-out;
          cursor: pointer; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section4 .umbra-cirese {
          width: 200px;
          left: calc(50% - 100px);
          bottom: -260px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section4 .cirese-s4:hover + .umbra-cirese {
          bottom: 80px;
          left: calc(50% - 310px);
          width: 180px; }
        .container-1280 .section4 .caise-s4 {
          position: absolute;
          width: 231px;
          z-index: 4;
          top: calc(50% - 115px);
          right: calc(50% - 340px);
          transition: .3s ease-in-out;
          cursor: pointer; }
          .container-1280 .section4 .caise-s4:hover {
            top: calc(50% - 125px);
            transition: .3s ease-in-out; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section4 .caise-s4 {
          width: 200px;
          left: calc(50% - 100px);
          top: 480px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section4 .umbra-caise {
          position: absolute;
          width: 231px;
          z-index: 3;
          top: calc(50% - -35px);
          right: calc(50% - 340px);
          transition: .3s ease-in-out;
          cursor: pointer; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section4 .umbra-caise {
          width: 200px;
          left: calc(50% - 100px);
          top: calc(50% - -225px); } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section4 .caise-s4:hover + .umbra-caise {
          right: calc(50% - 312px);
          top: calc(50% - -50px);
          width: 170px; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section4 .caise-s4:hover + .umbra-caise {
          display: none; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section4 .zeu-caise-s4 {
          position: absolute;
          width: 730px;
          z-index: 2;
          right: calc(50% - 750px);
          bottom: 100px;
          transition: 1s ease-out; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section4 .zeu-caise-s4 {
          width: 700px;
          right: -130px;
          top: 300px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section4 .nor-harpa {
          position: absolute;
          width: 700px;
          right: -330px;
          top: 60px; }
        .container-1280 .section4 .zeu-caise-s4-1 {
          position: absolute;
          width: 350px;
          z-index: 4;
          right: calc(50% - 450px);
          transition: 1s ease-out;
          bottom: 240px; }
        .container-1280 .section4 .zeu-cirese-s4 {
          position: absolute;
          width: 500px;
          z-index: 2;
          left: calc(50% - 650px);
          bottom: 170px;
          transition: 1s ease-out;
          z-index: 4; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section4 .zeu-cirese-s4 {
          width: 380px;
          left: -70px;
          bottom: -180px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section4 .nor-bufnita-1 {
          position: absolute;
          width: 700px;
          left: 0;
          top: 100px;
          z-index: 2; }
        .container-1280 .section4 .nor-cirese {
          position: absolute;
          z-index: 2;
          width: 800px;
          bottom: -100px;
          left: calc(50% - 700px); }
        .container-1280 .section4 .nor-cirese-top {
          position: absolute;
          width: 400px;
          bottom: -30px;
          z-index: 4;
          left: calc(50% - 450px);
          z-index: 6 !important; }
        .container-1280 .section4 .nor-harp {
          position: absolute;
          width: 700px;
          left: -350px;
          bottom: 150px;
          transition: 2s ease-out; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section4 .nor-harp {
          left: -190px;
          width: 800px; } }
  @media screen and (min-width: 48.75em) {
      .container-1280 .section5 {
        width: 100%;
        height: 768px;
        position: relative;
        background: #618cc2; }
        .container-1280 .section5 .nor-top-5 {
          position: absolute;
          bottom: 185px;
          z-index: 4;
          right: calc(50% - 400px); }
        .container-1280 .section5:hover .zeu-capsuna-s5 {
          transition: 1s ease-out;
          right: calc(50% - 560px); }
        .container-1280 .section5 .stropi-2 {
          position: absolute;
          z-index: 3;
          width: 450px;
          margin-left: calc(50% - 470px);
          bottom: 40px; }
        .container-1280 .section5 .birds {
          position: absolute;
          width: 90%;
          left: calc(50% - 45%);
          top: 150px;
          z-index: 3; }
        .container-1280 .section5 .nor-baza-stanga {
          position: absolute;
          bottom: 0px;
          left: -400px;
          width: 700px;
          z-index: 2;
          overflow: hidden; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section5 .nor-baza-stanga {
          left: -200px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section5 .nor-baza-dreapta {
          position: absolute;
          bottom: 0px;
          right: -380px;
          width: 700px;
          z-index: 2;
          overflow: hidden; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section5 .nor-baza-dreapta {
          right: -220px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section5 .nor-stanga-2 {
          position: absolute;
          width: 700px;
          left: -250px;
          bottom: 100px; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section5 .nor-stanga-2 {
          width: 900px;
          left: -100px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section5 .nor-dreapta-2 {
          position: absolute;
          width: 600px;
          right: -400px;
          bottom: 50px; }
        .container-1280 .section5 .capsuna-s5 {
          position: absolute;
          right: calc(50% - 291px);
          width: 231px;
          bottom: 250px;
          z-index: 3;
          transition: .3s ease-in-out;
          cursor: pointer; }
          .container-1280 .section5 .capsuna-s5:hover {
            bottom: 260px;
            transition: .3s ease-in-out; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section5 .capsuna-s5 {
          width: 200px;
          left: calc(50% - 100px);
          bottom: 150px; } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section5 .umbra-capsuna {
          position: absolute;
          right: calc(50% - 291px);
          width: 231px;
          bottom: 240px;
          z-index: 3;
          transition: .3s ease-in-out;
          cursor: pointer;
          z-index: 2; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section5 .umbra-capsuna {
          bottom: 142px;
          right: calc(50% - 120px); } }
  @media screen and (min-width: 48.75em) {
        .container-1280 .section5 .capsuna-s5:hover + .umbra-capsuna {
          right: calc(50% - 265px);
          bottom: 240px;
          width: 170px; }
        .container-1280 .section5 .zeu-capsuna-s5 {
          position: absolute;
          width: 550px;
          z-index: 2;
          right: calc(50% - 650px);
          bottom: 600px;
          z-index: 4;
          transition: 1s ease-out; } }
      @media screen and (min-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .container-1280 .section5 .zeu-capsuna-s5 {
          right: -150px;
          bottom: 220px; } }

#raze-contact {
  position: fixed;
  z-index: 1;
  width: 500px;
  left: calc(50% - 250px);
  top: -100px; }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    #raze-contact {
      left: calc(50% - 200px); } }

#raze-contact-2 {
  position: absolute;
  z-index: 4;
  width: 500px;
  left: calc(50% - 250px);
  top: -50px;
  height: 300px; }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    #raze-contact-2 {
      left: calc(50% - 200px); } }

#munte {
  position: absolute;
  width: 200px;
  z-index: 1;
  bottom: 0;
  width: 1390px; }
  @media screen and (max-width: 39.9375em) {
    #munte {
      display: none; } }

.index-mobil {
  display: none;
  position: relative; }
  @media screen and (max-width: 48.75em) {
    .index-mobil {
      display: block;
      overflow: hidden; }
      .index-mobil .pop-up {
        position: fixed;
        display: block;
        background-color: #f7f7f7;
        width: 100%;
        height: 100%;
        left: calc(50% - 50%);
        top: 0;
        z-index: 11;
        display: none; }
        .index-mobil .pop-up .close-icon {
          position: absolute;
          width: 30px;
          height: 30px;
          top: 0px;
          right: 0px;
          z-index: 4;
          cursor: pointer; }
          .index-mobil .pop-up .close-icon .xbtn {
            position: absolute;
            width: 25px;
            height: 2px;
            margin-left: 2px;
            margin-top: calc(50% - 1px);
            top: 0;
            background-color: #22349a;
            transform: rotate(45deg); }
            .index-mobil .pop-up .close-icon .xbtn::before {
              content: '';
              width: 25px;
              height: 2px;
              background-color: #22349a;
              position: absolute;
              transform: rotate(-90deg); } }
      @media screen and (max-width: 48.75em) and (min-width: 20em) and (max-width: 39.9375em) {
        .index-mobil .pop-up .close-icon {
          top: -10px;
          right: 10px; }
          .index-mobil .pop-up .close-icon .xbtn {
            width: 35px; }
            .index-mobil .pop-up .close-icon .xbtn::before {
              width: 35px; } }
  @media screen and (max-width: 48.75em) {
        .index-mobil .pop-up .pergament {
          display: none; }
        .index-mobil .pop-up .content-zeus, .index-mobil .pop-up .content-hera {
          position: relative;
          padding-top: 30px; }
        .index-mobil .pop-up .content {
          position: absolute;
          width: 90%;
          left: calc(50% - 45%);
          top: 0px;
          height: 90%;
          margin-top: 30px; }
          .index-mobil .pop-up .content .title-info {
            padding-top: 0px;
            font-size: 20px; }
          .index-mobil .pop-up .content .popup-title1 {
            font-size: 16px;
            letter-spacing: 2px;
            text-transform: uppercase; }
          .index-mobil .pop-up .content .lauri {
            position: relative;
            width: 150px;
            margin-left: calc(50% - 75px);
            margin-bottom: 15px;
            margin-top: 5px; }
          .index-mobil .pop-up .content p span {
            font-weight: 600; }
          .index-mobil .pop-up .content .play-icon {
            position: relative;
            margin-bottom: 15px;
            margin-top: 15px;
            margin-left: calc(50% - 20px);
            width: 40px;
            cursor: pointer; }
          .index-mobil .pop-up .content audio {
            width: 300px;
            margin-left: calc(50% - 150px); }
          .index-mobil .pop-up .content .lauri-info {
            position: relative;
            width: 150px;
            margin-left: calc(50% - 75px);
            margin-bottom: 8px;
            margin-top: 3px; }
          .index-mobil .pop-up .content .info {
            position: relative;
            width: 50%;
            float: left;
            text-align: center; }
            .index-mobil .pop-up .content .info p {
              font-weight: 600; }
            .index-mobil .pop-up .content .info ul {
              list-style: none;
              margin-left: 0; }
              .index-mobil .pop-up .content .info ul li {
                color: #22349a;
                font-family: 'roma-regular';
                line-height: 19px; }
        .index-mobil .pop-up .spoon {
          position: absolute;
          bottom: 0;
          width: 300px;
          left: 300px;
          bottom: -10px;
          z-index: 3; }
      .index-mobil .section-1 {
        position: relative; } }
    @media screen and (max-width: 48.75em) and (min-width: 20em) {
      .index-mobil .section-1 {
        height: 100vh; } }
    @media screen and (max-width: 48.75em) and (max-width: 20em) {
      .index-mobil .section-1 {
        height: 130vh; } }
  @media screen and (max-width: 48.75em) {
        .index-mobil .section-1 .iaurt-produs {
          position: absolute;
          width: 230px;
          bottom: -85px;
          right: calc(50% - 115px);
          z-index: 4; }
        .index-mobil .section-1 .umbra {
          position: absolute;
          bottom: -90px;
          width: 230px;
          left: calc(50% - 115px);
          z-index: 3; }
      .index-mobil .munte-top {
        position: absolute;
        top: 225px; }
      .index-mobil .sectiona {
        height: 150vh;
        width: 100%;
        position: relative; } }
    @media screen and (max-width: 48.75em) and (min-width: 20em) {
      .index-mobil .sectiona {
        height: 120vh; } }
    @media screen and (max-width: 48.75em) and (max-width: 20em) {
      .index-mobil .sectiona {
        height: 150vh; } }
  @media screen and (max-width: 48.75em) {
        .index-mobil .sectiona .iaurt-produs {
          position: absolute;
          width: 230px;
          bottom: 0;
          right: calc(50% - 115px);
          z-index: 4; }
        .index-mobil .sectiona .umbra {
          position: absolute;
          bottom: -10px;
          width: 230px;
          left: calc(50% - 115px);
          z-index: 3; }
      .index-mobil .section-2 {
        position: relative; }
        .index-mobil .section-2 .munte-1 {
          position: absolute;
          z-index: -1; } }
      @media screen and (max-width: 48.75em) and (min-width: 20em) {
        .index-mobil .section-2 .munte-1 {
          bottom: 40px; } }
      @media screen and (max-width: 48.75em) and (max-width: 20em) {
        .index-mobil .section-2 .munte-1 {
          bottom: 50px; } }
      @media screen and (max-width: 48.75em) and (max-width: 20em) {
        .index-mobil .section-2 .munte-1 {
          bottom: 50px; } }
      @media screen and (max-width: 48.75em) and (max-width: 21.375em) {
        .index-mobil .section-2 .munte-1 {
          bottom: -90px; } }
      @media screen and (max-width: 48.75em) and (min-width: 22.5em) {
        .index-mobil .section-2 .munte-1 {
          bottom: 0px; } }
      @media screen and (max-width: 48.75em) and (max-width: 25em) {
        .index-mobil .section-2 .munte-1 {
          bottom: -130px; } }
      @media screen and (max-width: 48.75em) and (max-width: 28.125em) {
        .index-mobil .section-2 .munte-1 {
          bottom: -130px; } }
  @media screen and (max-width: 48.75em) {
        .index-mobil .section-2 .munte-top {
          position: absolute;
          margin: auto;
          top: -450px;
          z-index: 2; }
      .index-mobil .section-3 {
        position: relative; }
        .index-mobil .section-3 .munte-2 {
          position: absolute;
          bottom: 0px; }
      .index-mobil .section-4 {
        position: relative; }
        .index-mobil .section-4 .munte-3 {
          position: absolute;
          bottom: 0px; }
      .index-mobil .section-5 {
        position: relative; }
        .index-mobil .section-5 .munte-4 {
          position: absolute;
          bottom: 0px; }
      .index-mobil .section-6 {
        position: relative; }
        .index-mobil .section-6 .munte-baza {
          position: absolute;
          bottom: 0px; }
      .index-mobil .zeus-mobil {
        position: absolute;
        width: 500px;
        bottom: -40px;
        left: -195px;
        z-index: 3; } }
    @media screen and (max-width: 48.75em) and (min-width: 20em) {
      .index-mobil .zeus-mobil {
        width: 400px;
        left: -125px; } }
    @media screen and (max-width: 48.75em) and (max-width: 20em) {
      .index-mobil .zeus-mobil {
        width: 500px; } }
  @media screen and (max-width: 48.75em) {
      .index-mobil .nor-zeus {
        position: absolute;
        width: 350px;
        bottom: -180px;
        right: -50px;
        z-index: 2; }
      .index-mobil .nor-zeus-2 {
        position: absolute;
        transform: rotate(190deg);
        left: -50px;
        bottom: -120px;
        width: 200px;
        z-index: 2; }
      .index-mobil .hera-mobil {
        position: absolute;
        width: 400px;
        top: 180px;
        right: -170px;
        z-index: 2; }
      .index-mobil .nor-grifon-mobil {
        position: absolute;
        display: block;
        width: 800px;
        top: 350px;
        right: -100px; }
      .index-mobil .apollo-mobil {
        position: absolute;
        width: 390px;
        bottom: 70px;
        right: -50px;
        z-index: 2; }
      .index-mobil .atena-mobil {
        position: absolute;
        width: 600px;
        bottom: 160px;
        left: -40px;
        z-index: 2; }
      .index-mobil .dragon {
        position: absolute;
        z-index: 4;
        top: 100px;
        z-index: red;
        width: 320px;
        left: -80px; }
      .index-mobil .afrodita-mobil {
        position: absolute;
        width: 1000px;
        bottom: 160px;
        left: 0px;
        z-index: 3; }
      .index-mobil .nor-hera {
        position: absolute;
        width: 300px;
        bottom: -90px;
        z-index: 2; }
      .index-mobil .nor-hera-2 {
        position: absolute;
        width: 250px;
        bottom: -100px;
        right: -50px;
        z-index: 2;
        transform: rotate(180deg); }
      .index-mobil .nor-afrodita {
        position: absolute;
        width: 300px;
        bottom: -90px;
        z-index: 2;
        left: -20px; }
      .index-mobil .nor-afrodita-2 {
        position: absolute;
        width: 300px;
        bottom: -90px;
        z-index: 2;
        right: 0;
        transform: rotate(180deg); }
      .index-mobil .templu-1 {
        position: absolute;
        z-index: 3;
        bottom: 0px;
        left: -110px; }
      .index-mobil .templu-2 {
        position: absolute;
        z-index: 3;
        bottom: 0px;
        right: -130px; }
      .index-mobil .nor-templu-1 {
        position: absolute;
        bottom: -20px;
        left: -110px;
        width: 200px;
        z-index: 4; }
      .index-mobil .nor-templu-2 {
        position: absolute;
        bottom: -20px;
        right: -130px;
        width: 250px;
        z-index: 4; } }

#munte-mobil {
  position: absolute;
  bottom: 0px;
  z-index: 1; }
  @media screen and (min-width: 48.75em) {
    #munte-mobil {
      display: none; } }
  @media screen and (max-width: 48.75em) {
    #munte-mobil {
      display: block; } }

#popup-index-mobile-scroll {
  height: 100%;
  overflow: scroll; }

#raze-product {
  position: fixed;
  z-index: 1;
  top: -80px;
  width: 600px;
  left: calc(50% - 300px); }
  @media screen and (max-width: 39.9375em) {
    #raze-product {
      left: calc(50% - 150px); } }

.container-product {
  position: relative;
  max-width: 1280px;
  height: 700px;
  margin: auto;
  z-index: 1; }
  @media screen and (max-width: 48em) {
    .container-product {
      display: none; } }
  .container-product .pop-up-product {
    position: fixed;
    width: 590px;
    height: 600px;
    left: calc(50% - 295px);
    top: calc(50% - 250px);
    z-index: 8;
    display: none; }
    @media screen and (min-width: 20em) and (max-width: 39.9375em) {
      .container-product .pop-up-product {
        background-color: #f0eeec;
        top: 0;
        height: 100%;
        width: 100%;
        left: 0; } }
    .container-product .pop-up-product .pergament {
      position: fixed;
      width: 590px;
      height: 580px;
      z-index: -1; }
      @media screen and (min-width: 20em) and (max-width: 39.9375em) {
        .container-product .pop-up-product .pergament {
          display: none; } }
    .container-product .pop-up-product .content-product {
      position: relative;
      margin: 50px auto;
      width: 430px;
      height: 500px; }
      @media screen and (min-width: 20em) and (max-width: 39.9375em) {
        .container-product .pop-up-product .content-product {
          background-color: aquamarine;
          height: 100%;
          width: 90%; } }
      .container-product .pop-up-product .content-product .product-scroll {
        width: 430px;
        height: 288px;
        overflow: scroll;
        padding: 0 20px; }
      .container-product .pop-up-product .content-product .product-scroll-2 {
        width: 430px;
        height: 240px;
        overflow: scroll;
        padding: 0 20px; }
      .container-product .pop-up-product .content-product .title-info {
        padding-top: 0px;
        font-size: 20px; }
      .container-product .pop-up-product .content-product .popup-title1 {
        font-size: 16px;
        letter-spacing: 2px;
        text-transform: uppercase; }
      .container-product .pop-up-product .content-product .close-icon {
        position: absolute;
        width: 30px;
        height: 30px;
        top: -10px;
        right: -10px;
        z-index: 4;
        cursor: pointer; }
        .container-product .pop-up-product .content-product .close-icon .xbtn {
          position: absolute;
          width: 25px;
          height: 2px;
          margin-left: 2px;
          margin-top: calc(50% - 1px);
          top: 0;
          background-color: #22349a;
          transform: rotate(45deg); }
          .container-product .pop-up-product .content-product .close-icon .xbtn::before {
            content: '';
            width: 25px;
            height: 2px;
            background-color: #22349a;
            position: absolute;
            transform: rotate(-90deg); }
      .container-product .pop-up-product .content-product .lauri {
        position: relative;
        width: 150px;
        margin-left: calc(50% - 75px);
        margin-bottom: 15px;
        margin-top: 5px; }
      .container-product .pop-up-product .content-product p span {
        font-weight: 600; }
      .container-product .pop-up-product .content-product .play-icon {
        position: relative;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: calc(50% - 20px);
        width: 40px;
        cursor: pointer; }
      .container-product .pop-up-product .content-product audio {
        width: 300px;
        margin-left: calc(50% - 150px); }
      .container-product .pop-up-product .content-product .lauri-info {
        position: relative;
        width: 150px;
        margin-left: calc(50% - 75px);
        margin-bottom: 8px;
        margin-top: 3px; }
      .container-product .pop-up-product .content-product .info {
        position: relative;
        width: 50%;
        float: left;
        text-align: center; }
        .container-product .pop-up-product .content-product .info p {
          font-weight: 600; }
        .container-product .pop-up-product .content-product .info ul {
          list-style: none;
          margin-left: 0; }
          .container-product .pop-up-product .content-product .info ul li {
            color: #22349a;
            font-family: 'roma-regular';
            line-height: 19px; }
    .container-product .pop-up-product .spoon {
      position: absolute;
      bottom: 0;
      width: 300px;
      left: 300px;
      bottom: -10px;
      z-index: 3; }
      @media screen and (min-width: 20em) and (max-width: 39.9375em) {
        .container-product .pop-up-product .spoon {
          width: 200px;
          bottom: 10px;
          left: 280px; } }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    .container-product .coloane-product {
      display: none; } }
  .container-product .coloana-stanga1-product {
    position: absolute;
    top: -100px;
    left: 230px;
    width: 80px; }
  .container-product .coloana-stanga2-product {
    position: absolute;
    top: -130px;
    left: 430px;
    width: 80px; }
  .container-product .coloana-dreapta1-product {
    position: absolute;
    top: -100px;
    right: 230px;
    width: 80px; }
  .container-product .coloana-dreapta2-product {
    position: absolute;
    top: -130px;
    right: 408px;
    width: 80px; }
  .container-product .coloana-stanga0-product {
    position: absolute;
    top: -50px;
    left: 80px;
    width: 90px; }
  .container-product .coloana-dreapta0-product {
    position: absolute;
    top: -50px;
    right: 80px;
    width: 90px; }
  .container-product .back-alb-2 {
    width: 950px;
    height: 750px;
    position: absolute;
    left: calc(50% - 425px);
    top: -150px;
    z-index: 1; }
  .container-product .product-space {
    position: relative;
    width: 800px;
    height: 400px;
    margin-left: calc(50% - 400px);
    margin-top: calc(60vh - 200px); }
    .container-product .product-space .zeu-product-zeus {
      width: 750px;
      z-index: 3;
      position: absolute;
      left: -480px;
      top: -120px; }
    .container-product .product-space .iaurt-product-zeus {
      position: absolute;
      left: 120px;
      top: 40px;
      z-index: 4;
      cursor: pointer;
      transition: .3s ease-out;
      width: 230px; }
      .container-product .product-space .iaurt-product-zeus:hover {
        top: 30px;
        transition: .3s ease-out; }
      .container-product .product-space .iaurt-product-zeus:hover + .umbra-product-zeus {
        width: 200px;
        left: 135px;
        transition: .3s ease-out; }
    .container-product .product-space .umbra-product-zeus {
      position: absolute;
      width: 231px;
      left: 120px;
      top: 190px;
      z-index: 3;
      cursor: pointer;
      transition: .3s ease-out; }
    .container-product .product-space .zeu-product-hera {
      width: 580px;
      z-index: 3;
      position: absolute;
      right: -430px;
      top: -120px; }
    .container-product .product-space .iaurt-product-hera {
      position: absolute;
      right: 120px;
      top: 40px;
      z-index: 4;
      cursor: pointer;
      transition: .3s ease-out;
      width: 230px; }
      .container-product .product-space .iaurt-product-hera:hover {
        top: 30px;
        transition: .3s ease-out; }
      .container-product .product-space .iaurt-product-hera:hover + .umbra-product-hera {
        width: 200px;
        right: 135px;
        transition: .3s ease-out; }
    .container-product .product-space .umbra-product-hera {
      position: absolute;
      width: 231px;
      right: 120px;
      top: 190px;
      z-index: 2;
      cursor: pointer;
      transition: .3s ease-out; }
    .container-product .product-space .zeu-product-afrodita {
      position: absolute;
      width: 530px;
      z-index: 3;
      left: -430px;
      bottom: -150px; }
    .container-product .product-space .iaurt-product-afrodita {
      position: absolute;
      z-index: 4;
      cursor: pointer;
      bottom: -20px;
      left: -20px;
      transition: .3s ease-out;
      width: 230px; }
      .container-product .product-space .iaurt-product-afrodita:hover {
        bottom: -10px;
        transition: .3s ease-out; }
      .container-product .product-space .iaurt-product-afrodita:hover + .umbra-product-afrodita {
        width: 200px;
        left: -5px;
        transition: .3s ease-out; }
    .container-product .product-space .umbra-product-afrodita {
      position: absolute;
      width: 231px;
      z-index: 2;
      cursor: pointer;
      bottom: -30px;
      left: -20px;
      transition: .3s ease-out; }
    .container-product .product-space .zeu-product-atena {
      position: absolute;
      width: 480px;
      z-index: 3;
      bottom: -170px;
      right: -370px; }
    .container-product .product-space .iaurt-product-atena {
      position: absolute;
      z-index: 3;
      cursor: pointer;
      bottom: -20px;
      right: -20px;
      transition: .3s ease-out;
      width: 230px; }
      .container-product .product-space .iaurt-product-atena:hover {
        bottom: -10px;
        transition: .3s ease-out; }
      .container-product .product-space .iaurt-product-atena:hover + .umbra-product-atena {
        width: 200px;
        right: -10px;
        transition: .3s ease-out; }
    .container-product .product-space .umbra-product-atena {
      position: absolute;
      width: 231px;
      z-index: 2;
      cursor: pointer;
      bottom: -30px;
      right: -20px;
      transition: .3s ease-out; }
    .container-product .product-space .zeu-product-apollo {
      position: absolute;
      width: 650px;
      z-index: 3;
      bottom: -330px;
      margin-left: calc(50% - 450px); }
    .container-product .product-space .iaurt-product-apollo {
      position: absolute;
      z-index: 4;
      cursor: pointer;
      bottom: -90px;
      left: calc(50% - 120px);
      transition: .3s ease-out;
      width: 230px; }
      .container-product .product-space .iaurt-product-apollo:hover {
        bottom: -80px;
        transition: .3s ease-out; }
      .container-product .product-space .iaurt-product-apollo:hover + .umbra-product-apollo {
        width: 200px;
        left: calc(50% - 100px);
        transition: .3s ease-out; }
    .container-product .product-space .umbra-product-apollo {
      position: absolute;
      width: 231px;
      z-index: 3;
      cursor: pointer;
      bottom: -100px;
      left: calc(50% - 120px);
      transition: .3s ease-out; }
    .container-product .product-space .nor-product-afrodita {
      position: absolute;
      width: 900px;
      left: -400px;
      bottom: -250px; }
    .container-product .product-space .nor-product-atena {
      position: absolute;
      width: 900px;
      right: -400px;
      bottom: -250px; }
    .container-product .product-space .nor-product-apollo {
      position: absolute;
      width: 800px;
      right: calc(50% - 400px);
      bottom: -300px; }
    .container-product .product-space .nor-product-zeus {
      position: absolute;
      width: 700px;
      left: -350px;
      bottom: 0px; }
    .container-product .product-space .nor-product-hera {
      position: absolute;
      width: 700px;
      right: -350px;
      bottom: 0px; }

.produse-mobil {
  overflow: hidden;
  position: relative; }
  .produse-mobil .pop-up-product {
    position: fixed;
    width: 590px;
    height: 600px;
    left: calc(50% - 295px);
    top: calc(50% - 250px);
    z-index: 8;
    display: none; }
    @media screen and (min-width: 20em) and (max-width: 39.9375em) {
      .produse-mobil .pop-up-product {
        background-color: #f0eeec;
        top: 0;
        height: 100%;
        width: 100%;
        left: 0; } }
    .produse-mobil .pop-up-product .pergament {
      position: fixed;
      width: 590px;
      height: 580px;
      z-index: -1; }
      @media screen and (min-width: 20em) and (max-width: 39.9375em) {
        .produse-mobil .pop-up-product .pergament {
          display: none; } }
    .produse-mobil .pop-up-product .content-product {
      position: relative;
      margin: 50px auto;
      width: 440px;
      height: 500px; }
      @media screen and (min-width: 20em) and (max-width: 39.9375em) {
        .produse-mobil .pop-up-product .content-product {
          height: 100%;
          width: 90%; } }
      .produse-mobil .pop-up-product .content-product .product-scroll {
        overflow: scroll;
        height: 90%; }
      .produse-mobil .pop-up-product .content-product .title-info {
        padding-top: 0px;
        font-size: 20px; }
      .produse-mobil .pop-up-product .content-product .popup-title1 {
        font-size: 16px;
        letter-spacing: 2px;
        text-transform: uppercase; }
      .produse-mobil .pop-up-product .content-product .close-icon {
        position: absolute;
        width: 30px;
        height: 30px;
        top: -30px;
        right: 0px;
        z-index: 4;
        cursor: pointer; }
        .produse-mobil .pop-up-product .content-product .close-icon .xbtn {
          position: absolute;
          width: 25px;
          height: 2px;
          margin-left: 2px;
          margin-top: calc(50% - 1px);
          top: 0;
          background-color: #22349a;
          transform: rotate(45deg); }
          .produse-mobil .pop-up-product .content-product .close-icon .xbtn::before {
            content: '';
            width: 25px;
            height: 2px;
            background-color: #22349a;
            position: absolute;
            transform: rotate(-90deg); }
      .produse-mobil .pop-up-product .content-product .lauri {
        position: relative;
        width: 150px;
        margin-left: calc(50% - 75px);
        margin-bottom: 15px;
        margin-top: 5px; }
      .produse-mobil .pop-up-product .content-product p span {
        font-weight: 600; }
      .produse-mobil .pop-up-product .content-product .play-icon {
        position: relative;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: calc(50% - 20px);
        width: 40px;
        cursor: pointer; }
      .produse-mobil .pop-up-product .content-product .lauri-info {
        position: relative;
        width: 150px;
        margin-left: calc(50% - 75px);
        margin-bottom: 8px;
        margin-top: 3px; }
      .produse-mobil .pop-up-product .content-product .info {
        position: relative;
        width: 50%;
        float: left;
        text-align: center; }
        .produse-mobil .pop-up-product .content-product .info p {
          font-weight: 600; }
        .produse-mobil .pop-up-product .content-product .info ul {
          list-style: none;
          margin-left: 0; }
          .produse-mobil .pop-up-product .content-product .info ul li {
            color: #22349a;
            font-family: 'roma-regular';
            line-height: 19px; }
      .produse-mobil .pop-up-product .content-product .spoon-wrapper {
        position: relative;
        height: 150px;
        overflow: hidden; }
        .produse-mobil .pop-up-product .content-product .spoon-wrapper .spoon-mobile {
          position: relative;
          bottom: 30px;
          right: -80px; }
  @media screen and (min-width: 48em) {
    .produse-mobil {
      display: none; } }
  .produse-mobil .section2 {
    position: relative;
    height: 110vh;
    width: 100%; }
    .produse-mobil .section2 .iaurt-produs {
      width: 230px;
      position: absolute;
      left: calc(50% - 115px);
      z-index: 3; }
      @media screen and (min-width: 20em) {
        .produse-mobil .section2 .iaurt-produs {
          bottom: -60px; } }
      @media screen and (max-width: 20em) {
        .produse-mobil .section2 .iaurt-produs {
          bottom: 0; } }
    .produse-mobil .section2 .umbra {
      position: absolute;
      bottom: -10px;
      width: 230px;
      left: calc(50% - 115px);
      z-index: 2; }
    .produse-mobil .section2 .back-alb-mobil {
      position: absolute;
      width: 600px;
      bottom: 50px;
      z-index: 1; }
      @media screen and (max-width: 48em) {
        .produse-mobil .section2 .back-alb-mobil {
          display: none; } }
    @media screen and (min-width: 20em) {
      .produse-mobil .section2 {
        height: 70vh; } }
    @media screen and (max-width: 20em) {
      .produse-mobil .section2 {
        height: 110vh; } }
  .produse-mobil .section-produse {
    position: relative;
    height: 100vh;
    width: 100%; }
    @media screen and (min-width: 20em) {
      .produse-mobil .section-produse {
        height: 70vh; } }
    @media screen and (max-width: 20em) {
      .produse-mobil .section-produse {
        height: 100vh; } }
    .produse-mobil .section-produse .iaurt-produs {
      width: 230px;
      position: absolute;
      left: calc(50% - 115px);
      bottom: 0;
      z-index: 3; }
    .produse-mobil .section-produse .umbra {
      position: absolute;
      bottom: -10px;
      width: 230px;
      left: calc(50% - 115px);
      z-index: 3; }
  .produse-mobil .coloana-s1 {
    width: 70px;
    position: absolute;
    bottom: -70px;
    left: 10px;
    z-index: 1; }
  .produse-mobil .coloana-s2 {
    width: 70px;
    position: absolute;
    left: calc(50% - 78px);
    bottom: -30px;
    z-index: 1; }
  .produse-mobil .coloana-d1 {
    width: 70px;
    position: absolute;
    bottom: -70px;
    right: 10px;
    z-index: 1; }
  .produse-mobil .coloana-d2 {
    width: 70px;
    position: absolute;
    right: calc(50% - 78px);
    bottom: -30px;
    z-index: 1; }
  .produse-mobil .nor-coloane-1 {
    position: absolute;
    bottom: -100px;
    z-index: 2; }
  .produse-mobil .nor-coloane-2 {
    position: absolute;
    bottom: -100px;
    z-index: 1; }
  .produse-mobil .nor-coloane-3 {
    width: 250px;
    right: 0;
    position: absolute;
    bottom: -150px; }
  .produse-mobil .nor-coloane-4 {
    width: 250px;
    left: 0;
    position: absolute;
    bottom: -190px;
    transform: rotate(180deg); }
  .produse-mobil .hera-nor-1 {
    width: 550px;
    position: absolute;
    bottom: -200px; }
  .produse-mobil .hera-nor-2 {
    position: absolute;
    width: 400px;
    bottom: -200px;
    right: -100px; }
  .produse-mobil .apollo-nor-2 {
    position: absolute;
    bottom: -100px; }
  .produse-mobil .atena-nor-1 {
    position: absolute;
    bottom: -100px; }
  .produse-mobil .atena-nor-2 {
    position: absolute;
    bottom: -50px;
    left: -50px; }
  .produse-mobil .atena-nor-3 {
    position: absolute;
    bottom: -50px;
    right: -50px; }
  .produse-mobil .afrodita-nor-1 {
    position: absolute;
    bottom: -170px; }
  .produse-mobil .afrodita-nor-2 {
    position: absolute;
    bottom: -220px;
    right: -100px; }

.product-scroll::-webkit-scrollbar {
  width: .2em; }

.product-scroll:-webkit-scrollbar,
.product-scroll::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px; }

.product-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(34, 52, 154, 0.6); }

.product-scroll:hover .cover-bar {
  -webkit-transition: all .5s; }

.product-scroll::-webkit-scrollbar-corner {
  background-color: none; }

.product-scroll-2::-webkit-scrollbar {
  width: .2em; }

.product-scroll-2:-webkit-scrollbar,
.product-scroll-2::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px; }

.product-scroll-2::-webkit-scrollbar-thumb {
  background-color: rgba(34, 52, 154, 0.6); }

.product-scroll-2:hover .cover-bar {
  -webkit-transition: all .5s; }

.product-scroll-2::-webkit-scrollbar-corner {
  background-color: none; }

#popup-produse-mobile-scroll {
  height: 85%;
  width: 100%;
  overflow: scroll; }

.container-retete {
  position: relative;
  max-width: 1170px;
  height: auto;
  margin: auto;
  z-index: 1;
  padding-bottom: 100px; }
  @media screen and (max-width: 48.75em) {
    .container-retete {
      width: 100%; } }

.nor-product-1 {
  position: absolute;
  top: 0;
  margin-left: -200px; }
  @media screen and (max-width: 48.75em) {
    .nor-product-1 {
      display: none; } }

@media screen and (max-width: 48.75em) {
  .nor {
    display: none; } }

.nor-product-2 {
  position: absolute;
  top: 0;
  right: -200px; }
  @media screen and (max-width: 48.75em) {
    .nor-product-2 {
      display: none; } }

.nor-product-3 {
  position: absolute;
  top: 150px;
  left: -80px; }
  @media screen and (max-width: 48.75em) {
    .nor-product-3 {
      display: none; } }

.nor-product-4 {
  position: absolute;
  top: 150px;
  right: -200px;
  z-index: -1; }
  @media screen and (max-width: 48.75em) {
    .nor-product-4 {
      display: none; } }

@media screen and (max-width: 48.75em) {
  .nor-product-hera {
    display: none; } }

.retete-space {
  position: relative;
  width: 1170px;
  margin: auto;
  margin-top: 200px;
  z-index: 15; }
  @media screen and (max-width: 48.75em) {
    .retete-space {
      width: 100%;
      margin-top: 250px;
      margin-top: 250px; } }
  .retete-space .alb-retete {
    position: absolute;
    width: 1000px;
    margin-left: calc(50% - 500px);
    top: -200px; }
  .retete-space .text-start {
    margin-bottom: 40px;
    text-align: left;
    position: relative;
    display: block;
    z-index: 4;
    width: 800px;
    margin-left: calc(50% - 350px); }
    @media screen and (max-width: 48.75em) {
      .retete-space .text-start {
        margin: 35px 30px; } }
  .retete-space .reteta {
    position: relative;
    z-index: 5;
    margin-bottom: 110px; }
    .retete-space .reteta .papirus-retete {
      position: absolute;
      width: 452px;
      margin-left: calc(50% - 226px); }
      @media screen and (max-width: 48.75em) {
        .retete-space .reteta .papirus-retete {
          margin: auto;
          width: 100%; } }
    .retete-space .reteta .content-reteta {
      position: relative;
      width: 70%;
      margin-left: calc(50% - 35%);
      padding: 20px 20px;
      margin-top: 50px; }
      @media screen and (max-width: 48.75em) {
        .retete-space .reteta .content-reteta {
          padding: 0; } }
      .retete-space .reteta .content-reteta .img-reteta {
        width: 80%;
        height: 130px;
        margin-left: calc(50% - 40%);
        margin-bottom: 10px; }
        @media screen and (max-width: 48.75em) {
          .retete-space .reteta .content-reteta .img-reteta {
            width: 100%;
            margin: auto;
            height: 100px;
            margin-bottom: 20px; } }
      .retete-space .reteta .content-reteta .lauri-retete {
        width: 140px;
        margin-left: calc(50% - 70px); }
      .retete-space .reteta .content-reteta p {
        margin: 15px 15px;
        text-align: left; }
        @media screen and (max-width: 48.75em) {
          .retete-space .reteta .content-reteta p {
            width: 100%;
            margin-left: 0;
            padding: 0; } }

.pergament-detaliu {
  position: relative;
  width: 900px;
  margin-left: calc(50% - 450px);
  background-color: #f8f8f8;
  height: auto;
  padding: 30px 30px; }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    .pergament-detaliu {
      width: 80%;
      margin-left: calc(50% - 40%);
      padding: 10px 10px; } }
  .pergament-detaliu .pergament-over {
    width: 1000px;
    height: 40px;
    position: absolute;
    top: -20px;
    margin-left: calc(50% - 530px); }
    @media screen and (min-width: 20em) and (max-width: 39.9375em) {
      .pergament-detaliu .pergament-over {
        width: 110%;
        margin-left: calc(50% - 58%); } }
  .pergament-detaliu .pergament-bottom {
    width: 1000px;
    height: 40px;
    position: absolute;
    bottom: -20px;
    margin-left: calc(50% - 530px);
    transform: rotate(180deg); }
    @media screen and (min-width: 20em) and (max-width: 39.9375em) {
      .pergament-detaliu .pergament-bottom {
        width: 110%;
        margin-left: calc(50% - 58%); } }
  .pergament-detaliu h1 {
    margin: auto;
    margin-top: 40px;
    width: 100%; }
    @media screen and (min-width: 20em) and (max-width: 39.9375em) {
      .pergament-detaliu h1 {
        margin-top: 20px; } }
  .pergament-detaliu .lauri-detaliu {
    display: block;
    position: relative;
    width: 170px;
    margin: auto;
    height: 25px; }
  .pergament-detaliu .imagine-detaliu {
    height: 450px;
    margin: 30px;
    width: 100%;
    margin-left: calc(50% - 50%); }
    @media screen and (min-width: 20em) and (max-width: 39.9375em) {
      .pergament-detaliu .imagine-detaliu {
        margin: 0;
        margin-top: 20px; } }
    .pergament-detaliu .imagine-detaliu iframe {
      margin-left: calc(50% - 50%); }
  .pergament-detaliu .reteta-video {
    height: 350px;
    width: 100%;
    margin-left: calc(50% - 50%);
    margin-top: 30px; }
    @media screen and (max-width: 48.75em) {
      .pergament-detaliu .reteta-video {
        margin-bottom: 10px;
        height: 230px;
        margin-top: 0px; } }
    @media screen and (max-width: 48.75em) {
      .pergament-detaliu .reteta-video iframe {
        height: 190px;
        margin-top: 30px; } }
  .pergament-detaliu h2 {
    font-size: 20px; }
  .pergament-detaliu ul li {
    font-family: 'roma-regular';
    color: #22349a; }
  .pergament-detaliu .ingrediente {
    position: relative;
    width: 90%;
    margin-top: 30px; }
  .pergament-detaliu p {
    text-align: left;
    margin-bottom: 50px; }
    @media screen and (min-width: 20em) and (max-width: 39.9375em) {
      .pergament-detaliu p {
        margin-bottom: 20px; } }
  @media screen and (max-width: 48.75em) {
    .pergament-detaliu .subtitlu {
      margin-top: 20px; } }

.wrrap-media {
  position: relative;
  overflow: hidden; }

.container-media {
  position: relative;
  max-width: 1280px;
  height: 150vh;
  margin: auto; }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    .container-media {
      height: 100vh; } }
  .container-media .popup-tvc {
    position: absolute;
    width: 750px;
    left: calc(50% - 375px);
    top: calc(50% - 225px);
    height: 450px;
    z-index: 2; }
    @media screen and (min-width: 20em) and (max-width: 39.9375em) {
      .container-media .popup-tvc {
        width: 100%;
        left: calc(50% - 50%);
        top: -25px; } }
    .container-media .popup-tvc .pergament {
      width: 730px; }
      @media screen and (min-width: 20em) and (max-width: 39.9375em) {
        .container-media .popup-tvc .pergament {
          display: none; } }
    .container-media .popup-tvc .content-tvc {
      position: absolute;
      width: 600px;
      top: 70px;
      left: calc(50% - 315px); }
      @media screen and (min-width: 20em) and (max-width: 39.9375em) {
        .container-media .popup-tvc .content-tvc {
          width: 90%;
          margin-top: 230px;
          left: calc(50% - 45%); }
          .container-media .popup-tvc .content-tvc iframe {
            position: relative;
            width: 100%; } }
      .container-media .popup-tvc .content-tvc video {
        width: 600px; }
  .container-media .nor1-media {
    position: absolute;
    left: -50px;
    bottom: -50px; }
  .container-media .nor2-media {
    position: absolute;
    right: -100px;
    bottom: 0px; }
  .container-media .nor3-media {
    position: absolute;
    top: 100px;
    right: 0; }
  .container-media .nor4-media {
    position: absolute;
    top: 50px;
    left: -100; }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    .container-media .nor1-media {
      position: absolute;
      left: -50px;
      bottom: 150px; }
    .container-media .nor2-media {
      position: absolute;
      right: -100px;
      bottom: 50px; }
    .container-media .nor3-media {
      position: absolute;
      top: 250px;
      right: -100px; }
    .container-media .nor4-media {
      position: absolute;
      top: 200px;
      left: -200; }
    .container-media .nor5-media {
      position: absolute;
      bottom: 50px;
      left: -200px; } }

.container-media-2 {
  position: relative;
  height: 20vh;
  width: 100%; }

@media screen and (max-width: 39.9375em) {
  .tvc-desktop {
    display: none; } }

.tvc-mobil {
  position: absolute;
  display: none;
  width: 90%;
  margin-top: 290px;
  left: calc(50% - 45%); }
  .tvc-mobil iframe {
    width: 300px;
    margin-left: calc(50% - 150px); }
  @media screen and (max-width: 39.9375em) {
    .tvc-mobil {
      display: block; } }

.wrapp-contact {
  position: relative;
  height: auto;
  overflow: hidden; }
  .wrapp-contact .section1-contact {
    position: relative;
    max-width: 1280px;
    height: 100vh;
    margin: auto; }
    .wrapp-contact .section1-contact .pergament-box-contact {
      position: absolute;
      width: 500px;
      height: 500px;
      top: 200px;
      left: calc(50% - 250px);
      z-index: 4; }
      @media screen and (min-width: 20em) and (max-width: 39.9375em) {
        .wrapp-contact .section1-contact .pergament-box-contact {
          top: -30px; } }
      @media screen and (min-width: 20em) and (max-width: 39.9375em) {
        .wrapp-contact .section1-contact .pergament-box-contact .perga-final {
          display: none; } }
      .wrapp-contact .section1-contact .pergament-box-contact .form-wrapper-contact {
        position: absolute;
        top: 100px;
        width: 380px;
        left: calc(50% - 190px); }
        @media screen and (min-width: 20em) and (max-width: 39.9375em) {
          .wrapp-contact .section1-contact .pergament-box-contact .form-wrapper-contact {
            top: 270px;
            width: 60%;
            left: calc(50% - 30%); } }
        .wrapp-contact .section1-contact .pergament-box-contact .form-wrapper-contact .title-contact {
          text-align: left;
          font-family: 'roma-bold'; }
        .wrapp-contact .section1-contact .pergament-box-contact .form-wrapper-contact .check-text {
          color: #22349a;
          font-size: 13px; }
        .wrapp-contact .section1-contact .pergament-box-contact .form-wrapper-contact [type="checkbox"] {
          margin: 0;
          color: #22349a; }
        .wrapp-contact .section1-contact .pergament-box-contact .form-wrapper-contact [type='text'] {
          box-shadow: none;
          border-right: none; }
    .wrapp-contact .section1-contact .nor-contact-1 {
      position: absolute;
      bottom: 0;
      left: 0; }
    .wrapp-contact .section1-contact .nor-contact-2 {
      position: absolute;
      bottom: 0;
      right: 0; }
    .wrapp-contact .section1-contact .nor-contact-3 {
      position: absolute;
      left: 0;
      bottom: 250px;
      width: 500px; }
      @media screen and (min-width: 20em) and (max-width: 39.9375em) {
        .wrapp-contact .section1-contact .nor-contact-3 {
          bottom: -40px;
          right: -50px; } }
    .wrapp-contact .section1-contact .nor-contact-4 {
      position: absolute;
      bottom: 250px;
      right: 0; }
      @media screen and (min-width: 20em) and (max-width: 39.9375em) {
        .wrapp-contact .section1-contact .nor-contact-4 {
          bottom: 0;
          left: -100px; } }
    .wrapp-contact .section1-contact .nor-contact-5 {
      position: absolute;
      bottom: -250px;
      width: 600px;
      left: calc(50% - 300px); }
  .wrapp-contact .section2-contact {
    position: relative;
    max-width: 1280px;
    height: 60vh;
    margin: auto; }
    @media screen and (max-width: 39.9375em) {
      .wrapp-contact .section2-contact {
        height: 120vh;
        padding-top: 100px; } }
  .wrapp-contact .box-info {
    width: 400px;
    height: 250px;
    z-index: 3;
    position: relative;
    margin: auto;
    overflow: scroll; }
    .wrapp-contact .box-info span {
      color: #22349a;
      font-weight: 600;
      margin-top: 20px; }
    .wrapp-contact .box-info p {
      text-align: left; }
    @media screen and (min-width: 20em) and (max-width: 39.9375em) {
      .wrapp-contact .box-info {
        margin-top: 60px;
        width: 80%; } }

.box-info::-webkit-scrollbar {
  width: .2em; }

.box-info::-webkit-scrollbar,
.box-info::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px; }

.box-info::-webkit-scrollbar-thumb {
  background-color: rgba(34, 52, 154, 0.6); }

.cover-bar {
  position: absolute;
  background: red;
  height: 100%;
  top: 0;
  right: 0;
  width: .4em;
  -webkit-transition: all .5s;
  opacity: 1; }

.box-info:hover .cover-bar {
  -webkit-transition: all .5s;
  background-color: red; }

.box-info::-webkit-scrollbar-corner {
  background-color: none; }

form input[type='text'] {
  border-bottom: 2px solid #22349a;
  background-color: rgba(255, 255, 255, 0);
  border-top: 0px;
  border-left: 0px;
  border-radius: 0px;
  box-shadow: inset 0 0px 2px rgba(28, 31, 42, 0.1);
  font-family: 'roma-regular';
  color: #22349a;
  text-align: center;
  transition: .5s ease-in-out; }

form input[type=text]:focus {
  background-color: rgba(255, 255, 255, 0);
  transition: .5s ease-in-out;
  border: none;
  border-bottom: 2px solid #22349a; }

form input[type='email'] {
  border-bottom: 2px solid #22349a;
  background-color: rgba(255, 255, 255, 0);
  border-top: 0px;
  border-left: 0px;
  border-right: none;
  border-radius: 0px;
  box-shadow: none;
  font-family: 'roma-regular';
  color: #22349a;
  text-align: center;
  transition: .5s ease-in-out; }

form input[type='email']:focus {
  background-color: rgba(255, 255, 255, 0);
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none; }

form textarea {
  border-bottom: 2px solid #22349a;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0); }

form textarea:focus {
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none; }

form #message {
  background-color: none; }

form #submit {
  background-color: #22349a;
  padding: 8px 25px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  width: 150px;
  margin-left: calc(50% - 75px);
  margin-top: 30px; }

form ::placeholder {
  color: #22349a;
  font-family: 'roma-bold';
  text-align: center; }

@media screen and (min-width: 20em) and (max-width: 39.9375em) {
  .back-alb-2 {
    display: none; } }

#raze-contact {
  position: fixed;
  width: 600px;
  top: -150px;
  left: calc(50% - 300px);
  z-index: 1; }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    #raze-contact {
      left: calc(50% - 200px); } }

.raze-termeni {
  width: 300px;
  top: -30px;
  position: fixed;
  left: calc(50% - 150px); }

.termeni-wrapp {
  position: relative; }
  .termeni-wrapp .container-termeni {
    position: relative;
    max-width: 1280px;
    height: 100vh;
    margin: auto; }
    .termeni-wrapp .container-termeni .popup-termeni {
      position: absolute;
      left: calc(50% - 300px);
      width: 600px;
      top: calc(50% - 225px);
      height: 450px;
      z-index: 2;
      margin-bottom: 300px;
      margin-top: 50px; }
      .termeni-wrapp .container-termeni .popup-termeni span {
        color: #22349a;
        font-family: 'roma-bold'; }
      .termeni-wrapp .container-termeni .popup-termeni .pergament {
        width: 600px; }
      .termeni-wrapp .container-termeni .popup-termeni #scroll-box {
        position: absolute;
        width: 440px;
        height: 400px;
        top: 70px;
        left: calc(50% - 220px);
        text-align: left;
        eight: 480px;
        overflow: scroll; }
        @media screen and (min-width: 20em) and (max-width: 39.9375em) {
          .termeni-wrapp .container-termeni .popup-termeni #scroll-box {
            width: 90%;
            left: calc(50% - 45%);
            height: 400px; } }
        .termeni-wrapp .container-termeni .popup-termeni #scroll-box p {
          text-align: left;
          margin-top: 15px;
          margin-bottom: 15px; }
        .termeni-wrapp .container-termeni .popup-termeni #scroll-box ul {
          color: #22349a;
          font-family: 'roma-regular'; }
          .termeni-wrapp .container-termeni .popup-termeni #scroll-box ul li {
            color: #22349a;
            font-family: 'roma-regular'; }
    .termeni-wrapp .container-termeni .nor-termen-1 {
      position: absolute;
      left: 0;
      bottom: 0; }
    .termeni-wrapp .container-termeni .nor-termen-2 {
      position: absolute;
      right: 0;
      bottom: 0; }
    .termeni-wrapp .container-termeni .nor-termen-3 {
      position: absolute;
      right: 0;
      bottom: 150px; }
    .termeni-wrapp .container-termeni .nor-termen-4 {
      position: absolute;
      left: 0;
      bottom: 0px; }
    .termeni-wrapp .container-termeni .nor-termen-5 {
      position: absolute;
      left: 0;
      top: 150px; }
    @media screen and (max-width: 48em) {
      .termeni-wrapp .container-termeni .popup-termeni {
        width: 90%;
        height: 300px;
        left: calc(50% - 45%); }
      .termeni-wrapp .container-termeni .pergament, .termeni-wrapp .container-termeni nor-termen-1, .termeni-wrapp .container-termeni nor-termen-2, .termeni-wrapp .container-termeni nor-termen-3, .termeni-wrapp .container-termeni nor-termen-4, .termeni-wrapp .container-termeni nor-termen-5 {
        display: none; }
      .termeni-wrapp .container-termeni .nor-termen-1 {
        bottom: -50px;
        left: -100px; }
      .termeni-wrapp .container-termeni .nor-termen-2 {
        bottom: -150px;
        right: -100px;
        left: calc(50% - 250px);
        width: 500px; }
      .termeni-wrapp .container-termeni .nor-termen-3 {
        bottom: -20px;
        left: -100px; }
      .termeni-wrapp .container-termeni .nor-termen-4 {
        bottom: 50px;
        right: -100px; }
      .termeni-wrapp .container-termeni .nor-termen-5 {
        position: absolute;
        bottom: 0px;
        right: -100px;
        display: none; } }
  .termeni-wrapp .container-termeni-2 {
    position: relative;
    height: 20vh; }

#scroll-box::-webkit-scrollbar {
  width: .4em; }

#scroll-box::-webkit-scrollbar,
#scroll-box::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px; }

#scroll-box::-webkit-scrollbar-thumb {
  background-color: rgba(34, 52, 154, 0.6); }

#scroll-box:hover .cover-bar {
  -webkit-transition: all .5s;
  background-color: red; }

#scroll-box::-webkit-scrollbar-corner {
  background-color: none; }

.zuzu-header-container {
  position: fixed;
  width: 100%;
  height: 130px;
  z-index: 5 !important;
  top: 0;
  left: 0; }
  @media screen and (max-width: 48.75em) {
    .zuzu-header-container {
      height: 200px;
      z-index: 2; } }
  .zuzu-header-container .logo-zuzu {
    position: relative;
    float: left;
    width: 120px;
    margin-left: 50px;
    padding-top: 22px;
    transition: .3s;
    z-index: 2; }
    @media screen and (max-width: 48.75em) {
      .zuzu-header-container .logo-zuzu {
        position: relative;
        width: 100px;
        left: calc(50% - 50px);
        margin: auto; } }
  .zuzu-header-container .logo-divin {
    position: relative;
    float: left;
    width: 180px;
    margin-left: calc(50% - 240px);
    padding-top: 20px;
    margin-right: 0px;
    transition: .3s;
    z-index: 3; }
    @media screen and (max-width: 48.75em) {
      .zuzu-header-container .logo-divin {
        width: 100%;
        margin: 20px 0;
        z-index: -2; }
        .zuzu-header-container .logo-divin img {
          width: 150px;
          display: block;
          margin: auto;
          margin-top: -20px; } }
  @media screen and (max-width: 48.75em) {
    .zuzu-header-container nav {
      display: none;
      position: fixed;
      height: 90%;
      width: 100%;
      margin-left: 0;
      top: 0px;
      padding-top: 120px;
      margin-top: 0;
      z-index: 8 !important; } }
  .zuzu-header-container nav ul {
    position: relative;
    margin-top: 30px;
    padding-top: 0;
    float: right;
    transition: .3s ease-in-out; }
    @media screen and (max-width: 48.75em) {
      .zuzu-header-container nav ul {
        position: absolute;
        width: 180px;
        margin-left: calc(50% - 90px); } }
    .zuzu-header-container nav ul li {
      list-style: none;
      float: left;
      padding: 8px;
      text-transform: uppercase;
      font-family: 'roma-bold'; }
      .zuzu-header-container nav ul li a {
        color: #fefefe; }
      @media screen and (max-width: 48.75em) {
        .zuzu-header-container nav ul li {
          float: none;
          width: 180px;
          margin: auto; }
          .zuzu-header-container nav ul li a {
            color: #22349a; } }
    .zuzu-header-container nav ul:hover ul {
      display: block; }

.menu-container-fixed {
  position: fixed;
  height: 70px;
  z-index: 2; }
  .menu-container-fixed .logo-zuzu {
    position: relative;
    float: left;
    width: 70px;
    margin-left: 70px;
    padding-top: 10px;
    transition: .3s;
    z-index: 2; }
    @media screen and (max-width: 48.75em) {
      .menu-container-fixed .logo-zuzu {
        width: 0px;
        margin-left: 25px;
        padding-left: 20px;
        z-index: 2;
        display: block; } }
  .menu-container-fixed .logo-divin {
    position: relative;
    float: left;
    width: 0px;
    margin-left: calc(50% - 12z0px);
    padding-top: 20px;
    margin-right: 100px;
    transition: .3s;
    z-index: 1; }
    @media screen and (max-width: 48.75em) {
      .menu-container-fixed .logo-divin {
        width: 100%;
        margin: 20px 0;
        transition: .3s; }
        .menu-container-fixed .logo-divin img {
          width: 0px;
          display: block;
          margin: auto;
          transition: .3s; } }
  .menu-container-fixed nav ul {
    margin-top: 10px;
    padding-top: 0;
    float: right;
    position: relative;
    transition: .3s ease-in-out; }
    @media screen and (max-width: 48.75em) {
      .menu-container-fixed nav ul {
        position: absolute;
        width: 180px;
        margin-left: calc(50% - 90px); } }
    .menu-container-fixed nav ul li {
      list-style: none;
      float: left;
      padding: 8px;
      text-transform: uppercase;
      font-family: 'roma-bold'; }
      .menu-container-fixed nav ul li a {
        color: #22349a;
        padding: 5px;
        background-color: rgba(255, 255, 255, 0.87);
        border-radius: 8px; }
        @media screen and (max-width: 48.75em) {
          .menu-container-fixed nav ul li a {
            background-color: none; } }

.menu-mobile {
  display: none; }
  @media screen and (max-width: 48.75em) {
    .menu-mobile {
      position: fixed;
      width: 50px;
      height: 50px;
      z-index: 8 !important;
      cursor: pointer;
      right: 0;
      top: 0;
      display: block; }
      .menu-mobile .hamburger {
        width: 30px;
        height: 3px;
        background-color: #22349a;
        position: relative;
        margin: auto;
        margin-top: calc(50% - 1px);
        transition: .2s ease-in-out;
        border-radius: 15px; }
        .menu-mobile .hamburger::before {
          content: '';
          width: 30px;
          height: 3px;
          background-color: #22349a;
          position: absolute;
          top: -7px;
          transition: .2s ease-in-out;
          border-radius: 15px; }
        .menu-mobile .hamburger::after {
          content: '';
          width: 30px;
          height: 3px;
          background-color: #22349a;
          position: absolute;
          top: 7px;
          transition: .2s ease-in-out;
          border-radius: 15px; } }

.active {
  cursor: pointer; }
  .active .hamburger {
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    margin: auto;
    margin-top: calc(50% - 1px);
    transition: .2s ease-in-out;
    top: 0; }
    .active .hamburger::before {
      transform: rotate(45deg);
      background-color: #22349a;
      top: 0px;
      transition: .2s ease-in-out; }
    .active .hamburger::after {
      transform: rotate(-45deg);
      background-color: #22349a;
      top: 0;
      transition: .2s ease-in-out; }

.nori-meniu {
  z-index: 1; }
  .nori-meniu .nor-meniu-1 {
    position: fixed;
    top: 20px;
    right: -485px;
    transition: .5s ease-in-out; }
  .nori-meniu .nor-meniu-22 {
    position: fixed;
    width: 500px;
    top: 20px;
    left: -460px;
    transition: .5s ease-in-out; }
  .nori-meniu .nor-meniu-3 {
    position: fixed;
    bottom: 50px;
    left: -460px;
    transition: .5s ease-in-out;
    transition: 1s ease-in-out; }
  .nori-meniu .nor-meniu-4 {
    position: fixed;
    bottom: 50px;
    right: -650px;
    transition: .8s ease-in-out; }

.active-nori .nor-meniu-1 {
  position: fixed;
  top: 20px;
  right: -100px;
  transition: .5s ease-in-out;
  z-index: 2; }

.active-nori .nor-meniu-22 {
  position: fixed;
  width: 500px;
  bottom: 20px;
  left: -50px;
  transition: .5s ease-in-out; }

.active-nori .nor-meniu-3 {
  position: fixed;
  width: 500px;
  bottom: 50px;
  left: -50px;
  transition: 1s ease-in-out; }

.active-nori .nor-meniu-4 {
  position: fixed;
  bottom: 50px;
  right: -100px;
  transition: .8s ease-in-out;
  z-index: 2; }

.face {
  position: fixed;
  width: 30px;
  right: 5px;
  top: 130px;
  z-index: 3;
  cursor: pointer; }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    .face {
      z-index: 6;
      top: 100px; } }

.insta {
  position: fixed;
  width: 30px;
  right: 5px;
  top: 170px;
  z-index: 4;
  cursor: pointer; }
  @media screen and (min-width: 20em) and (max-width: 39.9375em) {
    .insta {
      z-index: 6;
      top: 140px; } }

.footer-menu {
  position: absolute;
  bottom: 0;
  z-index: 4; }
  .footer-menu li {
    list-style: none;
    float: left;
    padding: 10px;
    font-family: 'roma'; }
    .footer-menu li a {
      color: #22349a; }
  @media screen and (max-width: 39.9375em) {
    .footer-menu {
      width: 100%;
      padding: 0;
      margin: 0; } }
