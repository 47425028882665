//* {
//    outline: 1px solid rgba(255, 0, 0, 0.42);
//}

.container-retete {
    position: relative;
    max-width: 1170px;
    height: auto;
    margin: auto;
    z-index: 1;
//    background-color: orange
    padding-bottom: 100px;
    
    @include breakpoint(780px down) {
        width: 100%;
    }
}

.nor-product-1 {
    position: absolute;
    top:0;
    margin-left: -200px;
    
    @include breakpoint(780px down) {
        display: none;
    }
}

.nor {
    @include breakpoint(780px down) {
        display: none;
    }
}
.nor-product-2 {
    position: absolute;
    top:0;
    right: -200px;
    
     @include breakpoint(780px down) {
        display: none;
    }
}
.nor-product-3 {
    position: absolute;
    top:150px;
    left: -80px;
    
     @include breakpoint(780px down) {
        display: none;
    }
}
.nor-product-4 {
    position: absolute;
    top:150px;
    right: -200px;
    z-index: -1;
    
     @include breakpoint(780px down) {
        display: none;
    }
}

.nor-product-hera {
    @include breakpoint(780px down) {
        display: none;
    }
}

.retete-space {
//    background-color: antiquewhite;
    position: relative;
    width: 1170px;
    margin: auto;
    margin-top: 200px;
    z-index: 15;
    
    @include breakpoint(780px down) {
        width: 100%;
         margin-top: 250px;
         margin-top: 250px;
    }
    
    .alb-retete {
        
        position: absolute;
        width: 1000px;
//        height: 400px;
        margin-left: calc(50% - 500px);
        top:-200px;
    }
    
    .text-start {
        margin-bottom: 40px;
        text-align: left;
        position: relative;
        display: block;
        z-index: 4;
        width: 800px;
        margin-left: calc(50% - 350px);
        
        @include breakpoint(780px down) {
            margin: 35px 30px;
        }
    }
    
    .reteta {
//        background-color: aqua;
        position: relative;
        z-index: 5;
        margin-bottom: 110px;
        
        .papirus-retete {
            position: absolute;
            width: 452px;
            margin-left: calc(50% - 226px);
            
            @include breakpoint(780px down) {
                margin: auto;
                width: 100%;
            }
        }
        
        .content-reteta {
            position: relative;
            width: 70%;
//            background-color: orangered;
            margin-left: calc(50% - 35%);
            padding: 20px 20px;
            margin-top: 50px;
            
            @include breakpoint(780px down) {
                padding: 0;
            }
            
            .img-reteta {
                width: 80%;
                height: 130px;
                margin-left: calc(50% - 40%);
                margin-bottom: 10px;
                
                @include breakpoint(780px down) {
                    width: 100%;
                    margin: auto;
                    height: 100px;
                    margin-bottom: 20px;
                }
            }
            
            .lauri-retete {
                width: 140px;
                margin-left: calc(50% - 70px);
            }
            
            p {
                margin: 15px 15px;
                text-align: left;
                
                @include breakpoint(780px down) {
                    width: 100%;
                    margin-left: 0;
                    padding: 0;
                }
            }
        }
    }
}

