.footer-menu {
    position: absolute;
    bottom: 0;
//    background-color: antiquewhite;
    z-index: 4;
    
    li {
        list-style: none;
        float: left;
        padding: 10px;
        font-family: 'roma';
        a {
            color: $zuzu;
        }
    }    
    
    @include breakpoint(small down) {
        width: 100%;
//        background-color: aqua;
        padding: 0;
        margin: 0;
    }
    
}

