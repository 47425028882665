.pergament-detaliu {
    
    position: relative;
    width: 900px;
    margin-left:calc(50% - 450px);
    background-color: #f8f8f8;
    height: auto;
    padding: 30px 30px;
    
    
    @include breakpoint(small only) {
        width: 80%;
        margin-left:calc(50% - 40%);
//        margin: 0;
        padding: 10px 10px;
        
    }
    
    
    
    .pergament-over {
    width: 1000px;
    height: 40px;
    position: absolute;
    top:-20px;
    margin-left: calc(50% - 530px);
        
        @include breakpoint(small only) {
            width: 110%;
            margin-left: calc(50% - 58%);
        }
    }
    
    .pergament-bottom {
    width: 1000px;
    height: 40px;
    position: absolute;
    bottom:-20px;
    margin-left: calc(50% - 530px);
    transform: rotate(180deg);
          @include breakpoint(small only) {
            width: 110%;
            margin-left: calc(50% - 58%);
        }
    }
    
    h1 {
        margin: auto;
        margin-top: 40px;
        width: 100%;
        
        @include breakpoint(small only) {
            margin-top: 20px;
        }
    }

    .lauri-detaliu {
        display: block;
        position: relative;
        width: 170px;
//        margin-left:calc(50% - 85px);
        margin: auto;
        height: 25px;
    }
    
    .imagine-detaliu {
      height: 450px;
        margin: 30px;
        width: 100%;
        margin-left:calc(50% - 50%);
        
        @include breakpoint(small only) {
            margin: 0;
            margin-top: 20px;
        }
        
        iframe {
            margin-left: calc(50% - 50%);
    
    //            @include breakpoint(780 down) {
    //                width: 100%;
    //                margin-left: calc(50% - 50%);
    //                
    //            }
        }
    }
    
    .reteta-video {
        height: 350px;
//        margin: 30px;
        width: 100%;
        margin-left: calc(50% - 50%);
        margin-top: 30px;
        
        @include breakpoint(780px down) {
            margin-bottom: 10px;
            height: 230px;
            margin-top: 0px;
        }
        
        iframe {
            
            @include breakpoint(780px down) {  
                height:190px;
                margin-top: 30px;
            }
        
        }
    }
    
    h2 {
        font-size:20px;
    }
    
    ul li {
        font-family: 'roma-regular';
        color: $brand;
    }    
    
    .ingrediente  {
        position: relative;
        width: 90%;
        margin-top: 30px;
    }
    
    p {
        text-align: left;
        margin-bottom: 50px;
        
        @include breakpoint(small only) {
            margin-bottom: 20px;
        }
        
    }
    
    .subtitlu {
        @include breakpoint(780px down) {
            margin-top: 20px;
        }
    }

}