.raze-termeni {
    width: 300px;
    top:-30px;
    position: fixed;
    left:calc(50% - 150px);
}

.termeni-wrapp {
    
    position: relative;

.container-termeni {
        position: relative;
        max-width: 1280px;
        height: 100vh;
        margin: auto;
    
    
    .popup-termeni {
        position: absolute;
        left: calc(50% - 300px);
        width: 600px;
        top:calc(50% - 225px);
        height: 450px;
        z-index: 2;
        margin-bottom: 300px;
        margin-top: 50px;
        
        
        span {
            color: $zuzu;
//            font-weight: 600;
            font-family: 'roma-bold';
        }
        
        .pergament {
            width: 600px;
        }
        
        #scroll-box {
           position: absolute;
           width: 440px;
           height: 400px;
           top:70px;
           left: calc(50% - 220px);
           text-align: left;
           eight: 480px;
            overflow: scroll;
           
               @include breakpoint(small only) {
                   width: 90%;
                   left:calc(50% - 45%);
                   height: 400px;
               }

           p {
               
               text-align: left;
               margin-top: 15px;
               margin-bottom: 15px;
               
           }
           
           ul {
                   color: $zuzu;
                   font-family: 'roma-regular';
               
               li {
                   color: $zuzu;
                   font-family: 'roma-regular';
               }
           }
           

        }
        
    }

    
    .nor-termen-1 {
        position: absolute;
        left: 0;
        bottom: 0;
//        background-color: aquamarine;
    }
    .nor-termen-2 {
        position: absolute;
        right: 0;
        bottom: 0;
//        background-color: aqua;
    }    
    .nor-termen-3 {
        position: absolute;
        right: 0;
        bottom: 150px;
//        background-color: orangered;
        
    }
    .nor-termen-4 {
        position: absolute;
        left: 0;
        bottom: 0px;
    }
    .nor-termen-5 {
        position: absolute;
        left: 0;
        top:150px;
    }
    
    
    @include breakpoint(768px down) {
        
//        height: auto;
        
        .popup-termeni {
            width: 90%;
            height: 300px;
            left: calc(50% - 45%);
        }
        
        .pergament, nor-termen-1, nor-termen-2, nor-termen-3, nor-termen-4, nor-termen-5 {
                    display: none
                }
        
        .nor-termen-1 {
            bottom: -50px;
            left: -100px
        }
        
        .nor-termen-2 {
            bottom: -150px;
            right: -100px;
            left: calc(50% - 250px);
            width: 500px;
        }
        
        .nor-termen-3 {
            bottom: -20px;
            left: -100px
        }
        
        .nor-termen-4 {
            bottom: 50px;
            right: -100px
        }
        
        .nor-termen-5 {
            position: absolute;
            bottom: 0px;
            right: -100px;
            display: none;
        }
        

    }
    
    
}

.container-termeni-2 {
    position: relative;
    height: 20vh;
}
    
    }



#scroll-box::-webkit-scrollbar {
  width: .4em; 
}
#scroll-box::-webkit-scrollbar,
#scroll-box::-webkit-scrollbar-thumb {
  overflow:visible;
  border-radius: 4px;
}
#scroll-box::-webkit-scrollbar-thumb {
//  background: $zuzu; 
    background-color: rgba(34, 52, 154, 0.6);

}

#scroll-box:hover .cover-bar {
//   opacity: 0;
  -webkit-transition: all .5s;
    background-color: red;
    
}

#scroll-box::-webkit-scrollbar-corner {
//  background: $zuzu; 
    background-color: none;
}

