  
#raze-product {
        position: fixed;
        z-index: 1;
        top:-80px;
        width: 600px;
        left: calc(50% - 300px);
    
        @include breakpoint(small down) {
//            background-color: aqua;
            left: calc(50% - 150px);

        }
    
    }
    

.container-product {
    
    @include breakpoint(768px down) {
     display: none
        
    }
    
    position: relative;
    max-width: 1280px;
    height: 700px;
    margin: auto;
    z-index: 1;
    
    
      .pop-up-product {
        position: fixed;
        width: 590px;
        height: 600px;
        left: calc(50% - 295px);
        top: calc(50% - 250px);
        z-index: 8;
        display: none;
//        background-color: orangered;
          
          @include breakpoint(small only) {
              background-color: #f0eeec;
              top:0;
              height: 100%;
              width: 100%;
              left:0;
          }
          

        .pergament {
            position: fixed;
            width: 590px;
            height: 580px;
            z-index: -1;
            
            @include breakpoint(small only) {
                display: none;
            }
        }
        
//        .content-zeus, .content-hera {
//            position:relative;
//            padding-top: 30px;
//        }
        
        .content-product {
            position: relative;
            margin: 50px auto;
            width: 430px;
            height: 500px;
//            background-color: #628d7f;
//            overflow: scroll;

            
            @include breakpoint(small only) {
                background-color: aquamarine;
                height: 100%;
                width: 90%;
            }
            
            .product-scroll {
                width: 430px;
                height: 288px;
//                background-color: darkseagreen;
                overflow: scroll;
                padding: 0 20px;
            }
            
            .product-scroll-2 {
                width: 430px;
                height: 240px;
                overflow: scroll;
                padding: 0 20px;
            }
            
            
            
            .title-info {
                padding-top: 0px;
                font-size: 20px;
            }
            
            .popup-title1 {
                font-size: 16px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }

            
            .close-icon {
                position: absolute;
                width: 30px;
                height: 30px;
                top:-10px;
                right: -10px;
                z-index: 4;
                cursor: pointer;
            
            .xbtn {
                position: absolute;
                width: 25px;
                height: 2px;
                margin-left: 2px;
                margin-top: calc(50% - 1px);
                top:0;
                background-color: $zuzu;
                transform: rotate(45deg);
                
                &::before {
                    content: '';
                    width: 25px;
                    height: 2px;
                    background-color: $zuzu;
                    position: absolute;
                    transform: rotate(-90deg);
                }
            }
        }     
            
            .lauri {
            position: relative;
            width: 150px;
            margin-left: calc(50% - 75px);
            margin-bottom: 15px;
            margin-top: 5px;
        }
            
        
                p span {
                    font-weight: 600;
                }

            
            .play-icon {
                position: relative;
                margin-bottom: 15px;
                margin-top: 15px;
                margin-left: calc(50% - 20px);
                width: 40px;
                cursor: pointer;
            }
            
            
            audio {
                width: 300px;
                margin-left: calc(50% - 150px);
            }
            
            .lauri-info {
                position: relative;
                width: 150px;
                margin-left: calc(50% - 75px);
                margin-bottom: 8px;
                margin-top: 3px;
            }
            
            .info {
                position: relative;
                width: 50%;
                float: left;
                text-align: center;
                
                p {
                    font-weight: 600;
                }
                
                ul {
                    list-style: none;
                    margin-left: 0;
                    
                    li {
                        color: $zuzu;
                        font-family: 'roma-regular';
                        line-height: 19px;
                    }
                }
            }
            
            .valori {}
            
            .grame {}
            
        }
        
            .spoon {
                position:absolute;
                bottom: 0;
                width: 300px;
                left: 300px;
                bottom: -10px;
                z-index: 3;   
                
                @include breakpoint(small only) {
                    width: 200px;
                    bottom: 10px;
                    left: 280px;
                }
            }

    }   
    
    // Coloane
    
    
    .coloane-product {
        
        @include breakpoint(small only) {
            display: none;
        }
    }
    
    
    .coloana-stanga1-product {
        position: absolute;
        top:-100px;
        left: 230px;
        width: 80px;
    }
    
    .coloana-stanga2-product {
        position: absolute;
        top:-130px;
        left: 430px;
        width: 80px;
    } 
    
    .coloana-dreapta1-product {
        position: absolute;
        top:-100px;
        right: 230px;
        width: 80px;
    }
    
    .coloana-dreapta2-product {
        position: absolute;
        top:-130px;
        right: 408px;
        width: 80px;
    }
    
    .coloana-stanga0-product {
        position: absolute;
        top:-50px;
        left: 80px;
        width: 90px;
    
}

    .coloana-dreapta0-product {
    
       position: absolute;
        top:-50px;
        right: 80px;
        width: 90px;
    
}
    
    
    // end coloane
    
    .back-alb-2 {
            width: 950px;
            height: 750px;
            position: absolute;
            left: calc(50% - 425px);
            top: -150px;
            z-index: 1;
        }

    
    .product-space  {
        position: relative;
        width: 800px;
        height: 400px;
        margin-left: calc(50% - 400px);
        margin-top: calc(60vh - 200px);
        
// zei 
        
// zeus
        
        .zeu-product-zeus {
            width: 750px;
            z-index: 3;
            position: absolute;
            left: -480px;
            top:-120px;
        }
        
        .iaurt-product-zeus {
            position: absolute;
            left: 120px;
            top:40px;
            z-index: 4;
            cursor: pointer;
            transition: .3s ease-out;
            width: 230px;
            
            &:hover {
                top:30px;
                transition: .3s ease-out;
            }
            
            &:hover + .umbra-product-zeus {
                width: 200px;
                left: 135px;
                transition: .3s ease-out;
                } 
            
            

            
        }
        
        .umbra-product-zeus {
            
            position: absolute;
            width: 231px;
            left: 120px;
            top:190px;
            z-index: 3;
            cursor: pointer;
            transition: .3s ease-out;
            }
        
// Hera
        
        .zeu-product-hera {
            width: 580px;
            z-index: 3;
            position: absolute;
            right: -430px;
            top: -120px;
        }
        
        .iaurt-product-hera {
            position: absolute;
            right: 120px;
            top:40px;
            z-index: 4;
            cursor: pointer;
            transition: .3s ease-out;
            width: 230px;
            
            &:hover {
                top:30px;
                transition: .3s ease-out;
            }
            
            &:hover + .umbra-product-hera {
                width: 200px;
                right: 135px;
                transition: .3s ease-out;
                } 
            }
        
        .umbra-product-hera {
            
            position: absolute;
            width: 231px;
            right: 120px;
            top:190px;
            z-index: 2;
            cursor: pointer;
            transition: .3s ease-out;
        }
        
        
        
// Afrodita
        
        .zeu-product-afrodita {
            position: absolute;
            width: 530px;
            z-index: 3;
            left: -430px;
            bottom: -150px;
        }
        
        .iaurt-product-afrodita {
            position: absolute;
            z-index: 4;
            cursor: pointer;
            bottom: -20px;
            left: -20px;
            transition: .3s ease-out;
            width: 230px;
            
            &:hover {
                bottom: -10px;
                transition: .3s ease-out;
            }
            
            &:hover + .umbra-product-afrodita {
                width: 200px;
                left: -5px;
                transition: .3s ease-out;
                } 
        }
        
        .umbra-product-afrodita {
            
            position: absolute;
            width: 231px;
            z-index: 2;
            cursor: pointer;
            bottom: -30px;
            left: -20px;
            transition: .3s ease-out;
        }
        
        
// Atena
        
        .zeu-product-atena {
            position: absolute;
            width: 480px;
            z-index: 3;
            bottom: -170px;
            right: -370px;
        }
        
        .iaurt-product-atena {
            position: absolute;
            z-index: 3;
            cursor: pointer;
            bottom: -20px;
            right: -20px;
            transition: .3s ease-out;
            width: 230px;

            
             &:hover {
                bottom: -10px;
                transition: .3s ease-out;
            }
            
            &:hover + .umbra-product-atena {
                    width: 200px;
                    right: -10px;
                    transition: .3s ease-out;
                } 
        }
        
        .umbra-product-atena {
            position: absolute;
            width: 231px;
            z-index: 2;
            cursor: pointer;
            bottom: -30px;
            right: -20px;
            transition: .3s ease-out;

        }
        
        
        
// apollo
        
        .zeu-product-apollo {
            position: absolute;
            width: 650px;
            z-index: 3;
            bottom: -330px;
            margin-left: calc(50% - 450px);
        }
        
        .iaurt-product-apollo {
            position: absolute;
            z-index: 4;
            cursor: pointer;
            bottom: -90px;
            left:calc(50% - 120px);
            transition: .3s ease-out;
            width: 230px;

            
                &:hover {
                    bottom: -80px;
                    transition: .3s ease-out;
                }
            
                &:hover + .umbra-product-apollo {
                    width: 200px;
                    left: calc(50% - 100px);
                    transition: .3s ease-out;
                } 
        }
        
        .umbra-product-apollo {
            position: absolute;
            width: 231px;
            z-index: 3;
            cursor: pointer;
            bottom: -100px;
            left:calc(50% - 120px);
            transition: .3s ease-out;

        }
        
        
        // nori
        
        .nor-product-afrodita {
                position: absolute;
                width: 900px;
                left: -400px;
                bottom: -250px;  
            }

        .nor-product-atena {
            position: absolute;
            width: 900px;
            right: -400px;
            bottom: -250px;
        }

        .nor-product-apollo {
            position: absolute;
            width: 800px;
            right: calc(50% - 400px);
            bottom: -300px;
        }

        .nor-product-zeus {
            position: absolute;
            width: 700px;
            left: -350px;
            bottom: 0px;
        }

        .nor-product-hera {
            position: absolute;
            width: 700px;
            right: -350px;
            bottom: 0px;
        }

        // end nori
 
    }

    
}



// pagina mobil

.produse-mobil   {
    
    overflow: hidden;
    
      .pop-up-product {
        position: fixed;
        width: 590px;
        height: 600px;
        left: calc(50% - 295px);
        top: calc(50% - 250px);
        z-index: 8;
        display: none;
          
          @include breakpoint(small only) {
              background-color: #f0eeec;
              top:0;
              height: 100%;
              width: 100%;
              left:0;
          }
           

        .pergament {
            position: fixed;
            width: 590px;
            height: 580px;
            z-index: -1;
            
            @include breakpoint(small only) {
                display: none;
            }
        }
        
        .content-product {
            position: relative;
            margin: 50px auto;
            width: 440px;
            height: 500px;
//            background-color: aqua;
            
            @include breakpoint(small only) {
//                background-color: aquamarine;
                height: 100%;
                width: 90%;
            }
            
            .product-scroll {
//                background-color: aqua;
                overflow: scroll;
                height: 90%;
            }
            
            .title-info {
                padding-top: 0px;
                font-size: 20px;
            }
            
            .popup-title1 {
                font-size: 16px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
  
            .close-icon {
                position: absolute;
                width: 30px;
                height: 30px;
                top:-30px;
                right: 0px;
                z-index: 4;
                cursor: pointer;
            
            .xbtn {
                position: absolute;
                width: 25px;
                height: 2px;
                margin-left: 2px;
                margin-top: calc(50% - 1px);
                top:0;
                background-color: $zuzu;
                transform: rotate(45deg);
                
                &::before {
                    content: '';
                    width: 25px;
                    height: 2px;
                    background-color: $zuzu;
                    position: absolute;
                    transform: rotate(-90deg);
                }
            }
        }     
            
            .lauri {
            position: relative;
            width: 150px;
            margin-left: calc(50% - 75px);
            margin-bottom: 15px;
            margin-top: 5px;
        }
        
                p span {
                    font-weight: 600;
                }

            
            .play-icon {
                position: relative;
                margin-bottom: 15px;
                margin-top: 15px;
                margin-left: calc(50% - 20px);
                width: 40px;
                cursor: pointer;
            }
            
            
//            audio {
//                width: 300px;
//                margin-left: calc(50% - 150px);
//            }
//            
            .lauri-info {
                position: relative;
                width: 150px;
                margin-left: calc(50% - 75px);
                margin-bottom: 8px;
                margin-top: 3px;
            }
            
            .info {
                position: relative;
                width: 50%;
                float: left;
                text-align: center;
                
                p {
                    font-weight: 600;
                }
                
                ul {
                    list-style: none;
                    margin-left: 0;
                    
                    li {
                        color: $zuzu;
                        font-family: 'roma-regular';
                        line-height: 19px;
                    }
                }
            }
            
            .valori {}
            
            .grame {}
            
            .spoon-wrapper {
                position: relative;
                height: 150px;
                overflow: hidden;
                
                .spoon-mobile {
                    position: relative;
                    bottom: 30px;
                    right: -80px;
                }
                
            }
            
        }
        
//        .spoon {
//                position:relative;
//                bottom: 0;
//                width: 300px;
//                left: 300px;
//                bottom: -10px;
//                z-index: 3;   
//                
//                @include breakpoint(small only) {
//                    width: 200px;
//                    bottom: 10px;
//                    left: 280px;
//                }
//            }

    }   
    
    
    position: relative;
    
    @include breakpoint(768px up) {
        display: none;
    }
    
    
    .section2 {
        position: relative;
        height: 110vh;
        width: 100%;
        
        .iaurt-produs {
            width: 230px;
            position: absolute;
            left: calc(50% - 115px);
            z-index: 3;
            
            @include breakpoint(320px up) {
                bottom:-60px;
            }
            
            @include breakpoint(320px down) {
                bottom:0;
            }
            
        }
        
        .umbra {
            position: absolute;
            bottom: -10px;
            width: 230px;
            left: calc(50% - 115px);
            z-index: 2;
        }
    
        .back-alb-mobil{
            position: absolute;
            width: 600px;
            bottom: 50px;
            z-index: 1;
            
            @include breakpoint(768px down) {
                display: none;
            }

        }
        
        
           @include breakpoint(320px up) {
                height: 70vh;
            }
            
            @include breakpoint(320px down) {
                height: 110vh;
            }
        
    }
    
    
    .section-produse {
        position: relative;
        height: 100vh;
        width: 100%;
//        outline: 2px solid yellow;
        
            @include breakpoint(320px up) {
                height: 70vh;
            }
            
            @include breakpoint(320px down) {
                height: 100vh;
            }
        
        
        
        
        
        .iaurt-produs {
            width: 230px;
            position: absolute;
            left: calc(50% - 115px);
            bottom: 0;
            z-index: 3;
        }
        
        .umbra {
            position: absolute;
            bottom: -10px;
            width: 230px;
            left: calc(50% - 115px);
            z-index: 3;
        }
        
        
    }
    
    .coloana-s1 {
        width: 70px;
        position: absolute;
        bottom: -70px;
        left: 10px;
        z-index: 1;
    }
    
    .coloana-s2 {
        width: 70px;
        position: absolute;
        left:calc(50% - 78px);
        bottom: -30px;
        z-index: 1;


    }
    
    .coloana-d1 {
        width: 70px;
        position: absolute;
        bottom:-70px;
        right: 10px;
        z-index: 1;
    }
    
    .coloana-d2 {
        width: 70px;
        position: absolute;
        right: calc(50% - 78px);
        bottom: -30px;
        z-index: 1;
    }
    
    .nor-coloane-1 {
        position: absolute;
        bottom: -100px;
        z-index: 2;
    }
    
    .nor-coloane-2 {
        position: absolute;
        bottom: -100px;
                z-index: 1;

    }
    
    .nor-coloane-3 {
        width: 250px;
        right: 0;
        position: absolute;
        bottom: -150px;
        
    }
    
    .nor-coloane-4 {
        width: 250px;
        left: 0;
        position: absolute;
        bottom: -190px;
        transform: rotate(180deg);
        
    }
        
    .hera-nor-1 {
        width: 550px;
        position: absolute;
        bottom: -200px;
    }
    
    .hera-nor-2 {
        position: absolute;
        width: 400px;
        bottom: -200px;
        right: -100px;
        
    }
    
    .apollo-nor-2 {
        position: absolute;
        bottom: -100px;
//        left: -50px;
    }
    
    .atena-nor-1 {
        position: absolute;
        bottom: -100px;
    }
    
    .atena-nor-2 {
        position: absolute;
        bottom: -50px;
        left: -50px;
    }
    
    .atena-nor-3 {
        position: absolute;
        bottom: -50px;
        right: -50px;
    }
    
    .afrodita-nor-1 {
        position: absolute;
        bottom: -170px;
    }
    
    .afrodita-nor-2 {
        position: absolute;
        bottom: -220px;
        right: -100px;
    }
    
}



.product-scroll::-webkit-scrollbar {
  width: .2em; 
}
.product-scroll:-webkit-scrollbar,
.product-scroll::-webkit-scrollbar-thumb {
  overflow:visible;
  border-radius: 4px;
}
.product-scroll::-webkit-scrollbar-thumb {
//  background: $zuzu; 
    background-color: rgba(34, 52, 154, 0.6);

}

.product-scroll:hover .cover-bar {
//   opacity: 0;
  -webkit-transition: all .5s;
    
}

.product-scroll::-webkit-scrollbar-corner {
//  background: $zuzu; 
    background-color: none;
}



//product-scroll-2

.product-scroll-2::-webkit-scrollbar {
  width: .2em; 
}
.product-scroll-2:-webkit-scrollbar,
.product-scroll-2::-webkit-scrollbar-thumb {
  overflow:visible;
  border-radius: 4px;
}
.product-scroll-2::-webkit-scrollbar-thumb {
//  background: $zuzu; 
    background-color: rgba(34, 52, 154, 0.6);

}

.product-scroll-2:hover .cover-bar {
//   opacity: 0;
  -webkit-transition: all .5s;
    
}

.product-scroll-2::-webkit-scrollbar-corner {
//  background: $zuzu; 
    background-color: none;
}






#popup-produse-mobile-scroll {
    height: 85%;
    width: 100%;
    overflow: scroll;
//    margin-bottom: 30px;
}