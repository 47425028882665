
.wrrap-media {
    position: relative;
    overflow: hidden;
}

.container-media {
    
    position: relative;
    max-width: 1280px;
    height: 150vh;
    margin: auto;
//    background-color: red;
    
    @include breakpoint(small only) {
        height: 100vh;
    }
    
    
    .popup-tvc {
        position: absolute;
        width: 750px;        
        left: calc(50% - 375px);
        top:calc(50% - 225px);
        height: 450px;
        z-index: 2;
        
        @include breakpoint(small only) {
            width: 100%;
            left: calc(50% - 50%);
            top:-25px;
        }
        
        .pergament {
            width: 730px;
            
            @include breakpoint(small only) {
                display: none;
            }
        }
        
       .content-tvc {
           position: absolute;
           width: 600px;
           top:70px;
           left: calc(50% - 315px);
//           background-color: aqua;
               
           @include breakpoint(small only) {
               width: 90%;
               margin-top:230px;
               left: calc(50% - 45%);
               
               iframe {
                   position: relative;
                   width: 100%;
               }
               

           }
           
           video {
               width: 600px;
           }
           
//           iframe {
//               margin-left: 20px;
//               
//               @include breakpoint(small only) {
//                   margin-left: 0;
//               }
//               
//           }
           

           
        }    
    }

    
    .nor1-media {
        position: absolute;
        left: -50px;
        bottom: -50px;
    }

    .nor2-media {
        position: absolute;
        right: -100px;
        bottom: 0px;
    }
    .nor3-media {
        position: absolute;
        top:100px;
        right: 0;
    }
    .nor4-media {
        position: absolute;
        top:50px;
        left: -100;
    }
    
    
    @include breakpoint(small only) {
        
        
        .nor1-media {
        position: absolute;
        left: -50px;
        bottom: 150px;
        }
        .nor2-media {
            position: absolute;
            right: -100px;
            bottom: 50px;
        }
        .nor3-media {
            position: absolute;
            top:250px;
            right: -100px;
        }
        .nor4-media {
            position: absolute;
            top:200px;
            left: -200;
        }     
        .nor5-media {
            position: absolute;
            bottom:50px;
            left: -200px;
        }
        
    }
    

}

.container-media-2 {
    position: relative;
    height: 20vh;
    width: 100%;
}

.tvc-desktop {
    
    @include breakpoint(small down) {
        display: none;
    }
}


.tvc-mobil {
    
    position: absolute;
    display: none;
    width: 90%;
    margin-top: 290px;
    left: calc(50% - 45%);
    
    iframe {
        width: 300px;
        margin-left: calc(50% - 150px);
    }
    
    
    @include breakpoint(small down) {
        display: block;
    }
    
    
}