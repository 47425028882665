.face {
    position: fixed;
    width: 30px;
    right: 5px;
    top:130px;
    z-index: 3;
    cursor: pointer;
    
    @include breakpoint(small only) {
        
        z-index: 6;
        top:100px;
    }    
    
        
 }


.insta {
     position: fixed;
    width: 30px;
    right: 5px;
    top:170px;
    z-index: 4;
    cursor: pointer;
    
    @include breakpoint(small only) {
        
        z-index: 6;
        top:140px;
    }  
    
}


